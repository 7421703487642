import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Radio, RadioGroup } from "react-icheck";
import Select from "react-select";
import _ from "lodash";
import validators from "../../validators";
import Api from "../../services/api";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import IntlTelInput from "react-intl-tel-input";
import "icheck/skins/all.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-datetime/css/react-datetime.css";
import * as utils from "../../utils/Util";

const LOCAL_CACHE_KEY = "_kycOwnership";

class KycBeneficialOwnershipInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      buttonLoading: false,
      apiError: "",
      displayMoreDirectors: false,
      displayMoreeDirectors: false,
      director1Name: "",
      director2Name: "",
      director3Name: "",
      director4Name: "",
      displayMoreAddress: false,
      address2Display: false,
      address3Display: false,
      address4Display: false,
      politicallyForegignExposedPerson: false,
      additionalShareHolders: [],
      additionalDirectors: []
    };
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.saveKycBasicDetails = this.saveKycBasicDetails.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.deleteShareholderAddress = this.deleteShareholderAddress.bind(this);
    this.deleteAdditionalDirectors = this.deleteAdditionalDirectors.bind(this);
    this.politicallyExposedRadioChange = this.politicallyExposedRadioChange.bind(this);
    this.optionRef = React.createRef();
    this.additionalOptionRef = React.createRef();
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_BASIC_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    try {
      const api = new Api();
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", { emdId });
      if (userResponse.code === 200) {
        if (
          userResponse.data.kycStatus === "u" ||
          (userResponse.data.kycStatus === "a" &&
            userResponse.data.isKycVerified === true)
        ) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? `/kyc_status/pending/${emdId}`
              : `/kyc_status/approved/${emdId}`;
          this.props.history.push(redirectUrl);
        } else {
          const localCache = utils.getKycLocalCache()[LOCAL_CACHE_KEY];
          if (userResponse.data.userKycDetail && !_.isEmpty(userResponse.data.userKycDetail.additionalShareHolders)) {
            let dob = moment(userResponse.data.userKycDetail.dob);
            this.setState({
              contactNo: userResponse.data.userKycDetail.contactNo,
              postalCode: userResponse.data.userKycDetail.postalCode,
              city: userResponse.data.userKycDetail.city,
              state: userResponse.data.userKycDetail.state,
              dob: dob,
              countryId: userResponse.data.userKycDetail.countryId._id,
              countryIdValue: userResponse.data.userKycDetail.countryId._id,
              countryName: userResponse.data.userKycDetail.countryId.name,
              agreeCheck: true,
              termsOfEngagementDataAdded: userResponse.data.termsOfEngagementDataAdded,
              director1Name: userResponse.data.userKycDetail.director1Name,
              director2Name: userResponse.data.userKycDetail.director2Name,
              address1FullName: userResponse.data.userKycDetail.address1FullName,
              address1Email: userResponse.data.userKycDetail.address1Email,
              address1PostalCode: userResponse.data.userKycDetail.address1PostalCode,
              address1State: userResponse.data.userKycDetail.address1State,
              address1City: userResponse.data.userKycDetail.address1City,
              address1ContactNo: userResponse.data.userKycDetail.address1ContactNo,
              address1AddressLine1: userResponse.data.userKycDetail.address1AddressLine1,
              address1AddressLine2: userResponse.data.userKycDetail.address1AddressLine2,
              politicallyExposedPerson: userResponse.data.userKycDetail.politicallyExposedPerson,
              additionalShareHolders: userResponse.data.userKycDetail.additionalShareHolders,
              additionalDirectors: userResponse.data.userKycDetail.additionalDirectors,
            });
          } else if (!_.isEmpty(localCache)) {
            this.setState(localCache);
          }
          const response = await api.get("user/getCountries");
          let countriesList = [];
          if (response.code === 200) {
            if (!_.isUndefined(response.data.countries)) {
              response.data.countries.forEach((country) => {
                let obj = { value: country._id, label: country.name };
                countriesList.push(obj);
              });
              if (!_.isUndefined(countriesList)) {
                this.setState({
                  countriesList: countriesList,
                });
              }
            }
          } else {
            toast.error(userResponse.message);
          }
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleGenderChange(event, value) {
    this.setState({ gender: value });
  }

  handleAgreeChange(e, checked) {
    this.setState({ agreeCheck: checked });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
      countryId: selectedOption.value,
    });
  }

  handleSecondaryCountryChange(index, selectedOption) {
    const { additionalShareHolders } = this.state;
    additionalShareHolders[index]['countryName'] = selectedOption.label;
    additionalShareHolders[index]['countryId'] = selectedOption.value;
    this.setState({ ...this.state, additionalShareHolders })
  }

  onchange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  numberOnChange(value) {
    this.setState({ address1ContactNo: value });
  }

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  additionalOnMenuOpen = (countryId) => {
    const { countriesList } = this.state;
    setTimeout(() => {
      this.additionalOptionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.additionalOptionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryId
        ),
      });
    }, 0);
  }

  deleteShareholderAddress(index) {
    const { additionalShareHolders } = this.state;
    additionalShareHolders.splice(index, 1)
    this.setState({ ...this.state, additionalShareHolders })
  }

  deleteAdditionalDirectors(index) {
    const { additionalDirectors } = this.state;
    additionalDirectors.splice(index, 1)
    this.setState({ ...this.state, additionalDirectors })
  }

  handleOnChange(event, index) {
    const { additionalShareHolders } = this.state;
    additionalShareHolders[index][event.target.name] = event.target.value;
    this.setState({ ...this.state, additionalShareHolders })
  }

  handleNumberOnChange(value, index) {
    const { additionalShareHolders } = this.state;
    additionalShareHolders[index]['contactNumber'] = value;
    this.setState({ ...this.state, additionalShareHolders });
  }

  handleDirectorOnChange(event, index) {
    const { additionalDirectors } = this.state;
    additionalDirectors[index]['directorName'] = event.target.value;
    this.setState({ ...this.state, additionalDirectors })
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "contactNo", "email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async saveKycBasicDetails(event) {
    event.preventDefault();
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    const {
      director1Name,
      director2Name,
      address1FullName,
      address1Email,
      address1CountryId,
      address1State,
      address1City,
      address1PostalCode,
      address1AddressLine1,
      address1AddressLine2,
      address1ContactNo,
      address2FullName,
      address2Email,
      address2CountryId,
      address2State,
      address2City,
      address2PostalCode,
      address2AddressLine1,
      address2AddressLine2,
      address2ContactNo,
      address3FullName,
      address3Email,
      address3CountryId,
      address3State,
      address3City,
      address3PostalCode,
      address3AddressLine1,
      address3AddressLine2,
      address3ContactNo,
      address4FullName,
      address4Email,
      address4CountryId,
      address4State,
      address4City,
      address4PostalCode,
      address4AddressLine1,
      address4AddressLine2,
      address4ContactNo,
      politicallyExposedPerson,
      additionalShareHolders,
      additionalDirectors
    } = this.state;
    const isFormValid = this.isFormValid();
    if (politicallyExposedPerson) {
      this.setState({ buttonLoading: true });
      const api = new Api();
      const formData = {
        director1Name,
        director2Name,
        address1FullName,
        address1Email,
        address1CountryId,
        address1State,
        address1City,
        address1PostalCode,
        address1AddressLine1,
        address1AddressLine2,
        address1ContactNo,
        address2FullName,
        address2Email,
        address2CountryId,
        address2State,
        address2City,
        address2PostalCode,
        address2AddressLine1,
        address2AddressLine2,
        address2ContactNo,
        address3FullName,
        address3Email,
        address3CountryId,
        address3State,
        address3City,
        address3PostalCode,
        address3AddressLine1,
        address3AddressLine2,
        address3ContactNo,
        address4FullName,
        address4Email,
        address4CountryId,
        address4State,
        address4City,
        address4PostalCode,
        address4AddressLine1,
        address4AddressLine2,
        address4ContactNo,
        politicallyExposedPerson,
        beneficialOwnershipProgram: true,
        additionalShareHolders,
        additionalDirectors
      }
      const localCache = utils.getKycLocalCache();
      localCache[LOCAL_CACHE_KEY] = {
        ...formData
      }
      utils.saveKycLocalCache(localCache);
      const response = await api
        .setToken(authenticationToken)
        .create("user/emd/saveKycBasicDetails", { ...formData, emdId });
      if (response) {
        this.setState({ buttonLoading: false });
        if (response.code === 200) {
          this.props.history.push(`/kyc/upload/${emdId}`);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      if (!isFormValid) {
        toast.error("Please enter valid details for required fields.");
      } else {
        toast.error("Please accept terms & conditions to proceed.");
      }
    }
  }


  displayAddressToggle() {
    let { additionalShareHolders } = this.state;
    const data = {
      fullName: '',
      email: '',
      countryId: '',
      countryName: '',
      state: '',
      city: '',
      postalCode: '',
      addressLine1: '',
      addressLine2: '',
      contactNumber: ''
    }
    if (additionalShareHolders.length <= 3) {
      additionalShareHolders.push(data);
    }
    this.setState({ additionalShareHolders })
  }

  displayDirectorToggle() {
    let { additionalDirectors } = this.state;
    const data = { directorName: '' }
    if (additionalDirectors.length <= 7) {
      additionalDirectors.push(data);
    }
    this.setState({ additionalDirectors })
  }

  politicallyExposedRadioChange(e) {
    this.setState({ politicallyExposedPerson: e.target.value });
  }

  render() {
    let {
      countriesList,
      additionalShareHolders,
      additionalDirectors,
      buttonLoading,
      countryName,
      countryIdValue,
      termsOfEngagementDataAdded,
      director1Name,
      director2Name,
      address1FullName,
      address1Email,
      address1State,
      address1City,
      address1PostalCode,
      address1AddressLine1,
      address1AddressLine2,
      address1ContactNo,
      politicallyExposedPerson,
    } = this.state;
    const { emdId } = this.props.match.params;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <ul className="progressbar d-flex p-0">
                  <li>Fill Details</li>
                  <li className="active">Beneficial Ownership Information</li>
                  <li>Upload</li>
                  <li>Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
              <div className="col-md-1"></div>
            </div>

            <div className="row mt-3 card-horizontal-padding">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box card-inner-padding h-100">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="row text-xl">
                        <div className="col-md-11">
                          Provide the names of all directors of the corporation:
                        </div>
                        <div className="col-md-1">
                          <i className="fa fa-plus-circle fa-lg cursor-pointer"
                            onClick={() => this.displayDirectorToggle()}>
                          </i>
                        </div>
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-small">Director-1 Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="director1Name"
                          id="director1Name"
                          value={director1Name}
                          onChange={this.onchange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-small">Director-2 Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="director2Name"
                          id="director2Name"
                          value={director2Name}
                          onChange={this.onchange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    {additionalDirectors.map((item, index) =>
                      <div className="col-md-6" key={index}>
                        <div className="form-group">
                          <div className="row">
                            <label className="text-small col-md-11">Director-{index + 3} Name</label>
                            <div className="col-md-1">
                              <i className="fa fa-trash fa-lg cursor-pointer"
                                onClick={() => this.deleteAdditionalDirectors(index)}>
                              </i>
                            </div>
                          </div>

                          <input
                            className="form-control"
                            type="text"
                            name="director4Name"
                            id="director4Name"
                            value={additionalDirectors[index].directorName}
                            onChange={(event) => this.handleDirectorOnChange(event, index)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mgtp-lg-4 mgtp-xl-4 mgtp-sm-4 mgtp-md-4 mgtp-xs-4 card-horizontal-padding">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box card-inner-padding h-100">
                  <form>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <h6 className="row text-xl">
                          <div className="col-md-11">
                            Provide the names and addresses of all individuals
                            who directly or indirectly own or control 20% or
                            more of the shares of the corporation:
                          </div>
                          <div className="col-md-1">
                            <i className="fa fa-plus-circle fa-lg cursor-pointer"
                              onClick={() => this.displayAddressToggle()}>
                            </i>
                          </div>
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">Full Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address1FullName"
                            id="address1FullName"
                            onChange={this.onchange}
                            value={address1FullName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">Email Address</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address1Email"
                            id="address1Email"
                            onChange={this.onchange}
                            value={address1Email}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-small">Country</label>
                          <Select
                            className="country-select2"
                            classNamePrefix="cntry"
                            options={countriesList}
                            placeholder="Select Country"
                            name="address1CountryId"
                            id="address1CountryId"
                            onChange={this.handleCountryChange}
                            value={{
                              label: countryName,
                              value: countryIdValue,
                            }}
                            ref={this.optionRef}
                            onMenuOpen={this.onMenuOpen}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-small">State/Province</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address1State"
                            id="address1State"
                            onChange={this.onchange}
                            value={address1State}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-small">City</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address1City"
                            id="address1City"
                            onChange={this.onchange}
                            value={address1City}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-small">Postal Code</label>
                          <input
                            className="form-control"
                            type="text"
                            name="address1PostalCode"
                            id="address1PostalCode"
                            onChange={this.onchange}
                            value={address1PostalCode}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">Address Line 1</label>
                          <input
                            className="form-control"
                            type="text"
                            id="address1AddressLine1"
                            name="address1AddressLine1"
                            onChange={this.onchange}
                            placeholder="Address Line 1"
                            value={address1AddressLine1}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">Address Line 2</label>
                          <input
                            className="form-control"
                            type="text"
                            id="address1AddressLine2"
                            name="address1AddressLine2"
                            onChange={this.onchange}
                            placeholder="Address Line 2"
                            value={address1AddressLine2}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">Contact Number</label>
                          <IntlTelInput
                            preferredCountries={["tw"]}
                            onPhoneNumberChange={(validate, value) => this.numberOnChange(value)}
                            numberType="MOBILE"
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            value={address1ContactNo}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {additionalShareHolders.map((item, index) => (
              <div className="row mgtp-lg-4 mgtp-xl-4 mgtp-sm-4 mgtp-md-4 mgtp-xs-4 card-horizontal-padding" key={index}>
                <div className="col-md-12">
                  <div className="element-wrapper custom-element-box card-inner-padding h-100">
                    <form>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <h6 className="row text-xl">
                            <div className="col-md-11">
                              Provide the names and addresses of all individuals
                              who directly or indirectly own or control 20% or
                              more of the shares of the corporation:
                            </div>
                            <div className="col-md-1">
                              {index < 3 &&
                                <i className="fa fa-plus-circle fa-lg cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() => this.displayAddressToggle()}></i>
                              }
                              <i className="ml-3 fa fa-trash fa-lg cursor-pointer"
                                aria-hidden="true"
                                onClick={() => this.deleteShareholderAddress(index)}></i>
                            </div>
                          </h6>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-small">Full Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="fullName"
                              onChange={(event) => this.handleOnChange(event, index)}
                              value={additionalShareHolders[index].fullName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-small">Email Address</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              onChange={(event) => this.handleOnChange(event, index)}
                              value={additionalShareHolders[index].email}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="text-small">Country</label>
                            <Select
                              className="country-select2"
                              classNamePrefix="cntry"
                              options={countriesList}
                              placeholder="Select Country"
                              onChange={this.handleSecondaryCountryChange.bind(this, index)}
                              value={{
                                label: additionalShareHolders[index]['countryName'],
                                value: additionalShareHolders[index]['countryId']
                              }}
                              ref={this.additionalOptionRef}
                              onMenuOpen={() => this.additionalOnMenuOpen(additionalShareHolders[index]['countryId'])}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="text-small"> State/Province</label>
                            <input
                              className="form-control"
                              type="text"
                              name="state"
                              onChange={(event) => this.handleOnChange(event, index)}
                              value={additionalShareHolders[index].state}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="text-small"> City</label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              onChange={(event) => this.handleOnChange(event, index)}
                              value={additionalShareHolders[index].city}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="text-small"> Postal Code</label>
                            <input
                              className="form-control"
                              type="text"
                              name="postalCode"
                              onChange={(event) => this.handleOnChange(event, index)}
                              value={additionalShareHolders[index].postalCode}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-small">Address Line 1</label>
                            <input
                              className="form-control"
                              type="text"
                              name="addressLine1"
                              onChange={(event) => this.handleOnChange(event, index)}
                              placeholder="Address Line 1"
                              value={additionalShareHolders[index].addressLine1}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-small">Address Line 2</label>
                            <input
                              className="form-control"
                              type="text"
                              name="addressLine2"
                              onChange={(event) => this.handleOnChange(event, index)}
                              placeholder="Address Line 2"
                              value={additionalShareHolders[index].addressLine2}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-small">Contact Number</label>
                            <IntlTelInput
                              preferredCountries={["tw"]}
                              onPhoneNumberChange={(validate, value) =>
                                this.handleNumberOnChange(value, index)
                              }
                              numberType="MOBILE"
                              containerClassName="intl-tel-input"
                              inputClassName="form-control"
                              fieldId={`contactNumber${index}`}
                              value={additionalShareHolders[index].contactNumber}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ))}
            <div className="row mgtp-lg-4 mgtp-xl-4 mgtp-sm-4 mgtp-md-4 mgtp-xs-4 card-horizontal-padding">
              <div className="col-md-12">
                <div className="element-wrapper custom-element-box card-inner-padding h-100">
                  <div className="form-check">
                    <label htmlFor="agreeCheck" className="ml-2">
                      Is anyone mentioned above a Politically Foreign Exposed
                      Person (“PEFP”)?
                    </label>
                    <RadioGroup
                      name={`politicallyExposedPerson`}
                      className="politically-exposed-radio-container"
                      onChange={(e) => this.politicallyExposedRadioChange(e)}
                      value={politicallyExposedPerson}
                    >
                      <Radio
                        value="Yes"
                        radioClass="iradio_square-blue pr-2"
                        increaseArea="20%"
                        label=" Yes"
                      />
                      <Radio
                        value="No"
                        radioClass="iradio_square-blue pr-2 ml-2"
                        increaseArea="20%"
                        label=" No"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end card-inner-padding kyc-btn-group">
              <Link
                className="btn cancel-button mr-2"
                to="/dashboard"
              >
                Do it later
              </Link>
              <Link to={`/kyc/${emdId}`} className="color-white">
                <button
                  className="btn update-button ml-2"
                  type="button"
                >
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-0"
                type="submit"
                onClick={this.saveKycBasicDetails}
                disabled={buttonDisabled}
              >
                Next
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-1" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycBeneficialOwnershipInformation;
