import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import { GOOGLE_MAP_KEY } from '../../services/api-config';
Geocode.setApiKey(GOOGLE_MAP_KEY);
Geocode.enableDebug();

class Map extends Component {

    onInfoWindowClose = (event) => {
        console.log(event);
    };

    onMarkerDragEnd = (event) => {
        const { getCity, getArea, getState, setMapStateValues, setMapLatituteLongitude } = this.props;
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
        setMapLatituteLongitude(newLat, newLng);
        if (newLng !== '' && newLng !== '') {
            Geocode.fromLatLng(newLat, newLng).then(
                response => {
                    const address = response.results[0].formatted_address,
                        addressArray = response.results[0].address_components,
                        city = getCity(addressArray),
                        area = getArea(addressArray),
                        state = getState(addressArray);
                    if (address !== '') {
                        const newStateObject = { address, city, area, state };
                        setMapStateValues(newStateObject);
                    }
                },
                error => {
                    console.error(error);
                }
            );
        }
    };

    render() {
        const { markerPosition, center, address } = this.props;
        const AsyncMap =
            withGoogleMap(
                props => (
                    <GoogleMap google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: center.lat, lng: center.lng }}
                    >
                        {address && address !== '' &&
                            <InfoWindow
                                onClose={this.onInfoWindowClose}
                                position={{ lat: (markerPosition.lat + 0.0018), lng: markerPosition.lng }}
                            >
                                <div><span style={{ padding: 0, margin: 0 }}>{address}</span></div>
                            </InfoWindow>
                        }
                        <Marker google={this.props.google}
                            name={'Location'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
                        />
                        <Marker />
                    </GoogleMap>
                )
            );

        let map;
        if (this.props.center.lat !== undefined) {
            map = <div>
                <AsyncMap
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>
        } else {
            map = <div style={{ height: this.props.height }} />
        }
        return (map)
    }
}
export default Map;