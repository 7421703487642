import React, { Component } from "react";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";

class Footer extends Component {
  render() {
    const { color } = this.props;
    const year = moment(new Date()).format("YYYY");
    return (
      <div className="copyright-text text-center mt-3 footer" style={{ color: color }}>
        Copyright © {year} {messageConstants.PERMIAN_LABEL} Technologies, Inc.
      </div>
    );
  }
}
export default Footer;
