import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { toast } from "react-toastify";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  ethAddressDisplay,
} from "../../utils/Util";
import Api from "../../services/api";
import validators from "../../validators";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      fullname: "",
      email: "",
      isKycVerified: "",
      isEmailVerified: "",
      phoneNumber: "N/A",
      page: 1,
      sizePerPage: 10,
      filters: "",
      emailStatus: "",
      kycStatus: "",
      loading: false,
      buttonLoading: false,
      transactionModalOpen: false,
      transactionAmount: 0,
      tokens: "",
      receivingEthOriginalAddress: "",
      receivingEthAddress: "",
    };
    this.validators = validators;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showTransactionModal = this.showTransactionModal.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.refreshCurrenttable = this.refreshCurrenttable.bind(this);
  }

  async componentDidMount() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    try {
      this.getMyTokenList();

      const response = await api
        .setToken(authenticationToken)
        .get("user/shareholder/details", { userId: userId });
      console.log("response.data", response.data);
      console.log("response.data", response.data.user);
      if (response.code === 200) {
        console.log("Hey it comes here......");
        console.log(response.data.user.fullName);
        this.setState({
          fullName: response.data.user.fullName,
          email: response.data.user.email,
          isKycVerified: response.data.user.isKycVerified,
          isEmailVerified: response.data.user.isEmailVerified,
          phoneNumber: response.data.user.phoneNumber,
          depositeAddress: response.data.user._id,
          identificationDataFilled: response.data.user.identificationDataFilled,
          fatcaCrsDataFilled: response.data.user.fatcaCrsDataFilled,
          tokens: response.data.user.tokens,
          receivingEthOriginalAddress:
            response.data.user && response.data.user.receivingEthOriginalAddress
              ? response.data.user.receivingEthOriginalAddress
              : "",
          receivingEthAddress:
            response.data.user && response.data.user.receivingEthAddress
              ? response.data.user.receivingEthAddress
              : "N/A",
          userRole:
            response.data.user && response.data.user.role
              ? response.data.user.role
              : "N/A",
          contactNo: response.data.kycDetail.contactNo,
          gender: response.data.kycDetail.gender,
          dob: response.data.kycDetail.dob,
          countryName:
            response.data &&
            response.data.kycDetail &&
            response.data.kycDetail.countryId
              ? response.data.kycDetail.countryId.name
              : "",
          state: response.data.kycDetail.state,
          city: response.data.kycDetail.city,
          postalCode: response.data.kycDetail.postalCode,
          kycStatus: response.data.kycDetail.status,
          note: response.data.kycDetail.note,
          kycDocumentTypeListTypes:
            response.data.kycDocumentTypeListTypes || [],
          kycFiles: response.data.kycFiles ? response.data.kycFiles : [],
          objectIdRef: response.data.objectIdRef || [],
        });
        console.log("hey", this.state.fullName);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      console.log("eee", error);
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
    this.getRecords();
  }

  refreshCurrenttable() {
    this.getRecords();
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  async onchange(event) {
    try {
      const { tokenId } = this.state;
      console.log("tokenId", tokenId);
      let authenticationToken = this.props.authToken;
      const eventTargetName = event.target.name;
      const eventTargetValue = event.target.value;
      this.setState({
        [event.target.name]: event.target.value,
      });

      if (
        event.target.name === "transactionAmount" ||
        event.target.name === "tokens"
      ) {
        if (!tokenId) {
          toast.error("Please select token.");
          return false;
        }
        const api = new Api();
        // get details for the specific token
        const tokenResponse = await api
          .setToken(authenticationToken)
          .get("user/issuer/token/price", {
            tokenId,
          });
        console.log("tokenPrice", tokenResponse.data.tokenPrice);

        if (eventTargetName === "transactionAmount") {
          const tokens = eventTargetValue / tokenResponse.data.tokenPrice;
          if (tokens) {
            this.setState({
              tokens,
            });
          }
        } else if (eventTargetName === "tokens") {
          const transactionAmount =
            eventTargetValue * tokenResponse.data.tokenPrice;
          if (transactionAmount) {
            this.setState({
              transactionAmount,
            });
          }
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page, txHash, depositeAddress } = this.state;
    const { email } = this.props.authUserInfo;
    let authenticationToken = this.props.authToken;
    let userId = this.props.match.params.id;
    this.setState({ loading: true });
    const response = await api
      .setToken(authenticationToken)
      .get("user/token/transactions/", {
        sizePerPage: sizePerPage,
        page: page,
        txHash: txHash,
        depositeAddress: depositeAddress,
        userId: userId,
        issuerEmail: email
      });
    if (response.code === 200) {
      this.setState({
        renderFlag: true,
        loading: false,
        data: response.data.transactions,
        totalSize: response.data.totalTransactions,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
      }
    }
  };

  showTransactionModal() {
    this.setState({ transactionModalOpen: true });
  }

  onCloseModal() {
    this.setState({ transactionModalOpen: false });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["transactionAmount"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  async getMyTokenList() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/my/tokens/list", {});
      if (response.code === 200) {
        this.setState({
          myTokensList: response.data.tokens,
        });
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  render() {
    const {
      fullName,
      email,
      isKycVerified,
      phoneNumber,
      data,
      sizePerPage,
      page,
      renderFlag,
      loading,
      isEmailVerified,
      identificationDataFilled,
      fatcaCrsDataFilled,
      receivingEthOriginalAddress,
      receivingEthAddress,
      userRole,
      countryName,
      state,
      city,
      postalCode,
      kycStatus,
      note,
      kycDocumentTypeListTypes,
      kycFiles,
      objectIdRef,
    } = this.state;

    console.log("fullName", fullName);
    console.log("email", email);

    let emailStatuslabelClassName = "badge-primary";
    // isEmailVerified === true ? "badge-success" : "badge-danger";
    let emailLabel = isEmailVerified === true ? "Verified" : "Not Verified";
    let phoneNumberDisplay =
      phoneNumber !== "" && phoneNumber !== null ? phoneNumber : "N/A";
    let userId = this.props.match.params.id;
    const _this = this;

    let kycStatuslabelClassName = "badge-primary";
    // kycStatus === "a" ? "badge-success" : kycStatus === "u"
    //     ? "Under badge-primary" : kycStatus === "r"
    //       ? "badge-danger" : "badge-warning";
    let kycLabel =
      kycStatus === "a"
        ? "Approved"
        : kycStatus === "u"
        ? "Under Review"
        : kycStatus === "r"
        ? "Rejected"
        : "Pending";

    let displayDocumentTypes = "";
    if (
      !_.isUndefined(kycDocumentTypeListTypes) &&
      !_.isEmpty(kycDocumentTypeListTypes)
    ) {
      displayDocumentTypes = kycDocumentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div>
            <div className="row text-default text-default-color">
              <div className="col-md-12">
                {parentDocumentType &&
                  parentDocumentType.children.map((documentType, index) => (
                    <div
                      className={
                        parentDocumentType.children.length > 1 ? "mt-4" : ""
                      }
                    >
                      <div className="row">
                        {_.isEmpty(objectIdRef) &&
                          objectIdRef[documentType._id] &&
                          objectIdRef[documentType._id].map(
                            (image, imageIndex) => (
                              <div className="col-md-12">
                                <div className="mt-2">
                                  {parentDocumentType.label} :{" "}
                                  <a
                                    href={kycFiles[image]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View
                                  </a>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )
      );
    }

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn Id",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
    ];

    if (userRole === "s") {
      columns.push(
        {
          headerClasses: "text-bold",
          dataField: "userId",
          text: "User Name",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{cell.fullName}</div>
              </div>
            );
          },
        },
        {
          headerClasses: "text-bold",
          dataField: "userId",
          text: "Email",
          sort: true,
          formatter: function (cell, row, rowIndex, formatExtraData) {
            return (
              <div className="text-left">
                <div>{cell.email}</div>
              </div>
            );
          },
        }
      );
    }

    columns.push(
      {
        headerClasses: "text-bold",
        dataField: "issuerId",
        text: "Token Symbol",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell.listedTokenSymbol}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "CreatedOnUTC",
        text: "Transaction Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Sent",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                {niceNumberDecimalDisplay(cell, 2)}{" "}
                {row.transactionType !== "MANUAL" ? row.transactionType : ""}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "coins",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {niceNumberDecimalDisplay(cell, 0)}{" "}
              {row.issuerId.listedTokenSymbol}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "usdAmount",
        text: "Contributed Amount",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let usdAmount =
            row.transactionType !== "MANUAL" && row.transactionType !== "CARD"
              ? parseFloat(row.amountRecieve) * parseFloat(row.cryptoLiveRate)
              : row.amountRecieve;
          return <div>${niceNumberDecimalDisplay(usdAmount, 2)}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "depositeAddress",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.transactionType !== "MANUAL" && (
                <span className="custom-tooltip" tooltip-title={cell}>
                  {ethAddressDisplay(cell)}
                </span>
              )}
              {row.transactionType === "MANUAL" && <span>N/A</span>}
              {row.transactionType === "CARD" && <span>N/A</span>}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        headerAlign: 'center',
        dataField: "detail",
        text: "Detail",
        style: {
          minWidth: 200
        },
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.paymentFileUrl && (
                <div>
                  <a href={row.paymentFileUrl} target="_blank">
                    View Payment Proof
                  </a>
                </div>
              )}
              {row.subscriptionAgreementFileUrl && (
                <div>
                  <a href={row.subscriptionAgreementFileUrl} target="_blank">
                    View Subscription Agreement
                  </a>
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.tokenSentStatus === true) {
            label = "Token Sent";
            className = "badge-success";
          } else if (row.status === 2) {
            label = "Pending";
            className = "badge-danger";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={"badge"}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      }
    );

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={loading}
          data={data}
          columns={columns}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span className="text-xl">User Details</span>
                    </div>
                  </h6>

                  <div className="row text-default text-default-color">
                    <div className="col-md-6">
                      <div>Full Name : {fullName}</div>
                    </div>
                    <div className="col-md-6">
                      <div>Email : {email}</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Email Verified :{" "}
                        <span className={"badge " + emailStatuslabelClassName}>
                          {emailLabel}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Kyc Verified :{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>Contact No : {phoneNumberDisplay}</div>
                    </div>
                    <div className="col-md-6 wallet-address">
                      Whitelisted wallet address :{" "}
                      {receivingEthOriginalAddress &&
                      receivingEthOriginalAddress !== "" ? (
                        <span className="address-break">
                          {receivingEthOriginalAddress}
                        </span>
                      ) : (
                        <span className="address-break">
                          {receivingEthAddress}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Identification details submitted:{" "}
                        {identificationDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Fatca/CRS details submitted:{" "}
                        {fatcaCrsDataFilled ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3"></div>
                </div>
              </div>

              <div className="element-box mt-3">
                <div>
                  <h6 className="element-header flex-header">
                    <span className="text-xl">Transactions</span>
                    <span className="mgtp-xs-3">
                      <button
                        className="mb-2 btn default-btn"
                        type="button"
                        onClick={() => this.refreshCurrenttable()}
                      >
                        Refresh
                      </button>
                    </span>
                  </h6>

                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        {renderFlag === true && (
                          <RemoteAll
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={this.state.totalSize}
                            onTableChange={this.handleTableChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="element-box mt-3">
                <div>
                  <h6 className="element-header">
                    <span className="text-xl">KYC Details</span>
                  </h6>

                  <div className="row text-default text-default-color">
                    <div className="col-md-6">
                      <div>Full Name : {fullName}</div>
                    </div>
                    <div className="col-md-6">
                      <div>Email : {email}</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>City : {city || "N/A"}</div>
                    </div>
                    <div className="col-md-6">
                      <div>State/Province : {state || "N/A"}</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>Country : {countryName || "N/A"}</div>
                    </div>
                    <div className="col-md-6">
                      <div>Postal Code : {postalCode || "N/A"}</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Status:{" "}
                        <span className={"badge " + kycStatuslabelClassName}>
                          {kycLabel}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {displayDocumentTypes && (
                <div className="element-box mt-3">
                  <h6 className="element-header">
                    <span className="text-xl text-default-color">
                      KYC documents
                    </span>
                  </h6>
                  {displayDocumentTypes}
                </div>
              )}

              {kycStatus !== "u" && note && note !== "" && note !== null && (
                <div className="element-box text-xl text-default-color mt-3">
                  <h6>Note</h6>
                  {note}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserDetail;
