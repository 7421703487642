import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Checkbox } from "react-icheck";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { toast } from "react-toastify";

class KycAgreement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      note: "",
      agreeCheck: false,
      agreementLoading: true,
    };
    this.submitKycAgreement = this.submitKycAgreement.bind(this);
    this.redirectHandler = this.redirectHandler.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_AGREEMENT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }

    try {
      const { emdId } = this.props.match.params;
      let authenticationToken = this.props.authToken;
      const api = new Api();
      const userKycAgreementDetails = await api
        .setToken(authenticationToken)
        .get("user/emd/kycAgreementDetails", { emdId });
      if (userKycAgreementDetails.code === 200) {
        if (
          !_.isUndefined(userKycAgreementDetails.data.emdId) &&
          userKycAgreementDetails.data.emdId !== null
        ) {
          this.setState({
            relationshipDisclosureData:
              userKycAgreementDetails.data && userKycAgreementDetails.data.emdId
                ? userKycAgreementDetails.data.emdId.relationshipDisclosureData
                : "",
            agreementLoading: false,
          });
        }
      }

      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", { emdId });

      if (userResponse.code === 200) {
        if (userResponse.data.isKycVerified === true) {
          this.props.history.push(`/kyc_status/approved/${emdId}`);
        }
        if (userResponse.data.kycStatus === "r") {
          this.props.history.push(`/kyc_status/rejected/${emdId}`);
        }

        if (userResponse.data.termsOfEngagementDataAdded) {
          this.setState({
            termsOfEngagementDataAdded:
              userResponse.data.termsOfEngagementDataAdded,
          });
        }

        if (
          !_.isUndefined(userResponse.data.userKycDetail.note) &&
          userResponse.data.userKycDetail.note !== "" &&
          userResponse.data.userKycDetail.note !== null
        ) {
          this.setState({
            note: userResponse.data.userKycDetail.note,
          });
        }
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  async submitKycAgreement() {
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    this.setState({
      buttonLoading: true,
    });
    const api = new Api();
    const response = await api
      .setToken(authenticationToken)
      .create("user/emd/submitKyc", {emdId});
    if (!_.isUndefined(response) && response.code === 200) {
      toast.success(response.message);
      const { termsOfEngagementDataAdded } = this.state;
      const url = termsOfEngagementDataAdded
        ? "/kyc/engagement/terms"
        : `/kyc_status/pending/${emdId}`;
      this.props.history.push(url);
    } else {
      this.setState({
        buttonLoading: false,
      });
      toast.error(response.message);
    }
  }

  async redirectHandler() {
    const { emdId } = this.props.match.params;
    const { termsOfEngagementDataAdded } = this.state;
    const url = termsOfEngagementDataAdded
      ? "/kyc/engagement/terms"
      : `/kyc_status/pending/${emdId}`;
    this.props.history.push(url);
  }

  handleAgreeChange(e, checked) {
    this.setState({ agreeCheck: checked });
  }

  render() {
    const {
      buttonLoading,
      relationshipDisclosureData,
      agreeCheck,
      agreementLoading,
      termsOfEngagementDataAdded,
    } = this.state;
    const { emdId } = this.props.match.params;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <ul className="progressbar d-flex p-0">
                  <li>Fill Details</li>
                  <li>Beneficial Ownership Information</li>
                  <li>Upload</li>
                  <li className="active">Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row mt-4 card-horizontal-padding">
              <div className="col-md-12">
                <div className="element-wrapper">
                  <div className="element-box custom-element-box">
                    <h5 className="mb-3 text-xl">
                      Relationship Disclosure :
                    </h5>
                    {relationshipDisclosureData && (
                      <div>
                        <div
                          className="text-default"
                          dangerouslySetInnerHTML={{
                            __html: relationshipDisclosureData,
                          }}
                        />
                        <div className="mt-3 row kyc-agree-container">
                          <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-xl-1 checkbox-lg-margin">
                            <Checkbox
                              id="agreeCheck"
                              checkboxClass="icheckbox_square-blue"
                              increaseArea="20%"
                              name="agreeCheck"
                              onChange={this.handleAgreeChange}
                              checked={agreeCheck}
                            />
                          </div>
                          <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-xl-11 checkbox-label-lg-margin">
                            <label htmlFor="agreeCheck" className="ml-2">
                              I have read above relationship disclosure and I
                              agree to terms.
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {!relationshipDisclosureData && !agreementLoading && (
                      <div>Dealer agreement details not found.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end card-inner-padding kyc-btn-group">
              <Link
                className="btn cancel-button mr-2"
                to="/dashboard"
              >
                Do it later
              </Link>
              <Link to={`/kyc/upload/${emdId}`} className="color-white">
                <button
                  className="btn update-button ml-2"
                  type="button"
                >
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-0"
                type="submit"
                onClick={this.submitKycAgreement}
                disabled={buttonDisabled}
              >
                {termsOfEngagementDataAdded ? "Next" : "Submit"}
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-1" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
export default KycAgreement;
