import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import * as messageConstants from "../../utils/Messages";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

class KycUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentTypeId: "",
      kycFile: "",
      selfieWithKycFile: "",
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      kycDocumentNumber: "",
      displayNote: false,
      kycFiles: "",
      objectIdRef: "",
      filesRequiredAr: [],
      allowKycSubmit: false,
      wasteFileAr:[]
    };
    this.saveKycDocuments = this.saveKycDocuments.bind(this);
    this.fileRemoved = this.fileRemoved.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.uploadInput = [];
    this.uploadImageArray = {};
    this.uploadMultipleImageArray = {};
    this.proxyUrl = "https://cors-anywhere.herokuapp.com/";
    this.userRequiredUploadFileCounter = 7;
    this.userUploadedFileCounter = 0;
  }

  async componentDidMount() {
    
    document.title =
      messageConstants.KYC_UPLOAD_FILES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;

    try {
      const api = new Api();
      let _this = this;
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", {
          emdId,
          getKycPhoto: true,
          getKycDocumentTypes: true,
        });
      if (userResponse.code === 200) {
        if (
          userResponse.data.kycStatus === "u" ||
          userResponse.data.kycStatus === "a"
        ) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? `/kyc_status/pending/${emdId}`
              : `/kyc_status/approved/${emdId}`;
          this.props.history.push(redirectUrl);
        } else {
          let stateObj = {};
          let userKycImages = userResponse.data.userKycDetailPhotos;

          if (userResponse.data.termsOfEngagementDataAdded) {
            stateObj["termsOfEngagementDataAdded"] =
              userResponse.data.termsOfEngagementDataAdded;
          }

          if (!_.isUndefined(userKycImages) && !_.isEmpty(userKycImages)) {
            _.forEach(userKycImages, function (value, key) {
              let keyName = "display" + key + "Image";
              let textKey = "kyc" + key + "TextDisplay";
              stateObj[keyName] = value;
              stateObj[textKey] = false;
            });
          }

          if (
            !_.isUndefined(userResponse.data.displayNote) &&
            userResponse.data.displayNote === true
          ) {
            stateObj.displayNote = true;
          }

          if (
            !_.isUndefined(userResponse.data.kycDocumentTypeList) &&
            !_.isUndefined(userResponse.data.kycDocumentNumber)
          ) {
            stateObj.kycDocumentTypeList =
              userResponse.data.kycDocumentTypeList;
            stateObj.kycDocumentNumber = userResponse.data.kycDocumentNumber;
            stateObj.kycFiles = userResponse.data.kycFiles;
            stateObj.objectIdRef = userResponse.data.objectIdRef;
            stateObj["allowKycSubmit"] = true;
          }

          if (!_.isEmpty(userResponse.data.objectIdRef)) {
            let keyFiles = userResponse.data.kycFiles;
            _.forEach(userResponse.data.objectIdRef, function (value, key) {
              stateObj[key + "StateFiles"] = [];
              if (!_.isEmpty(value)) {
                _.forEach(value, function (value1, key1) {
                  let temp = {};
                  temp.source = _this.proxyUrl + keyFiles[value1];
                  temp.options = { type: "local", load: false };
                  stateObj[key + "StateFiles"].push(temp);
                });
              }
            });

            this.userUploadedFileCounter = _.size(
              userResponse.data.objectIdRef
            );
          }

          if (!_.isUndefined(stateObj)) {
            this.setState(stateObj);
          }
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  async saveKycDocuments(event) {
    const { allowKycSubmit } = this.state;
    let _this = this;
    let uploadedFileSize =
      _.size(this.uploadImageArray) + _.size(this.uploadMultipleImageArray);
    if (
      uploadedFileSize > 0 &&
      (uploadedFileSize >= _this.userRequiredUploadFileCounter ||
        _this.userRequiredUploadFileCounter <= _this.userUploadedFileCounter)
    ) {
      this.setState({
        buttonLoading: true,
      });
      const { emdId } = this.props.match.params;
      const data = new FormData();
      data.append("emdId", emdId);
      if (!_.isUndefined(this.uploadImageArray)) {
        _.forEach(this.uploadImageArray, function (imageValue) {
          data.append(imageValue.name, imageValue.file);
          data.append(
            imageValue.name + "_kycDocumentId",
            imageValue.documentTypeId
          );
        });
      }

      if (!_.isUndefined(this.uploadMultipleImageArray)) {
        _.forEach(this.uploadMultipleImageArray, function (imageValue) {
          if (!_.isEmpty(imageValue.files)) {
            _.forEach(imageValue.files, function (image) {
              let size = image.file.size;
              let type = image.file.type;
              let lastModified = image.file.lastModified;
              if (
                size > 0 &&
                size < 5000000 &&
                !_.isUndefined(type) &&
                !_.isUndefined(lastModified) &&
                lastModified !== "" &&
                lastModified !== null
              ) {
                data.append(imageValue.name + "[]", image.file);
              } else {
                data.append(
                  imageValue.name + "_existed_files[]",
                  image.file.name
                );
              }
            });
            data.append(
              imageValue.name + "_kycDocumentId",
              imageValue.documentTypeId
            );
          }
        });
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.props.authToken,
        },
      };
      axios
        .post(API_ROOT + "/user/emd/saveKycDocuments", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({
              buttonLoading: false,
            });
            if (response.data.code === 200) {
              toast.success(response.data.message);
              _this.props.history.push(`/kyc/relationship/disclosure/${emdId}`);
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          _this.setState({
            buttonLoading: false,
          });
          toast.error(messageConstants.ERROR_MSG_VALID_FILES);
        });
    } else {
      if (
        allowKycSubmit &&
        _this.userUploadedFileCounter >= _this.userRequiredUploadFileCounter
      ) {
        this.props.history.push("/kyc_submit");
      } else {
        let message = messageConstants.ERROR_MSG_REQUIRED_FILES;
        _this.setState({
          buttonLoading: false,
        });
        toast.error(message);
      }
    }
  }

  async fileUpdated(
    isMultiple = false,
    documentTypeName,
    documentTypeId,
    fileItems,
  ) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      let { allowKycSubmit } = this.state;

      if (isMultiple === true) {
        var temp = {};
        temp.documentTypeId = documentTypeId;
        temp.files = fileItems;
        temp.name = documentTypeName;
        this.uploadMultipleImageArray[documentTypeName] = temp;
        if (allowKycSubmit) {
          this.userUploadedFileCounter += 1;
        }
      } else {
        let size = fileItems[0].file.size;
        let type = fileItems[0].file.type;
        let lastModified = fileItems[0].file.lastModified;
        if (
          size > 0 &&
          size < 5000000 &&
          !_.isUndefined(type) &&
          !_.isUndefined(lastModified) &&
          lastModified !== "" &&
          lastModified !== null
        ) {    
          var uploadTemp = {};
          uploadTemp.documentTypeId = documentTypeId;
          uploadTemp.file = fileItems[0].file;
          uploadTemp.name = documentTypeName;
          this.uploadImageArray[documentTypeName] = uploadTemp;
          if (allowKycSubmit) {
            this.userUploadedFileCounter += 1;
          }
        }
      }
    }
  }

  async fileRemoved() {
    let { allowKycSubmit } = this.state;
    if (allowKycSubmit) {
      this.userUploadedFileCounter -= 1;
    }
  }

  render() {
    const { kycDocumentTypeList, displayNote, termsOfEngagementDataAdded } =
      this.state;
    let { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let displayDocumentTypes = "";
    let _this = this;
    if (
      !_.isUndefined(kycDocumentTypeList) &&
      !_.isEmpty(kycDocumentTypeList)
    ) {
      displayDocumentTypes = kycDocumentTypeList.map(
        (parentDocumentType, mainIndex) => (
          <div key={mainIndex} className="col-md-12">
            <div className="mt-4">
              <h5
                className={
                  "color-white " +
                  (parentDocumentType.children.length > 1 ? "" : "d-none hide")
                }
              >
                {parentDocumentType.label}
              </h5>
              <div className={parentDocumentType.children.length > 1 ? "d-none hide" : ""}>
                {parentDocumentType.children.map((documentType, index) => (
                  <div key={index} className="card-horizontal-padding mt-4">
                    <div className="element-wrapper custom-element-box">
                      <div className="element-box ">
                        <h5 className="form-header">
                          {documentType.label}{" "}
                          <span
                            className={
                              "required-field " +
                              (documentType.required === true
                                ? ""
                                : "d-none hide")
                            }
                          >
                            *
                          </span>
                        </h5>
                        <div className="dropzoneIdContainer">
                          <div className="row">
                            <div className="col-md-7">
                              <FilePond
                                ref={(ref) =>
                                  (this.uploadInput[documentType._id] = ref)
                                }
                                files={
                                  this.state[documentType._id + "StateFiles"]
                                }
                                allowMultiple={documentType.multiple || false}
                                allowFileTypeValidation={true}
                                acceptedFileTypes={[
                                  "image/*",
                                  "application/pdf",
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]}
                                instantUpload={false}
                                allowFileSizeValidation={true}
                                allowImagePreview={false}
                                maxFileSize="5MB"
                                credits={false}
                                server={{
                                  load: (
                                    source,
                                    load,
                                    error,
                                    progress,
                                    abort,
                                    headers
                                  ) => {
                                    var myRequest = new Request(source);
                                    fetch(myRequest).then(function (response) {
                                      response.blob().then(function (myBlob) {
                                        load(myBlob);
                                      });
                                    });
                                  },
                                }}
                                onremovefile={(file) => {
                                  _this.fileRemoved();
                                }}
                                onupdatefiles={(fileItems) => {
                                  if (
                                    !_.isEmpty(fileItems) &&
                                    fileItems[0].fileSize < 5000000
                                  ) {
                                    if (
                                      fileItems[0].fileExtension === "pdf" ||
                                      fileItems[0].fileExtension === "docx"
                                    ) {
                                      _this.fileUpdated(
                                        documentType.multiple,
                                        documentType.name,
                                        documentType._id,
                                        fileItems,
                                        documentType.required
                                      );
                                    } else if (
                                      (fileItems[0].fileExtension == "jpeg" ||
                                        fileItems[0].fileExtension == "jpg" ||
                                        fileItems[0].fileExtension == "gif" ||
                                        fileItems[0].fileExtension == "tiff" ||
                                        fileItems[0].fileExtension == "psd" ||
                                        fileItems[0].fileExtension == "eps" ||
                                        fileItems[0].fileExtension == "ai" ||
                                        fileItems[0].fileExtension == "indd" ||
                                        fileItems[0].fileExtension == "raw" ||
                                        fileItems[0].fileExtension == "png") &&
                                      fileItems[0].fileSize < 500000
                                    ) {
                                      _this.fileUpdated(
                                        documentType.multiple,
                                        documentType.name,
                                        documentType._id,
                                        fileItems,
                                        documentType.required
                                      );
                                    } else if (
                                      (fileItems[0].fileExtension == "jpeg" ||
                                        fileItems[0].fileExtension == "jpg" ||
                                        fileItems[0].fileExtension == "gif" ||
                                        fileItems[0].fileExtension == "tiff" ||
                                        fileItems[0].fileExtension == "psd" ||
                                        fileItems[0].fileExtension == "eps" ||
                                        fileItems[0].fileExtension == "ai" ||
                                        fileItems[0].fileExtension == "indd" ||
                                        fileItems[0].fileExtension == "raw" ||
                                        fileItems[0].fileExtension == "png") &&
                                      fileItems[0].fileSize > 500000
                                    ) {
                                      fileItems[0].abortLoad();
                                      toast.error(
                                        "Please make sure image size is under 500kb"
                                      );
                                    }
                                  } else if (
                                    !_.isEmpty(fileItems) &&
                                    fileItems[0].fileSize > 5000000
                                  ) {
                                    fileItems[0].abortLoad();

                                    toast.error(
                                      "Please make sure document size is under 5mb"
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div className="col-md-5">
                              <ul className="text-small text-default-color mgtp-sm-3">
                                <li className="mt-1">
                                  {documentType.label} should be good condition
                                  and clearly visible.
                                </li>
                                <li className="mt-1">
                                  Make sure that there is no light glare on the{" "}
                                  {documentType.label}.
                                </li>
                                <li className="mt-1">
                                  {documentType.label} must not be expaired.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      );
    }

    const { emdId } = this.props.match.params;

    return (
      <div className="kyc-container kyc-document-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <ul className="progressbar d-flex p-0">
                  <li>Fill Details</li>
                  <li>Beneficial Ownership Information</li>
                  <li className="active">Upload</li>
                  <li>Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
            </div>

            {displayNote && (
              <div className="mt-3 d-none hide">
                <h6 className="color-white">
                  Note : All documents requested need to be signed/stamped by a
                  registered lawyer, auditor. Documents must either be in the
                  English language or officially translated.
                </h6>
              </div>
            )}

            <div className="row mt-3">{displayDocumentTypes}</div>

            <div className="d-flex justify-content-end card-inner-padding kyc-btn-group">
              <Link className="btn cancel-button mr-2" to="/dashboard">
                Do it later
              </Link>
              <Link
                to={`/kyc/beneficial/ownership/information/${emdId}`}
                className="color-white"
              >
                <button className="btn update-button ml-2" type="button">
                  Previous
                </button>
              </Link>
              <button
                className="btn update-button ml-0"
                type="submit"
                onClick={this.saveKycDocuments}
                disabled={buttonDisabled}
              >
                Next
                {buttonLoading && (
                  <i className="fa-spin fa fa-spinner text-white ml-1" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default KycUpload;
