import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import * as messageConstants from "../../utils/Messages";
class ListingProgressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      progressReportFiles: [],
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.LISTING_PROGRESS_REPORT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  async getRecords() {
    const api = new Api();
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let listingId = this.props.match.params.id;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("supplier/listing/progress/list", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
          listingId: listingId,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.progressReports,
          totalSize: response.data.totalProgressReports,
          progressReportFiles: response.data.progressReportFiles,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag, progressReportFiles } =
      this.state;
    let listingId = this.props.match.params.id;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "title",
        text: "Title",
      },
      {
        headerClasses: "text-bold",
        dataField: "categoryId",
        text: "Category",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var categoryName =
            row && row.categoryId && row.categoryId.title
              ? row.categoryId.title
              : "N/A";
          return <div>{categoryName}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "shortDescription",
        text: "Description",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var location = cell !== "" && cell !== null ? cell : "N/A";
          return <div>{location}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        formatter: function (cell) {
          let labelClassName =
            cell === "p"
              ? "badge-warning"
              : cell === "a"
              ? "badge-primary"
              : "badge-danger";
          let label =
            cell === "p" ? "Pending" : cell === "a" ? "Accepted" : "Rejected";
          return (
            <div className="text-left">
              <div>
                <span className={"badge " + labelClassName}>{label}</span>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "document",
        text: "View File",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <a
                href={progressReportFiles[row._id]}
                target="_blank"
                rel="noopener noreferrer"
              >
                View File
              </a>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var date = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{date}</div>;
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No progress report found!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box">
                <Link
                  to={`/campaign/progress/${listingId}/add`}
                  className="btn btn-sm btn-primary pull-right"
                >
                  Add Progress Report
                </Link>
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListingProgressList;
