import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { ExportExcel } from "../ExportExcel/ExportExcel";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  niceNumberDecimalDisplay,
  sendTokens,
  ethAddressDisplay,
} from "../../utils/Util";
import AddClientModal from "./AddClientModal";
import { API_ROOT } from "../../services/api-config";
import _ from "lodash";

const STATUS = {
  INITIAL: "initial",
  NOT_LOGGED: "not-logged",
  ERROR: "error",
  SUCCESS: "success",
  LOADING: "loading",
  PROCESSING: "processing",
  MISSING_METAMASK: "missing-metamask",
  MISSING_CONTRACT_ADDRESS: "missing-contract-address",
  MISMATCH_FROM_ADDRESS: "mimatch-from-address",
};

class Shareholders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filterByName: "",
      filterByEmail: "",
      fileName: "Shareholders",
      filterByType: "",
      getCounter: 0,
      loading: false,
      csvData: [],
      status: STATUS.LOADING,
      tokenSymbol: "",
    };
  }

  refreshCurrenttable = () => {
    this.getRecords();
  };

  getRecords = async () => {
    const api = new Api();
    const { sizePerPage, page, filterByName, filterByEmail } = this.state;
    this.setState({ loading: true });
    const authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/shareholders/list", {
          sizePerPage: sizePerPage,
          page: page,
          filterByName,
          filterByEmail,
        });
      if (response.code === 200) {
        this.setState(
          {
            renderFlag: true,
            loading: false,
            data: response.data.shareholderList,
            getCounter: ++this.state.getCounter,
            totalSize: response.data.totalShareHolders,
            tokenSymbol: response.data.tokenSymbol,
          },
          () => {
            if (this.state.getCounter === 1) {
              this.getAllData();
            }
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  };

  updateStatus = (status) => {
    this.setState({
      status,
    });
  };

  getAllData = () => {
    try {
      const { totalSize } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      api
        .setToken(authenticationToken)
        .get("user/shareholders/list", {
          page: 1,
          sizePerPage: totalSize,
        })
        .then(async (response) => {
          let listArr = [];
          if (
            response.code === 200 &&
            response.data &&
            response.data.shareholderList
          ) {
            const symbol = response.data.tokenSymbol;
            response.data.shareholderList.map((data) => {
              let list = {};
              list["NAME"] = data.userId && data.userId.fullName;
              list["EMAIL"] = data.userId && data.userId.email;
              list["OUTSTANDING TOKENS"] = `${niceNumberDecimalDisplay(
                data.tokens,
                2
              )} ${symbol}`;
              list["ISSUED TOKENS"] = `${niceNumberDecimalDisplay(
                data.totalTokenSent,
                2
              )} ${symbol}`;
              list["WHITELISTED ADDRESS"] =
                data.userId && data.userId.receivingEthAddress;
              listArr.push(list);
            });
            this.setState({
              csvData: listArr,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  showClientModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  componentDidMount() {
    document.title =
      messageConstants.SHAREHOLDERS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords = () => {
    this.getRecords();
  };

  clearFilterRecords = () => {
    this.setState(
      {
        filterByEmail: "",
        filterByName: "",
        filterByType: "",
      },
      () => {
        this.getRecords();
      }
    );
  };

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  sendTokens = async (
    listedTokenContractAddress,
    receivingEthAddress,
    tokens,
    transactionId
  ) => {
    await window.ethereum.enable();
    if (listedTokenContractAddress && receivingEthAddress && tokens) {
      const response = await sendTokens(
        listedTokenContractAddress,
        receivingEthAddress,
        tokens
      );
      if (response && response.txHash) {
        const api = new Api();
        await api.create("user/issuer/token/minted", {
          transactionId,
          txHash: response.txHash,
        });
      }
    } else {
      const erroMsg = !receivingEthAddress
        ? "User's wallet address is not whitelisted"
        : !tokens || tokens <= 0
        ? "Please enter a valid token."
        : "";
      toast.error(erroMsg);
    }
  };

  addClientRequest = () => {
    const { clientFile } = this.state;
    let authenticationToken = this.props.authToken;
    const _this = this;
    if (clientFile) {
      this.setState({ clientAddLoading: true });
      const data = new FormData();
      if (clientFile) {
        data.append("clientFile", clientFile);
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authenticationToken,
        },
      };
      axios
        .post(API_ROOT + "/user/issuer/shareholders/add", data, config)
        .then(function (response) {
            if (response.data.code === 200) {
              _this.setState({
                clientAddLoading: false,
              });
              toast.success(response.data.message);
              _this.onCloseModal();
              _this.getRecords();
            } else {
              toast.error(response.data.message);
            }
        })
        .catch(function (error) {
          console.log("error", error);
          _this.setState({
            clientAddLoading: false,
          });
          toast.error(error.response.data && error.response.data.message);
        });
    } else {
      const errorMsg = !clientFile
        ? "Please upload csv file"
        : "Add client operation failed. Please try again later.";
      toast.error(errorMsg);
    }
  };

  handleUploadFile = (event) => {
    if (event.target.files[0]) {
      this.setState({
        clientFile: event.target.files[0],
      });
    }
  };

  copyWhiteListAddress = () => {
    let msg = messageConstants.WHITELIST_ADDRESS_COPIED;
    toast.success(msg);
  };

  render() {
    const {
      data,
      sizePerPage,
      page,
      renderFlag,
      filterByName,
      filterByEmail,
      filterByType,
      loading,
      totalSize,
      tokenSymbol,
    } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Name",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{cell && cell.fullName ? cell.fullName : ""}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Email",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>{cell && cell.email ? cell.email : ""}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "tokens",
        text: "Outstanding Tokens",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {niceNumberDecimalDisplay(cell, 2)} {tokenSymbol}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "totalTokenSent",
        text: "Issued Tokens",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>
                {niceNumberDecimalDisplay(cell, 2)} {tokenSymbol}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "Whitelisted Address",
        sort: true,
        formatter: function (cell) {
          return (
            <div className="text-left">
              <div>
                {cell && cell.receivingEthAddress
                  ? ethAddressDisplay(cell.receivingEthAddress)
                  : ""}

                {cell && cell.receivingEthAddress && (
                  <CopyToClipboard
                    text={cell.receivingEthAddress}
                    onCopy={() => _this.copyWhiteListAddress()}
                  >
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2"
                      aria-hidden="true"
                    ></i>
                  </CopyToClipboard>
                )}
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        isDummyField: true,
        text: "operations",
        sort: true,
        formatter: function (cell, row) {
          const outstandingTokens =
            parseFloat(row.tokens) - parseFloat(row.totalTokenSent);
          return (
            <div className="text-left">
              <div className="d-inline-block">
                <div className="btn-group mr-1 mb-1">
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="btn default-border-btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuButton6"
                    type="button"
                  >
                    Action
                  </button>
                  <div
                    aria-labelledby="dropdownMenuButton6"
                    className="dropdown-menu"
                  >
                    {!row.tokenSentStatus && (
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          _this.sendTokens(
                            row.issuerId.listedTokenContractAddress,
                            row.userId.receivingEthAddress,
                            row.tokens,
                            row._id
                          )
                        }
                      >
                        Issue
                      </button>
                    )}

                    {row.userId && (
                      <Link to={"/user_detail/" + row.userId._id}>
                        <button className="dropdown-item">Details</button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive ">
        <BootstrapTable
          remote
          footerClasses="mt-3"
          loading={loading}
          keyField="_id"
          bordered={false}
          data={data}
          columns={columns}
          noDataIndication="No results!"
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };
    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box">
              <h5 className="text-xl">Filter Shareholders</h5>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label className="sr-only"> Name</label>
                    <input
                      className="form-control mb-1 mr-sm-2 mr-xs-1"
                      name="filterByName"
                      id="filterByName"
                      placeholder="Full Name"
                      type="text"
                      onChange={this.onchange}
                      value={filterByName}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label className="sr-only"> Email</label>
                    <input
                      className="form-control mb-1 mr-sm-2 mr-xs-1"
                      placeholder="Email Address"
                      type="text"
                      name="filterByEmail"
                      id="filterByEmail"
                      onChange={this.onchange}
                      value={filterByEmail}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn default-btn"
                  type="button"
                  onClick={this.filterRecords}
                >
                  Filter
                </button>
                <button
                  className="btn default-border-btn ml-2"
                  type="button"
                  onClick={this.clearFilterRecords}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="element-wrapper mt-4">
              <div className="element-box">
                <div className="manage-header">
                  <div>
                    <h5 className="text-xl">Manage Shareholders</h5>
                  </div>
                  <div className="manage-button">
                    <span className="mgtp-xs-3">
                      <button
                        className="btn default-btn"
                        type="button"
                        onClick={() => this.refreshCurrenttable()}
                      >
                        Refresh
                      </button>
                      <button
                        className="btn default-btn"
                        type="button"
                        onClick={() => this.showClientModal()}
                      >
                        Add Shareholders{" "}
                      </button>
                      <ExportExcel
                        csvData={this.state.csvData}
                        fileName={this.state.fileName}
                        timeHorizon={"All"}
                      />
                    </span>
                  </div>
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddClientModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          handleEventsChange={this.handleEventsChange}
          handleUploadFile={this.handleUploadFile}
          addClientRequest={this.addClientRequest}
        />
      </div>
    );
  }
}
export default Shareholders;
