import React, { Component } from "react";
import Modal from "react-responsive-modal";
import csvFile from "../../assets/add_clients.csv";

class AddClientModal extends Component {
  render() {
    const {
      modalOpen,
      onCloseModal,
      clientAddLoading,
      handleUploadFile,
      addClientRequest,
    } = this.props;
    let buttonDisabled = clientAddLoading === true ? true : false;

    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content verifier-modal">
                    <h4 className="onboarding-title">Add Shareholders</h4>
                    <form>
                      <div>
                        <label>
                          Clients CSV format{" "}
                          <a
                            href={csvFile}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        </label>
                      </div>

                      <div className="form-group mt-2">
                        <label className="text-default text-default-color">
                          Upload File
                          <span className="required-field">*</span>
                        </label>
                        <br />
                        <input
                          type="file"
                          name="clientsFile"
                          onChange={handleUploadFile}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="btn default-border-btn"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn default-btn ml-2"
                          type="button"
                          onClick={addClientRequest}
                          disabled={buttonDisabled}
                        >
                          Submit
                          {clientAddLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default AddClientModal;
