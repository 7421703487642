import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as messageConstants from "../../utils/Messages";
class ListingSuccess extends Component {
    async componentWillMount() {
        document.title = messageConstants.LISTING_SUCCESS_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
        this.props.pageProgress('remove');
    }
    render() {
        return (
            <div className="kyc-status-container text-center mt-15-percent">
                <h2 className="mt-5 mb-5">
                    <i className={'progress-icon-container fa fa-check color-white'}></i> Successfully Submitted
                </h2>
                <h5 className="mt-3 mb-5">{messageConstants.LISTING_SUBMITTED_SUCCESS_MSG}</h5>
                <Link to="/campaign/new">
                    <button className='btn btn-primary kyc-status-button'>Submit Another Request</button>
                </Link>
            </div>
        );
    }
}
export default ListingSuccess;