import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from 'lodash';
import DueDiligenceText from './DueDiligenceText';
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import DueDiligenceInputs from './DueDiligenceInputs';
import DueDiligenceDocuments from './DueDiligenceDocuments';

class DueDiligence extends Component {

    constructor() {
        super();
        this.state = {
            companyName: '',
            diligenceQuestionaires: [],
            headingTitle: '',
            nextKey: '',
            prevKey: '',
            stateQuestionaire: {},
            buttonLoading: false,
            diligenceUploadDocumentsDetails: [],
            diligenceTitles: {}
        }
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.submitResult = this.submitResult.bind(this);
        this.submitDocumentResults = this.submitDocumentResults.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.questionaireAnswers = {};
    }

    async componentDidUpdate(prevProps) {
        if (this.props !== prevProps && this.props.match.params.id && this.props.match.params.id !== 'documents') {
            await this.fetchQuetionaireData();
            this.scrollToTop();
        }
    }

    async componentDidMount() {
        document.title = messageConstants.DUE_DILIGENCE_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
        let dueDiligenceType = this.props.match.params.id;
        if (typeof (this.props.pageProgress) === 'function') {
            this.props.pageProgress('display');
        }
        let kycVerifiedResponse = await this.props.checkKycVerified(true);
        if (kycVerifiedResponse.status === true) {
            let stateSet = {
                dueDiligenceType
            };
            let userResponse = kycVerifiedResponse.userDetailsResponse;
            stateSet.companyName = userResponse.data.companyName;
            stateSet.fullName = userResponse.data.fullName;
            if (!_.isEmpty(stateSet)) {
                this.setState(stateSet);
            }
        }
        if (dueDiligenceType !== '' && dueDiligenceType !== 'upload') {
            await this.fetchQuetionaireData();
        }
        if (typeof (this.props.pageProgress) === 'function') {
            this.props.pageProgress('force_remove');
        }
    }

    async fetchQuetionaireData() {
        try {
            let dueDiligenceType = this.props.match.params.id;
            let authenticationToken = this.props.authToken;
            const api = new Api();
            const diligenceDetails = await api.setToken(authenticationToken).get("user/due_diligence_details/", { dueDiligenceType });
            if (diligenceDetails.code === 200) {
                this.setState({
                    diligenceQuestionaires: diligenceDetails.data.diligenceQuestionaires,
                    headingTitle: diligenceDetails.data.headingTitle,
                    nextKey: diligenceDetails.data.nextKey,
                    prevKey: diligenceDetails.data.prevKey,
                    diligenceUploadDocumentsDetails: (diligenceDetails.data.diligenceUploadDocumentsDetails) ? diligenceDetails.data.diligenceUploadDocumentsDetails : [],
                    diligenceTitles: (diligenceDetails.data.diligenceTitles) ? diligenceDetails.data.diligenceTitles : {},
                });
            }
        } catch (error) {
            console.log("Error is");
            console.log(error);
        }
    }

    handleRadioChange(e, value, diligenceId) {
        const questionaireItems = this.state.stateQuestionaire;
        questionaireItems[diligenceId] = value;
        this.setState({ stateQuestionaire: questionaireItems });
    }

    async submitResult(event, nextKey = '') {
        event.preventDefault();
        const { stateQuestionaire } = this.state;
        let authenticationToken = this.props.authToken;
        this.setState({
            buttonLoading: true
        });
        const api = new Api();
        const response = await api.setToken(authenticationToken).create("user/due_diligence/save", {
            'dueDiligenceAnswers': stateQuestionaire
        });
        if (response) {
            this.setState({
                buttonLoading: false
            });
            if (response.code === 200) {
                toast.success(response.message);
                this.props.history.push(`/due_diligence/${nextKey}`);
            } else {
                toast.error(response.message);
            }
        }
    }

    async submitDocumentResults(event, nextKey = '') {
        event.preventDefault();
        const { dataroomUrl } = this.state;
        let authenticationToken = this.props.authToken;
        const api = new Api();
        const response = await api.setToken(authenticationToken).create("user/due_diligence/dataroom/save", {
            dataroomUrl
        });
        if (response) {
            this.setState({
                buttonLoading: false
            });
            if (response.code === 200) {
                toast.success(response.message);
                this.props.history.push(`/dashboard`);
            } else {
                toast.error(response.message);
            }
        }
    }

    scrollToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const { prevKey, nextKey, buttonLoading } = this.state;
        const { match } = this.props;
        const dueDiligenceType = match.params.id;
        return (
            <div className="content-i identification-block">
                <div className="content-box">
                    <div className="kyc-container">
                        {!dueDiligenceType &&
                            <DueDiligenceText {...this.state} />
                        }
                        {dueDiligenceType && dueDiligenceType !== 'documents' &&
                            <DueDiligenceInputs {...this.state} {...this.props} handleRadioChange={this.handleRadioChange} />
                        }
                        {dueDiligenceType && dueDiligenceType === 'documents' &&
                            <DueDiligenceDocuments {...this.state} {...this.props} handleChange={this.handleChange} />
                        }
                        <div className="row">
                            <div className="col-md-12 text-right button-center mt-4">
                                {!dueDiligenceType &&
                                    <Link to="/due_diligence/corporate_documents" className="color-white text-underline">
                                        <button className="btn modal-button update-button" type="button">Next</button>
                                    </Link>
                                }
                                {dueDiligenceType && dueDiligenceType !== 'documents' &&
                                    <div>
                                        <button className="btn update-button" type="button" style={{ margin: '0' }}>
                                            <Link className="update-button" to={`/due_diligence/${prevKey}`} style={{ margin: '0' }}>
                                                Previous
                                            </Link>
                                        </button>
                                        <button className="btn update-button color-white" type="button" onClick={(e) => this.submitResult(e, nextKey)} disabled={buttonLoading}>Next {buttonLoading && (
                                            <i className="fa-spin fa fa-spinner text-white ml-1" />
                                        )}</button>
                                    </div>
                                }
                                {dueDiligenceType && dueDiligenceType === 'documents' &&
                                    <div>
                                        <button className="btn update-button" type="button" style={{ margin: '0' }}>
                                            <Link className="update-button" to={`/due_diligence/${prevKey}`} style={{ margin: '0' }}>
                                                Previous
                                            </Link>
                                        </button>
                                        <button className="btn update-button" type="button" onClick={(e) => this.submitDocumentResults(e, nextKey)}>Next</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}
export default DueDiligence;