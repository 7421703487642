import React from 'react';
import { toast } from 'react-toastify';
class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};       
  }

  async componentDidMount(){
    let authenticationToken = this.props.authToken;
    if(authenticationToken !== '' && authenticationToken !== null)
    {
      toast.success('Logged out successfully.');
      this.props.logout();
      /*const api = new Api();
      const response =  await api.create("logout", {
        authenticationToken: authenticationToken
      });
      if (response.status === "success") 
      {
        toast.success(response.message);
        this.props.logout();
      }
      else
      {
        toast.error(response.message);
      }*/
    }
  }

  render() {
    return (
      <div className="register-container"></div>
      );
  }
}
export default Logout;