import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import Web3 from "web3";

export const niceNumberDisplay = (val) => {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1,$2");
    }
    return val;
};

export const niceNumberDecimalDisplay = (value, decimalPoint) => {
    let niceNumber = 0;
    if (!_.isUndefined(value)) {
        niceNumber = parseFloat(value);
        let decimal = !_.isUndefined(decimalPoint) ? decimalPoint : 3;
        if (decimal > 0) {
            niceNumber =
                niceNumber > 0 ?
                niceNumber.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, "$&,") :
                0;
        } else {
            niceNumber =
                niceNumber > 0 ?
                niceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
                0;
        }
    }
    return niceNumber;
};

export const tooltipNumberDisplay = (value, symbol, action = "append") => {
    let finalNumber = "";
    if (!_.isUndefined(value) && !_.isNull(value)) {
        let niceNumber = parseFloat(value);
        let numberAsString = value.toString();
        if (
            numberAsString.indexOf(".") === -1 ||
            numberAsString.split(".")[1].length < 3
        ) {
            niceNumber =
                niceNumber > 0 ?
                niceNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") :
                0;
        } else {
            niceNumber =
                niceNumber > 0 ? numberAsString.replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
        }
        if (!_.isUndefined(symbol)) {
            finalNumber =
                action === "append" ?
                niceNumber + " " + symbol :
                symbol + " " + niceNumber;
        } else {
            finalNumber = niceNumber;
        }
    } else {
        finalNumber = 0;
    }
    return finalNumber;
};

export const ethAddressDisplay = (ethAddress) => {
    let ETHAddressDisplay = "";
    if (!_.isUndefined(ethAddress)) {
        ETHAddressDisplay = ethAddress.substr(0, 6) + "..." + ethAddress.substr(-4);
    }
    return ETHAddressDisplay;
};

export const sendTokens = async(
    tokenContractAddress,
    whitelistedAddress = "",
    tokenValue,
    decimalPoints = 18
) => {
    try {
        if (tokenContractAddress && whitelistedAddress && tokenValue > 0) {
            let finalTokenValue = Math.ceil(Number(tokenValue));
            finalTokenValue = finalTokenValue * Math.pow(10, decimalPoints);
            finalTokenValue = await toPlainString(finalTokenValue);
            const signer = new ethers.providers.Web3Provider(
                window.ethereum
            ).getSigner();
            const contractABI = await getABIOfContact(tokenContractAddress);
            const network =
                process.env.REACT_APP_NODE_ENV === "test" ||
                process.env.REACT_APP_NODE_ENV === "dev" ?
                "kovan" :
                "mainnet";

            // check if user is not whitelisted
            const provider = ethers.getDefaultProvider(network);

            const contract1 = new ethers.Contract(
                tokenContractAddress,
                contractABI,
                provider
            );
            const isWhitelistedFlag = await contract1.isWhitelisted(
                whitelistedAddress
            );

            if (!isWhitelistedFlag) {
                toast.error(`please whitelist this address to send tokens.`);
                return false;
            }

            if (contractABI) {
                const contract = new ethers.Contract(
                    tokenContractAddress,
                    contractABI,
                    signer
                );
                const stakeResponse = await contract.mint(
                    whitelistedAddress,
                    finalTokenValue
                );
                if (stakeResponse.txHash) {
                    toast.success(`Token issued successfully.`);
                }
                return stakeResponse;
            } else {
                toast.error(`Unable to process for whitelisting transaction.`);
            }
        }
    } catch (error) {
        const errorMessage = error.message;
        toast.error(errorMessage);
    }
};

export const fetchNetwork = () => {
    return new Promise((resolve, reject) => {
        const { web3 } = window;

        web3 &&
            web3.version &&
            web3.version.getNetwork((err, netId) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(netId);
                }
            });
    });
};

/* function getAccounts1() {
  try {
    const { web3 } = window;
    return web3.eth.accounts;
  } catch (e) {
    return [];
  }
} */

const getAccounts = async() => {
    try {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        return accounts;
    } catch (e) {
        return [];
    }
};

export const fetchAccounts = () => {
    return new Promise((resolve, reject) => {
        const { web3 } = window;
        const ethAccounts = getAccounts();

        if (_.isEmpty(ethAccounts)) {
            web3 &&
                web3.eth &&
                web3.eth.getAccounts((err, accounts) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(accounts);
                    }
                });
        } else {
            resolve(ethAccounts);
        }
    });
};

const getABIOfContact = async(contractAddress) => {
    try {
        const apiUrl =
            process.env.REACT_APP_NODE_ENV === "test" ||
            process.env.REACT_APP_NODE_ENV === "dev" ?
            "https://api-kovan.etherscan.io/api" :
            "https://api.etherscan.io/api";
        const url = `${apiUrl}?module=contract&action=getabi&address=${contractAddress}&apikey=${"APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V"}`;
        return fetch(url)
            .then((response) => response.json())
            .then(async(data) => {
                if (data && data.status === "1") {
                    return data.result || null;
                }
                return null;
            })
            .catch((error) => {
                console.error("error: ", error);
                return null;
            });
    } catch (error) {
        console.error("error: ", error);
    }
};

export const fetchTokenDetails = async(
    tokenContractAddress,
    issuedTokenOnly = false
) => {
    try {
        if (!tokenContractAddress) {
            return false;
        }
        const network =
            process.env.REACT_APP_NODE_ENV === "test" ||
            process.env.REACT_APP_NODE_ENV === "dev" ?
            "kovan" :
            "mainnet";

        if (tokenContractAddress) {
            const contractABI = await getABIOfContact(tokenContractAddress);
            const provider = ethers.getDefaultProvider(network);

            if (!contractABI) {
                const responseAr = {
                    businessNumber: "",
                    country: "",
                    issuerName: "",
                    legend_us: "",
                    province: "",
                    website: "",
                    name: "",
                    owner: "",
                    symbol: "",
                    totalSupply: "",
                    decimals: "",
                };
                return responseAr;
            }

            const contract1 = new ethers.Contract(
                tokenContractAddress,
                contractABI,
                provider
            );

            if (issuedTokenOnly) {
                let totalSupply = await contract1.totalSupply();
                const decimals = await contract1.decimals();
                totalSupply = totalSupply / Math.pow(10, decimals);
                return totalSupply;
            }

            const BusinessNumber = await contract1.BusinessNumber();
            const Country = await contract1.Country();
            const IssuerName = await contract1.IssuerName();
            const Legend_US = await contract1.Legend_US();
            const Province = await contract1.Province();
            const Website = await contract1.Website();
            const name = await contract1.name();
            const owner = await contract1.owner();
            const symbol = await contract1.symbol();
            const decimals = await contract1.decimals();
            let totalSupply = await contract1.totalSupply();
            totalSupply = totalSupply / Math.pow(10, decimals);

            const responsear = {
                businessNumber: BusinessNumber,
                country: Country,
                issuerName: IssuerName,
                legend_us: Legend_US,
                province: Province,
                website: Website,
                name,
                owner,
                symbol,
                totalSupply,
                decimals,
            };

            return responsear;
        }
    } catch (error) {
        const errorMessage = error.message;
        toast.error(errorMessage);
    }
};

export const createWallet = async() => {
    // let randomWallet = ethers.Wallet.createRandom();
    // console.log("randomWallet", randomWallet);
    // console.log("Wallet Private Key", randomWallet.privateKey);
    return "";
};

const toPlainString = async (num) => {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
};

const LOCALSTORAGE_KYC_KEY = "_kycDetails";
export const saveKycLocalCache  = (data) => {
  if (!_.isEmpty(data)) {
    const json = JSON.stringify(data);
    localStorage.setItem(LOCALSTORAGE_KYC_KEY, json);
  }
}

export const getKycLocalCache = () => {
  const json = localStorage.getItem(LOCALSTORAGE_KYC_KEY);
  if (json) {
    return JSON.parse(json);
  }
  return {};
}

export const clearKycLocalCache = () => {
  localStorage.removeItem(LOCALSTORAGE_KYC_KEY);
}

export const pageProgress = (action) => {
    console.log(`pageProgress:${action}`);
    const body = document.body;
    if (action === "remove") {
      body.classList.add("page-loaded");
    } else if (action === "force_remove") {
      setTimeout(() => {
        body.classList.add("page-loaded");
      }, 5000);
    } else {
      body.classList.remove("page-loaded");
    }
  };
  