import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import { API_ROOT } from "../../services/api-config";
import { toast } from "react-toastify";
import Api from "../../services/api";

const SubscriptionAgreement = function ({ authToken }) {
  const [options, setOptions] = useState([]);
  const [fileList, setFileList] = useState([{isUploaded: false}]);
  const [dealerSelects, setDealerSelect] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const inputRef = {};

  useEffect(async () => {
    const api = new Api();
    const response = await api
      .setToken(authToken)
      .get("user/emd/getSelectedDealer");
    if (response.code === 200) {
      const { selectedDealers } = response.data;
      if (selectedDealers) {
        const options = selectedDealers.map(dealer => {
          return {
            value: dealer._id,
            label: `${dealer.companyName} - ${dealer.country}`
          }
        });
        setOptions(options);
      }
    }
  }, [])

  useEffect(async() => {
    await fetchFiles();
  }, [])

  useEffect(() => {
    if (_.isEmpty(fileList)) {
      return setDealerSelect({});
    }
    const dealers = {};
    for(const file of fileList) {
      if (file.country) {
        dealers[file.id] = true;
      }
    }
    setDealerSelect(dealers);
  }, [fileList]);

  const fetchFiles = async () => {
    const api = new Api();
    const response = await api
      .setToken(authToken)
      .get("supplier/subscription/agreement");
    if (response.code === 200) {
      if (!_.isEmpty(response.data)) {
        const fileList = await response.data.map(data => {
          return {
            isUploaded: true,
            dealerId: data.dealerId,
            country: data.country,
            companyName: data.companyName,
            location: data.location
          }
        });
        setFileList(fileList);
      }
    }
  }

  const onAddNewAgreement = () => {
    fileList.push({isUploaded: false});
    setFileList([...fileList])
  }

  const onRemoveAgreement = (index) => {
    fileList.splice(index, 1);
    setFileList([...fileList])
  }

  const onSelectDealer = (index, selectOption) => {
    if (!dealerSelects[selectOption.value]) {
      const file = fileList[index]
      // important
      if (_.isUndefined(file.file)) {
        file.isUploaded = false;
        delete file.location;
      }
      const parseLabel = selectOption.label.split(' - ');
      fileList[index].dealerId = selectOption.value;
      fileList[index].companyName = parseLabel[0];
      fileList[index].country = parseLabel[1];
      setFileList([...fileList]);
    }
  }

  const onUploadFile = (event, index) => {
    fileList[index].isUploaded = true;
    fileList[index].file = event.target.files[0];
    setFileList([...fileList]);
  }

  const isFormValid = () => {
    if (_.isEmpty(fileList)) {
      return false;
    }
    for (const file of fileList) {
      if (!file.isUploaded || _.isUndefined(file.dealerId)) {
        return false;
      }
    }
    return true;
  }

  const onSubmitAgreement = () => {
    setButtonLoading(true);
    const formData = new FormData();
    for (const fileInfo of fileList) {
      const {dealerId, file, country, companyName} = fileInfo;
      formData.append(dealerId, file);
      formData.append('country', country);
      formData.append('companyName', companyName);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: authToken,
      },
    };
    axios
      .post(API_ROOT + "/supplier/subscription/agreement", formData, config)
      .then(function ({data}) {
        setButtonLoading(false);
        toast.success(data.message);
        fetchFiles();
      })
      .catch(function (error) {
        setButtonLoading(false);
        toast.error("Subscription agreement not saved successfully. Please try again later.");
      });
  }

  const renderDocuments = () => {
    if (!_.isEmpty(fileList)) {
      return _.map(fileList, (file, index) => {
        const {isUploaded} = file;
        return (
          <div className="col-lg-9 col-md-12 col-sm-12" key={index}>
            <div className="mt-2">
              <div className="row">
                <div className="col-md-3 col-lg-5 col-sm-6 col-xs-6 fix-width">
                  <Select
                    className="country-select2"
                    classNamePrefix="cntry"
                    placeholder="Select Dealer"
                    onChange={(selectOption) => onSelectDealer(index, selectOption)}
                    options={options}
                    value={fileList[index].dealerId ? {
                      value: fileList[index].dealerId,
                      label: `${fileList[index].companyName} - ${fileList[index].country}`
                    }: ""}
                  />
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 text-center fix-width">
                  {isUploaded && (
                    <i className="fa fa-check filesuccess"/>
                  )}
                  {!isUploaded && (
                    <i className="fa fa-times fileerror"/>
                  )}
                  <a href={file.location}
                     target="_blank"
                     rel="noopener noreferrer"
                     className={file.location && "cursor-pointer"}
                  >
                    <label className="pl-1 font-default font-small">
                      Agreement {" "}
                      <span className="required-sign">*</span>
                    </label>
                  </a>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6 d-flex">
                  <button
                    className="upload-button cursor-pointer font-small"
                    onClick={(e) => inputRef[index].click()}
                  >
                    <span className="button-container">
                      Upload <i className="fa fa-paperclip ml-1 mt-1"/>
                    </span>
                  </button>
                  {
                    fileList.length > 1 && (
                      <button
                        className="upload-button cursor-pointer font-small ml-2"
                        onClick={() => onRemoveAgreement(index)}
                      >
                        <span className="button-container">
                          Remove <i className="fa fa-minus-circle ml-1 mt-1"/>
                        </span>
                      </button>
                    )
                  }
                  <br/>
                  <input
                    key={index}
                    className="listing_file"
                    type="file"
                    ref={(ref) => (inputRef[index] = ref)}
                    name={`agreement_${index}`}
                    onChange={(event) =>
                      onUploadFile(event, index)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  return (
    <>
      <div className="row listing-files-container files-container" style={{ paddingBottom: 140}}>
        {renderDocuments()}
        <div className="col-lg-9 col-md-12 col-sm-12 mt-3 ml-1">
          <i className="fa fa-plus-circle fa-lg cursor-pointer"
             onClick={onAddNewAgreement}>
          </i>
        </div>
      </div>
      <div className="custom-submit-button2 mt-4">
        <button
          className="btn default-btn"
          type="submit"
          onClick={onSubmitAgreement}
          disabled={!isFormValid() || buttonLoading}
        >
          Submit
          {buttonLoading && (
            <i className="fa-spin fa fa-spinner text-white ml-1"/>
          )}
        </button>
      </div>
    </>

  );
}

export default SubscriptionAgreement;