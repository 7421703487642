import React, { Component } from 'react';
import { Radio, RadioGroup } from "react-icheck";
import _ from "lodash";

class DueDiligenceInputs extends Component {

    render() {
        const { diligenceQuestionaires, handleRadioChange, stateQuestionaire } = this.props;
        let questionaireHtml = '';
        if (!_.isUndefined(diligenceQuestionaires) && !_.isEmpty(diligenceQuestionaires)) {
            questionaireHtml = _.map(diligenceQuestionaires, function (questionaire) {
                return (
                    <div className="row" key={Math.random()}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="text-default text-default-color">{questionaire.label}</label>
                                <div className="our-service-container">
                                    <RadioGroup name={`questionaire_${questionaire._id}`}
                                        className="wealth-split-radio-container"
                                        onChange={(e, value) => handleRadioChange(e, value, questionaire._id)}
                                        value={(stateQuestionaire && stateQuestionaire[questionaire._id]) ? stateQuestionaire[questionaire._id] : ((questionaire.answerText) ? questionaire.answerText : '')}>
                                        <Radio
                                            value="Available"
                                            radioClass="iradio_square-blue pr-2 text-default"
                                            increaseArea="20%"
                                            label="Available"
                                        />
                                        <Radio
                                            value="Not Applicable"
                                            radioClass="iradio_square-blue pr-2 text-default"
                                            increaseArea="20%"
                                            label="Not Applicable"
                                        />
                                        <Radio
                                            value="Not Available"
                                            radioClass="iradio_square-blue pr-2 text-default"
                                            increaseArea="20%"
                                            label="Not Available"
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="element-wrapper custom-element-box">
                        <div className="element-box">
                            {questionaireHtml}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DueDiligenceInputs;