import "icheck/skins/all.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-datetime/css/react-datetime.css";
import React from "react";
import _ from "lodash";
import { Checkbox } from "react-icheck";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import validators from "../../validators";
import Api from "../../services/api";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";
import * as utils from "../../utils/Util";

const LOCAL_CACHE_KEY = "_kyc";

class Kyc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName:
        !_.isUndefined(this.props.authUserInfo) &&
          this.props.authUserInfo !== null
          ? this.props.authUserInfo.fullName
          : "",
      dob: "",
      countryId: "",
      countryIdValue: null,
      countryName: "Select country",
      state: "",
      city: "",
      postalCode: "",
      email:
        !_.isUndefined(this.props.authUserInfo) &&
          this.props.authUserInfo !== null
          ? this.props.authUserInfo.email
          : "",
      contactNo: "",
      error: null,
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      agreeCheck: false,
      countriesList: [{ value: "", label: "Select Country" }],
      natureOfClientsBusiness: "",
      insiderOrReportingIssuer: "",
      representativeFirstName: "",
      representativeLastName: "",
    };
    this.optionRef = React.createRef();
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.setConteactNo = this.setConteactNo.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.saveKycBasicDetails = this.saveKycBasicDetails.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.KYC_BASIC_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;

    try {
      const api = new Api();
      const userResponse = await api
        .setToken(authenticationToken)
        .create("user/emd/userKycDetails", {emdId});
      if (userResponse.code === 200) {
        if (userResponse.data.kycStatus === "u" ||
          (userResponse.data.kycStatus === "a" && userResponse.data.isKycVerified === true)) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? `/kyc_status/pending/${emdId}`
              : `/kyc_status/approved/${emdId}`;
          this.props.history.push(redirectUrl);
        } else {
          const localCache = utils.getKycLocalCache()[LOCAL_CACHE_KEY];
          if (userResponse.data.userKycDetail) {
            this.setState({
              fullName:
                userResponse.data.userKycDetail &&
                userResponse.data.userKycDetail.fullName
                  ? userResponse.data.userKycDetail.fullName
                  : this.props.authUserInfo.fullName,
              contactNo: userResponse.data.userKycDetail.contactNo || "",
              postalCode: userResponse.data.userKycDetail.postalCode,
              city: userResponse.data.userKycDetail.city,
              state: userResponse.data.userKycDetail.state,
              countryId: userResponse.data.userKycDetail.countryId
                ? userResponse.data.userKycDetail.countryId._id
                : "",
              countryIdValue: userResponse.data.userKycDetail.countryId
                ? userResponse.data.userKycDetail.countryId._id
                : "",
              countryName: userResponse.data.userKycDetail.countryId
                ? userResponse.data.userKycDetail.countryId.name
                : "",
              agreeCheck: true,
              termsOfEngagementDataAdded:
                userResponse.data.termsOfEngagementDataAdded,
              natureOfClientsBusiness:
                userResponse.data.userKycDetail.natureOfClientsBusiness,
              insiderOrReportingIssuer:
                userResponse.data.userKycDetail.insiderOrReportingIssuer,
              representativeFirstName:
                userResponse.data.userKycDetail.representativeFirstName,
              representativeLastName:
                userResponse.data.userKycDetail.representativeLastName,
            });
          } else if (!_.isEmpty(localCache)) {
            this.setState(localCache);
          }
          const response = await api.get("user/getCountries");
          let countriesList = [];
          if (response.code === 200) {
            if (!_.isUndefined(response.data.countries)) {
              response.data.countries.forEach((country) => {
                let obj = { value: country._id, label: country.name };
                countriesList.push(obj);
              });
              if (!_.isUndefined(countriesList)) {
                this.setState({
                  countriesList: countriesList,
                });
              }
            }
          } else {
            toast.error(userResponse.message);
          }
        }
      } else {
        toast.error(userResponse.message);
      }

      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (error) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  handleGenderChange(event, value) {
    this.setState({ gender: value });
  }

  handleAgreeChange(e, checked) {
    this.setState({ agreeCheck: checked });
  }

  handleDobChange(date) {
    this.setState({ dob: date });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
      countryId: selectedOption.value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  setConteactNo(value) {
    this.setState({
      contactNo: value,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "contactNo", "email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    const { countryId } = this.state;
    if (!countryId) {
      status = false;
    }
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  async saveKycBasicDetails(event) {
    event.preventDefault();
    const { emdId } = this.props.match.params;
    let authenticationToken = this.props.authToken;
    const {
      fullName,
      countryId,
      countryIdValue,
      countryName,
      state,
      city,
      postalCode,
      contactNo,
      email,
      agreeCheck,
      natureOfClientsBusiness,
      insiderOrReportingIssuer,
      representativeFirstName,
      representativeLastName,
    } = this.state;
    const isFormValid = this.isFormValid();
    if (
      !_.isUndefined(agreeCheck) &&
      agreeCheck === true &&
      isFormValid
    ) {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const formData = {
        fullName: fullName,
        countryId: countryId,
        state: state,
        city: city,
        postalCode: postalCode,
        contactNo: contactNo,
        email: email,
        natureOfClientsBusiness,
        insiderOrReportingIssuer,
        representativeFirstName,
        representativeLastName,
      };
      const localCache = utils.getKycLocalCache();
      localCache[LOCAL_CACHE_KEY] = {
        ...formData,
        countryIdValue,
        countryName
      }
      utils.saveKycLocalCache(localCache);
      const response = await api
        .setToken(authenticationToken)
        .create("user/emd/saveKycBasicDetails", { ...formData, emdId});

      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.props.history.push(`/kyc/beneficial/ownership/information/${emdId}`);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      if (!isFormValid) {
        toast.error("Please enter valid details for required fields.");
      } else {
        toast.error("Please accept terms & conditions to proceed.");
      }
    }
  }

  render() {
    let {
      fullName,
      countriesList,
      contactNo,
      email,
      postalCode,
      city,
      state,
      buttonLoading,
      agreeCheck,
      countryName,
      countryIdValue,
      insiderOrReportingIssuer,
      representativeFirstName,
      representativeLastName,
      termsOfEngagementDataAdded,
      natureOfClientsBusiness,
    } = this.state;
    let buttonDisabled = (buttonLoading === true) || !this.isFormValid();
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header">Submit KYC</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <ul className="progressbar d-flex p-0">
                  <li className="active">Fill Details</li>
                  <li className="">Beneficial Ownership Information</li>
                  <li>Upload</li>
                  <li className="">Relationship Disclosure</li>
                  {termsOfEngagementDataAdded && <li>Terms Of Engagement</li>}
                </ul>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-3 row-eq-height card-inner-padding">
              <div className="col-md-6">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <form>
                    <h5 className="text-default-color text-xl">
                      Identity Details
                    </h5>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">
                            {" "}
                            Company name{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={fullName}
                            onChange={this.onchange}
                          />
                          {fullName && this.displayValidationErrors("fullName")}
                        </div>
                      </div>

                      <div className="col-sm-6 cstmField">
                        <div className="form-group">
                          <label className="text-small">
                            {" "}
                            Email Address{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="email"
                            disabled={true}
                            value={email}
                            onChange={this.onchange}
                          />
                          {email && this.displayValidationErrors("email")}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-small">
                            Representative First Name{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="representativeFirstName"
                            id="representativeFirstName"
                            value={representativeFirstName}
                            onChange={this.onchange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 cstmField">
                        <div className="form-group">
                          <label className="text-small">
                            {" "}
                            Representative Last Name{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="representativeLastName"
                            id="representativeLastName"
                            value={representativeLastName}
                            onChange={this.onchange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-small">
                            {" "}
                            Nature of the client’s business{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            name="natureOfClientsBusiness"
                            id="natureOfClientsBusiness"
                            onChange={this.onchange}
                            value={natureOfClientsBusiness}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="text-small">
                            Confirm whether the corporation is an insider of a
                            reporting issuer or any other issuer whose
                            securities are publicly traded{" "}
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="insiderOrReportingIssuer"
                            id="insiderOrReportingIssuer"
                            onChange={this.onchange}
                            value={insiderOrReportingIssuer}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6 mgtp-sm-4 mgtp-xs-4">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <h5 className="text-default-color text-xl">
                    Contact Information
                  </h5>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-small">
                          {" "}
                          Country <span className="required-sign">*</span>
                        </label>
                        <Select
                          autoFocus
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countriesList}
                          placeholder="Select Country"
                          name="countryId"
                          id="country"
                          ref={this.optionRef}
                          onChange={this.handleCountryChange}
                          value={countryIdValue? {
                            label: countryName,
                            value: countryIdValue,
                          }: ""}
                          onMenuOpen={this.onMenuOpen}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-small">
                          {" "}
                          State/Province{" "}
                          <span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          id="state"
                          onChange={this.onchange}
                          value={state}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-small">
                          {" "}
                          City <span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          id="city"
                          onChange={this.onchange}
                          value={city}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-small">
                          {" "}
                          Postal Code <span className="required-sign">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          onChange={this.onchange}
                          value={postalCode}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-small">
                          {" "}
                          Contact Number{" "}
                          <span className="required-sign">*</span>
                        </label>
                        <br />
                        <IntlTelInput
                          preferredCountries={["tw"]}
                          onPhoneNumberChange={(validate, value, countryData) =>
                            this.setConteactNo(value)
                          }
                          numberType="MOBILE"
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          value={contactNo}
                          style={{ width: "100%" }}
                        />
                        {contactNo && this.displayValidationErrors("contactNo")}
                      </div>
                    </div>
                  </div>

                  <div className="form-check mt-3 kyc-agree-container">
                    <Checkbox
                      id="agreeCheck"
                      checkboxClass="icheckbox_square-blue"
                      increaseArea="20%"
                      name="agreeCheck"
                      onChange={this.handleAgreeChange}
                      checked={agreeCheck}
                    />
                    <label htmlFor="agreeCheck" className="ml-2 text-default">
                      I agree to{" "}
                      <Link
                        to="/terms_conditions"
                        className="text-underline"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row card-inner-padding kyc-btn-group mgtp-sm-3"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <div className="col-md-12 text-right d-flex justify-content-end">
                <Link className="btn cancel-button" to="/dashboard">
                  Do it later
                </Link>
                <button
                  className="btn update-button"
                  type="submit"
                  onClick={this.saveKycBasicDetails}
                  disabled={buttonDisabled}
                >
                  Next
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Kyc;