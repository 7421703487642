/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import Avatar from "react-avatar";
import Api from "../../services/api";
import defaultPermianChainImg from "../../assets/img/permiandealer.png";
import * as messageConstants from "../../utils/Messages";

const COUNTRY_NONE = "none";

class EmdList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countrySelected: {},
      dealers: [],
      requestProcessing: true,
      totalDealers: 0,
    };
    this.selectEmdUser = this.selectEmdUser.bind(this);
    this.unselectEmdUser = this.unselectEmdUser.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.EMD_LIST_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    const api = new Api();
    let authenticationToken = this.props.authToken;
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/emd/list");
      if (response.code === 200) {
        this.setState({
          dealers: response.data.dealers,
          requestProcessing: false,
          totalDealers: response.data.totalDealers,
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
      this.setState({
        dealers: [],
        requestProcessing: false,
        totalDealers: 0,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dealers !== this.state.dealers) {
      const countrySelected = {};
      this.state.dealers.map(dealer => {
        if (dealer.selected) {
          const country = dealer.country || COUNTRY_NONE;
          countrySelected[country] = true;
        }
      });
      this.setState({ countrySelected });
    }
  }

  async selectEmdUser(emdId) {
    if (emdId) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      try {
        const response = await api
          .setToken(authenticationToken)
          .create("user/emd/select", { emdId });
        if (response.code === 200) {
          toast.success(response.message);
          const dealers = this.state.dealers.map(dealer => {
            if (dealer._id === emdId) {
              dealer.selected = true;
            }
            return dealer;
          });
          this.setState({
            dealers
          });
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      } catch (e) {
        toast.error("Invalid Request");
      }
    }
  }

  async unselectEmdUser(emdId) {
    if (emdId) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      try {
        const response = await api
          .setToken(authenticationToken)
          .create("user/emd/unselect", {
            emdId,
          });
        if (response.code === 200) {
          toast.success(response.message);
          const dealers = this.state.dealers.map(dealer => {
            if (dealer._id === emdId) {
              dealer.selected = false;
            }
            return dealer;
          });
          this.setState({
            dealers
          });
        }
      } catch (e) {
        toast.error("Invalid Request");
      }
    }
  }

  redirectToKyc(emdId) {
    const redirectUrl = `/kyc/${emdId}`;
    this.props.history.push(redirectUrl);
  }

  renderButtons(dealer, index) {
    if (!dealer.selected) {
      const country = dealer.country || COUNTRY_NONE;
      const disabled = this.state.countrySelected[country];
      return disabled ? (
        <>
          <button
            className="btn btn-md disabled"
            id={`btn-${index}`}>
            Select
          </button>
          <UncontrolledPopover
            trigger="hover"
            placement="bottom"
            target={`btn-${index}`}
          >
            <PopoverBody>
              You have already selected a dealer {dealer.country && `in ${country}`}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ) :
        (
          <button
            className="btn btn-md default-btn"
            onClick={() => this.selectEmdUser(dealer._id)}>
            Select
          </button>
        )
    }
    if (dealer.kycStatus === "u") {
      return (
        <>
          <button className="btn btn-md cancel-button mr-3"
            onClick={() => this.unselectEmdUser(dealer._id)}>
            Unselect
          </button>
          <span className="badge badge-success p-2">
            Under Review
          </span>
        </>
      )
    }
    if (dealer.kycStatus === "a") {
      return (
        <>
          <span className="badge mr-3 py-2 px-2 badge-success">
            Approved
          </span>

          <Menu menuButton={<MenuButton className="btn btn-md default-btn text-white">Actions</MenuButton>}>
            <MenuItem className=""
              onClick={() => this.unselectEmdUser(dealer._id)}>
              Unselect
            </MenuItem>
            <MenuItem className=""
              onClick={() => this.redirectToKyc(dealer._id)}>
              {!dealer.kycStatus || dealer.kycStatus === "c" ? 'Submit KYC' : 'Update KYC'}
            </MenuItem>
          </Menu>
        </>
      )
    }
    if (dealer.kycStatus === "r") {
      return (
        <>
          <button className="btn btn-md cancel-button"
            onClick={() => this.unselectEmdUser(dealer._id)}>
            Unselect
          </button>
          <button className="btn btn-md btn-danger"
            onClick={() => this.redirectToKyc(dealer._id)}>
            Compliance
          </button>
        </>
      )
    }
    if (!dealer.kycStatus ||
      dealer.kycStatus === "p" ||
      dealer.kycStatus === "c") {
      return (
        <>
          <button className="btn btn-md cancel-button"
            onClick={() => this.unselectEmdUser(dealer._id)}>
            Unselect
          </button>
          <button className="btn btn-md default-btn text-white"
            onClick={() => this.redirectToKyc(dealer._id)}>
            {!dealer.kycStatus || dealer.kycStatus === "c" ? 'Submit KYC' : 'Update KYC'}
          </button>
        </>
      )
    }
    return null;
  }

  render() {
    const { dealers, totalDealers, requestProcessing } = this.state;
    let displayDealersList = "";
    if (!_.isUndefined(dealers) && !_.isEmpty(dealers) && totalDealers > 0) {
      dealers.sort((a, b) => {
        if (a.selected === b.selected) {
          return 0;
        }
        return a.selected ? -1 : 1;
      });
      displayDealersList = dealers.map((dealerDetail, index) => (
        <div
          className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4"
          key={index}
        >
          <div className={`post-box emd-box investor-dealer-box ${dealerDetail.selected ? 'emd-selected' : ''}`}>
            <div className="d-flex">
              <div className="avatar">
                {dealerDetail && dealerDetail.avatar && (
                  <Avatar
                    size="82"
                    src={
                      dealerDetail && dealerDetail.avatar
                        ? dealerDetail.avatar
                        : defaultPermianChainImg
                    }
                    round="8px"
                  />
                )}
                {dealerDetail && !dealerDetail.avatar && (
                  <Avatar
                    size="82"
                    name={dealerDetail.companyName}
                    round="8px"
                  />
                )}
              </div>
              <div className="username">
                <h5 className="post-title text-xl">
                  {dealerDetail.companyName ? dealerDetail.companyName : ""}
                </h5>
                <h6 className="post-title mt-2 text-default">
                  {dealerDetail.country ? dealerDetail.country + " - " : ""}{" "}
                  {dealerDetail.position ? dealerDetail.position + " - " : ""}{" "}
                  {dealerDetail.companyName ? dealerDetail.companyName : ""}
                </h6>
              </div>
            </div>
            <div className="description">
              <div className="post-text">
                {_.truncate(dealerDetail.shortSummary, { length: 100 })}
              </div>
            </div>
            <div className="post-foot d-flex row">
              <div className="post-tags col-12 col-xxl-4">
                {dealerDetail.websiteLink && (
                  <a
                    className="badge badge-primary mr-2"
                    href={dealerDetail.websiteLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-globe"></i>
                  </a>
                )}
                {dealerDetail.linkedinLink && (
                  <a
                    className="badge badge-primary mr-2"
                    href={dealerDetail.linkedinLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                )}
                {dealerDetail.originalEmail && (
                  <a
                    className="badge badge-primary mr-2"
                    href={`mailto:${dealerDetail.originalEmail}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-envelope"></i>
                  </a>
                )}
                {dealerDetail.phoneNumber && (
                  <a
                    className="badge badge-primary"
                    href={`callto:${dealerDetail.phoneNumber}`}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-phone"></i>
                  </a>
                )}
              </div>
              <div className="col-12 col-xxl-8 post-action">
                <div>
                  {this.renderButtons(dealerDetail, index)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }

    return (
      <div className="emd-list-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className={`col-12 col-xxl-12`}>
                <div
                  className={`element-wrapper compact card-inner-padding `}
                >
                  <h4 className="element-header text-xl d-flex justify-content-center color-primary">
                    Select Dealers
                  </h4>
                  {requestProcessing === false && totalDealers > 0 && (
                    <div className="element-box-tp">
                      <div className="row mt-4">{displayDealersList}</div>
                    </div>
                  )}
                  {requestProcessing === false && totalDealers === 0 && (
                    <div className="alert mt-3 alert-dark-blue">
                      {messageConstants.EMD_USERS_RECORD_NOT_FOUND}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EmdList;
