import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import UserDashboardMenu from './UserDashboardMenu';
import UserDashboardTopBar from './UserDashboardTopBar';
import CustomHeader from './CustomHeader';
import Footer from './Footer';
import Api from "../../services/api";


class Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            xprTokenPrice: ''
        };
        document.body.classList.add('menu-position-side');
        document.body.classList.add('menu-side-left');
        document.body.classList.add('full-screen');
        document.body.classList.add('with-content-panel');
        document.body.classList.add('user-dashboard-panel');
    }

    async componentDidMount() {
        const api = new Api();
        const { authToken } = this.props;
        try {
            const response = await api.setToken(authToken).get("user/token/price");
            if (response && response.code === 200 && response.data.xprTokenPrice > 0) {
                this.setState({
                    xprTokenPrice: response.data.xprTokenPrice
                })
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    render() {
        const { xprTokenPrice } = this.state;
        return (
            <div className="all-wrapper with-side-panel solid-bg-all user-dashboard-container">
                <ToastContainer hideProgressBar={true} />
                <div>
                    <div className="user-dashboard-header-bg-color header-fixed-menu">
                        <CustomHeader authUserInfo={this.props.authUserInfo} xprTokenPrice={xprTokenPrice} isKycPage={false} />
                    </div>
                    <div className="layout-w center-content">
                        <UserDashboardMenu authUserInfo={this.props.authUserInfo} />
                        <div className="content-w user-dashboard-content">
                            <UserDashboardTopBar authUserInfo={this.props.authUserInfo} />
                            <div className="min-height-80vh">
                                {this.props.children}
                            </div>
                            <div className="base-footer">
                                <Footer color="black" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Base;