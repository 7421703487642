import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import auth from "../../utils/auth";
import Api from "../../services/api";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfileModal from "./EditProfileModal";
import { confirmAlert } from "react-confirm-alert";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNumber: "N/A",
      email: "",
      countryName: "",
      countryIdValue: null,
      changePasswordModalOpen: false,
      editProfileModalOpen: false,
      updateEthAddressButtonLoading: false,
      changePasswordButtonLoading: false,
      editProfileButtonLoading: false,
      toTpUri: "",
      twoFAFormattedKey: "",
      twoFAStatus: false,
      twoFAcode: "",
      receivingEthAddress: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      role: "",
      identificationDataFilled: false,
      fatcaCrsDataFilled: false,
      paymentMethods: [],
      userPaymentDetails: {},
      bankName: "",
      countriesList: [{ value: this.countryIdValue, label: this.countryName }],
    };
    this.optionRef = React.createRef();
  }

  async componentWillMount() {
    const api = new Api();
    const authToken = auth.getToken();
    document.title =
      messageConstants.MY_ACCOUNT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let kycVerifiedResponse = await this.props.checkKycVerified(true);
    if (kycVerifiedResponse.status === true) {
      let stateSet = {};
      let userResponse = kycVerifiedResponse.userDetailsResponse;
      if (!_.isUndefined(userResponse.data.twoFAFormattedKey)) {
        stateSet.twoFAFormattedKey = userResponse.data.twoFAFormattedKey;
      }
      if (!_.isUndefined(userResponse.data.toTpUri)) {
        stateSet.toTpUri = userResponse.data.toTpUri;
      }
      if (!_.isUndefined(userResponse.data.twoFAStatus)) {
        stateSet.twoFAStatus = userResponse.data.twoFAStatus;
      }
      if (!_.isUndefined(userResponse.data.role)) {
        stateSet.role = userResponse.data.role;
      }
      stateSet.countryName = userResponse.data.country;
      stateSet.twoFAStatus = userResponse.data.twoFAStatus;
      stateSet.receivingEthAddress = userResponse.data.receivingEthAddress;
      stateSet.fullName = userResponse.data.fullName;
      stateSet.email = userResponse.data.email;
      stateSet.companyName = userResponse.data.companyName;
      const wireTransferSubscribeTokenDetails =
        userResponse.data.wireTransferDetailSubscribeToken;
      stateSet.bankName = wireTransferSubscribeTokenDetails
        ? wireTransferSubscribeTokenDetails.bankName
        : "";
      stateSet.bankAccountNumber = wireTransferSubscribeTokenDetails
        ? wireTransferSubscribeTokenDetails.bankAccountNumber
        : "";
      stateSet.bankSwiftCode = wireTransferSubscribeTokenDetails
        ? wireTransferSubscribeTokenDetails.bankSwiftCode
        : "";
      stateSet.bankBicCode = wireTransferSubscribeTokenDetails
        ? wireTransferSubscribeTokenDetails.bankBicCode
        : "";
      const wireTransferMinerDetails =
        userResponse.data.wireTransferDetailMinerOrder || "";
      stateSet.minerBankName = wireTransferMinerDetails.bankName || "";
      stateSet.minerBankAccountNumber =
        wireTransferMinerDetails.bankAccountNumber || "";
      stateSet.minerBankSwiftCode =
        wireTransferMinerDetails.bankSwiftCode || "";
      stateSet.minerBicCode = wireTransferMinerDetails.bankBicCode || "";

      if (!_.isUndefined(userResponse.data.phoneNumber)) {
        stateSet.phoneNumber = userResponse.data.phoneNumber;
      }

      const authenticationToken = this.props.authToken;
      const paymentListResponse = await api
        .setToken(authenticationToken)
        .get("supplier/listing/payment/methods", { allowId: "true" });
      if (
        paymentListResponse.code === 200 &&
        paymentListResponse.data &&
        paymentListResponse.data.paymentMethodsList
      ) {
        stateSet.paymentMethods = paymentListResponse.data.paymentMethodsList;
      }

      const paymentMethodsResponse = await api
        .setToken(authenticationToken)
        .get("supplier/payment/methods");
      if (
        paymentMethodsResponse.code === 200 &&
        paymentMethodsResponse.data &&
        paymentMethodsResponse.data.userPaymetsDetail
      ) {
        _.forEach(
          paymentMethodsResponse.data.userPaymetsDetail,
          function (value, key) {
            stateSet["paymentMethod_" + key] = value;
          }
        );
      }

      if (!_.isEmpty(stateSet)) {
        this.setState(stateSet);
      }
    } else {
      this.props.history.push(kycVerifiedResponse.redirectUrl);
    }

    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }

    document.title = messageConstants.MY_ACCOUNT_PAGE_TITLE +
        messageConstants.PAGE_TITLE_SEPERATOR +
        messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    try {
      const api = new Api();
      const userResponse = await api
          .setToken(authToken)
          .create("user/getUserDetails");
      if (userResponse.code === 200) {
        let stateSet = {};
        if (!_.isUndefined(userResponse.data.twoFAFormattedKey)) {
          stateSet.twoFAFormattedKey = userResponse.data.twoFAFormattedKey;
        }
        if (!_.isUndefined(userResponse.data.toTpUri)) {
          stateSet.toTpUri = userResponse.data.toTpUri;
        }
        if (!_.isUndefined(userResponse.data.twoFAStatus)) {
          stateSet.twoFAStatus = userResponse.data.twoFAStatus;
        }
        if (!_.isUndefined(userResponse.data.role)) {
          stateSet.role = userResponse.data.role;
        }
        stateSet.countryName = userResponse.data.country;
        stateSet.twoFAStatus = userResponse.data.twoFAStatus;
        stateSet.receivingEthAddress = userResponse.data.receivingEthAddress;
        stateSet.fullName = userResponse.data.fullName;
        stateSet.email = userResponse.data.email;
        stateSet.companyName = userResponse.data.companyName;
        const wireTransferSubscribeTokenDetails =
            userResponse.data.wireTransferDetailSubscribeToken;
        stateSet.bankName = wireTransferSubscribeTokenDetails
            ? wireTransferSubscribeTokenDetails.bankName
            : "";
        stateSet.bankAccountNumber = wireTransferSubscribeTokenDetails
            ? wireTransferSubscribeTokenDetails.bankAccountNumber
            : "";
        stateSet.bankSwiftCode = wireTransferSubscribeTokenDetails
            ? wireTransferSubscribeTokenDetails.bankSwiftCode
            : "";
        stateSet.bankBicCode = wireTransferSubscribeTokenDetails
            ? wireTransferSubscribeTokenDetails.bankBicCode
            : "";
        const wireTransferMinerDetails =
            userResponse.data.wireTransferDetailMinerOrder || "";
        stateSet.minerBankName = wireTransferMinerDetails.bankName || "";
        stateSet.minerBankAccountNumber =
            wireTransferMinerDetails.bankAccountNumber || "";
        stateSet.minerBankSwiftCode =
            wireTransferMinerDetails.bankSwiftCode || "";
        stateSet.minerBicCode = wireTransferMinerDetails.bankBicCode || "";

        if (!_.isUndefined(userResponse.data.phoneNumber)) {
          stateSet.phoneNumber = userResponse.data.phoneNumber;
        }

        const authenticationToken = this.props.authToken;
        const paymentListResponse = await api
            .setToken(authenticationToken)
            .get("supplier/listing/payment/methods", { allowId: "true" });
        if (
            paymentListResponse.code === 200 &&
            paymentListResponse.data &&
            paymentListResponse.data.paymentMethodsList
        ) {
          stateSet.paymentMethods = paymentListResponse.data.paymentMethodsList;
        }

        const paymentMethodsResponse = await api
            .setToken(authenticationToken)
            .get("supplier/payment/methods");
        if (
            paymentMethodsResponse.code === 200 &&
            paymentMethodsResponse.data &&
            paymentMethodsResponse.data.userPaymetsDetail
        ) {
          _.forEach(
              paymentMethodsResponse.data.userPaymetsDetail,
              function (value, key) {
                stateSet["paymentMethod_" + key] = value;
              }
          );
        }

        if (!_.isEmpty(stateSet)) {
          this.setState(stateSet);
        }
      } else {
        return toast.error(response.message);
      }
      pageProgress("remove");
      const response = await api.get("user/getCountries");
      let countriesList = [];
      if (response.code === 200) {
        if (response.data.countries) {
          response.data.countries.forEach((country) => {
            let obj = { value: country._id, label: country.name };
            countriesList.push(obj);
          });
          if (countriesList) {
            this.setState({
              countriesList: countriesList
            });
          }
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  updateRejectKycStatus = async (userId, type) => {
    const api = new Api();
    const authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .create("user/kyc_status/update/reject");
    if (response && response.code === 200) {
      this.props.history.push("/kyc");
    } else {
      toast.error(response.message);
    }
  };

  updateKyc = () => {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Cancel",
      },
      {
        label: "Yes",
        onClick: () => _self.updateRejectKycStatus(),
      },
    ];
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure want to resubmit KYC?",
      buttons: buttons,
    });
  };

  twoFaAuthenticationChange = async (type = "enable") => {
    let { twoFAcode } = this.state;
    let authenticationToken = this.props.authToken;
    let authUserInfo = this.props.authUserInfo;
    let twoFAStatus = type === "disable" ? false : true;
    if (!!twoFAcode && !!authUserInfo.email) {
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/verify2FA", {
          email: authUserInfo.email,
          code: twoFAcode,
          status: twoFAStatus,
        });
      if (response.code === 200) {
        this.setState({
          twoFAcode: "",
          twoFAStatus: response.data.twoFAStatus,
          toTpUri: response.data.toTpUri,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_TWO_FA_CODE);
    }
  };

  updateReceivingEthAddress = async () => {
    const { receivingEthAddress } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!receivingEthAddress) {
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/receiving-address/update", {
          ethAddress: receivingEthAddress,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS);
    }
  };

  updatePassword = async () => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!oldPassword && !!newPassword && newPassword === confirmNewPassword) {
      this.setState({ changePasswordButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/changePassword", {
          oldPassword: oldPassword,
          newPassword: newPassword,
        });
      this.setState({ changePasswordButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onChangePasswordCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        newPassword !== confirmNewPassword
          ? "Password and confirm password did not matched."
          : messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  };

  updateProfile = async () => {
    const { fullName, phoneNumber, companyName, countryName } = this.state;
    const authenticationToken = this.props.authToken;
    if (!!fullName && !!phoneNumber && !!companyName && !!countryName) {
      this.setState({ editProfileButtonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/profile/edit", {
          fullName: fullName,
          phoneNumber: phoneNumber,
          companyName,
          countryName
        });
      this.setState({ editProfileButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onEditProfileCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  };

  onchange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onNumberChange = (value) => {
    this.setState({ phoneNumber: value });
  };

  openChangePasswordModal = () => {
    this.setState({ changePasswordModalOpen: true });
  };

  onChangePasswordCloseModal = () => {
    this.setState({ changePasswordModalOpen: false });
  };

  openEditProfileModal = () => {
    this.setState({ editProfileModalOpen: true });
  };

  onEditProfileCloseModal = () => {
    this.setState({ editProfileModalOpen: false });
  };

  onMenuOpen = () => {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  };

  handleCountryChange = (selectedOption) => {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
    });
  }

  updatePaymentMethod = async (paymentMethodId) => {
    if (paymentMethodId) {
      const authenticationToken = this.props.authToken;
      const paymentMethodValue = this.state["paymentMethod_" + paymentMethodId];
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("supplier/payment_method/update", {
          paymentMethodValue: paymentMethodValue,
          paymentMethodId: paymentMethodId,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  updateWireTransferSubcribeToken = async () => {
    const { bankName } = this.state;
    if (bankName) {
      const authenticationToken = this.props.authToken;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("supplier/wire_transfer/update", {
          wireTransferDetails: bankName,
        });
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(
        messageConstants.ERROR_MSG_REQUIRED_MESSAGE_FOR_WIRE_TRANSFER
      );
    }
  };

  render() {
    let {
      toTpUri,
      twoFAStatus,
      twoFAFormattedKey,
      twoFAcode,
      fullName,
      email,
      phoneNumber,
      paymentMethods,
      bankName,
      companyName,
      countryName
    } = this.state;
    let qrCodeImg = "";
    let paymentMethodsHtml = "";
    let twofaButtonClassName = "btn-primary";
    let baseThis = this;
    if (!twoFAStatus && toTpUri !== "") {
      qrCodeImg =
        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=" +
        toTpUri;
    }
    let twofaLabel = "Disabled";
    let twofaClassName = "badge-danger";
    let buttonLabel = "Enable 2FA";
    let buttonType = "enable";
    if (twoFAStatus === true) {
      twofaLabel = "Enabled";
      twofaClassName = "badge-success";
      buttonLabel = "Disable 2FA";
      buttonType = "disable";
      twofaButtonClassName = "btn-danger";
    }

    if (!_.isUndefined(paymentMethods) && !_.isEmpty(paymentMethods)) {
      paymentMethodsHtml = _.map(paymentMethods, function (paymentMethod) {
        const preValue = baseThis.state["paymentMethod_" + paymentMethod._id]
          ? baseThis.state["paymentMethod_" + paymentMethod._id]
          : "";

        return (
          <div
            className="col-lg-6 col-md-12 col-sm-12 file-upload-border"
            key={Math.random()}
          >
            <div className=" mt-3 row flex-nowrap">
              <div className=" col-xs-2 col-sm-2 col-md-2  col-xl-1 col-xxl-1  ">
                <span style={{ marginLeft: "-5px" }}>
                  {paymentMethod.title}:
                </span>
              </div>
              <div className="col-xs-7 col-sm-8 col-md-7   col-xl-9  col-xxl-9">
                <input
                  className="update-payment-method-input average-listing-input dark-blue-font-color"
                  type="text"
                  name={`paymentMethod_${paymentMethod._id}`}
                  onChange={(e) => baseThis.onchange(e)}
                  value={preValue ? preValue : ""}
                />
              </div>
              <div className="col-xs-3 col-sm-2 col-md-3 col-xl-2 col-xxl-2">
                <button
                  className="pull-right account-button"
                  onClick={() =>
                    baseThis.updatePaymentMethod(paymentMethod._id)
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="my-account-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-6 my-account-details">
                <div className="dark-blue-theme-color my-account-header-block card-inner-padding h-100 myaccount-card">
                  <div>
                    <h5>
                      <span>Account Information</span>
                    </h5>
                    <div className="mt-1 text-default text-default-color">
                      Full Name : <span className="pl-0">{fullName}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Email : <span className="pl-0">{email}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Contact : <span className="pl-0">{phoneNumber}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Company Name : <span className="pl-0">{companyName}</span>
                    </div>
                    <div className="mt-2 text-default text-default-color">
                      Country : <span className="pl-0">{countryName}</span>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn account-button btn-large"
                      onClick={() => this.openEditProfileModal()}
                      style={{ padding: "8px 12px" }}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-6 my-account-details mgtp-md-4 mgtp-sm-4">
                <div className="text-default-color card-inner-padding dark-blue-theme-color my-account-header-block h-100">
                  <h5>
                    <span className="text-default-color">Change Password</span>
                  </h5>
                  <div className="mt-3">
                    <ul className="text-default">
                      <li>
                        Pick a strong password. Use a combination of numbers,
                        letters & special characters.
                      </li>
                      <li className="mt-1">
                        It should be different from other passwords you use
                        elsewhere on the internet. Change your password
                        regularly.
                      </li>
                    </ul>
                  </div>

                  <div className="mt-3 text-left">
                    <button
                      className="btn account-button btn-large"
                      onClick={() => this.openChangePasswordModal()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="text-default-color card-inner-padding dark-blue-theme-color my-account-header-block two-factor-block">
                  <h5>
                    <span className="text-default-color">
                      2 Factor Authentication
                    </span>
                    <span className="pull-right">
                      <span style={{ fontSize: "14px" }}>Current Status</span>{" "}
                      <label className={"badge " + twofaClassName}>
                        {twofaLabel}
                      </label>
                    </span>
                  </h5>

                  <div className="mt-3 text-default-color two-factor-heading-text two-factor-heading-header">
                    Two-factor authentication is a method for protection your
                    web account. When it is activated you need to enter not only
                    your password, but also a special code.
                  </div>

                  <div className="row mt-3 flex-sm-nowrap justify-content-center">
                    {qrCodeImg !== "" && (
                      <div className="ml-2 ">
                        <img alt="" src={qrCodeImg} height="170" />
                      </div>
                    )}

                    <div className="two-factor-desc mt-3">
                      {!twoFAStatus && (
                        <div>
                          <div className="text-default text-default-color">
                            Open up your 2FA mobile app and scan the following
                            QR barcode. If your 2FA mobile app does not support
                            QR barcodes, enter in the following number:
                          </div>
                          <h5 className="mt-4 text-center text-large">
                            {twoFAFormattedKey}
                          </h5>
                        </div>
                      )}

                      <div className="mt-4 text-center">
                        <label className="text-default text-default-color">
                          2FA Code
                        </label>{" "}
                        <input
                          className="form-control two-fa-code-input"
                          type="text"
                          name="twoFAcode"
                          id="twoFAcode"
                          placeholder="Enter 2FA code"
                          onChange={this.onchange}
                          value={twoFAcode}
                        />
                        <button
                          className={
                            "btn twofa-submit-button pl-3  " +
                            twofaButtonClassName
                          }
                          onClick={() =>
                            this.twoFaAuthenticationChange(buttonType)
                          }
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="text-default-color card-inner-padding dark-blue-theme-color listing-files-container files-container">
                  <h5 className="text-default-color">Payment Methods</h5>
                  <div className="row">{paymentMethodsHtml}</div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="text-default-color card-inner-padding dark-blue-theme-color listing-files-container files-container">
                  <h5 className="text-default-color">
                    Wire transfer for subscribe token
                  </h5>
                  <div className="bank_container">
                    <div className="row">
                      <div className="mr-3 col-12">
                        <div>
                          Please follow the below pattern
                          <span className="required-sign"> *</span>
                        </div>
                        <textarea
                          name="bankName"
                          onChange={this.onchange}
                          value={bankName}
                          placeholder={
                            "Bank Name : name, Bank Account Number : No, ..."
                          }
                          className="form-control ml-1 dark-blue-font-color mb-2 mt-2"
                          rows="5"
                          id="comment"
                        ></textarea>
                      </div>
                      <div className="ml-3 text-center">
                        <button
                          className="btn account-button my-account-button update-btn"
                          onClick={() => this.updateWireTransferSubcribeToken()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ChangePasswordModal
              {...this.props}
              {...this.state}
              onInputValueChange={this.onchange}
              isFormValid={this.isFormValid}
              displayValidationErrors={this.displayValidationErrors}
              onCloseModal={this.onChangePasswordCloseModal}
              updatePassword={this.updatePassword}
            />
            <EditProfileModal
              optionRef={this.optionRef}
              {...this.props}
              {...this.state}
              onCloseModal={this.onEditProfileCloseModal}
              onInputValueChange={this.onchange}
              updateProfile={this.updateProfile}
              onNumberChange={this.onNumberChange}
              handleCountryChange={this.handleCountryChange}
              onMenuOpen={this.onMenuOpen}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default MyAccount;
