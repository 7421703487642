import React, { Component } from "react";
import supplierLogoImg from "../../assets/img/supplier-w.png";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import _ from "lodash";
import axios from "axios";
import { API_ROOT } from "../../services/api-config";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import tokenIcon from "../../assets/img/token.svg?v1";

class CustomHeader extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
    };
  }

  componentDidMount() {
    this.setCryptoUnitPrice();
  }

  async setCryptoUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  render() {
    let { authUserInfo, isKycPage } = this.props;
    let { ethUnitUsdPrice, btcUnitUsdPrice } = this.state;
    let userDisplayName =
      !_.isUndefined(authUserInfo) &&
      authUserInfo !== null &&
      authUserInfo !== ""
        ? authUserInfo.userDisplayName
        : "";
    return (
      <div>
        <div className="top-bar custom-top-bar padding-10">
          <div>
            <Link to="/">
              <img
                className="customLogo"
                src={supplierLogoImg}
                height={70}
                alt="supplier"
              />
            </Link>
          </div>

          {!isKycPage && (
            <span className="text-white text-center mr-4">
              ETH/USD : ${niceNumberDecimalDisplay(ethUnitUsdPrice)} | BTC/USD :
              ${niceNumberDecimalDisplay(btcUnitUsdPrice)}{" "}
            </span>
          )}

          {!isKycPage && (
            <div
              className={`top-bar color-scheme-transparent admin-top-bar 
            ${!isKycPage ? "token-picker-widget" : ""}`}
            >
              {
                <div className="fancy-selector-w">
                  <div className="fancy-selector-current">
                    <div className="fs-img">
                      <img
                        className="color-white svg-white"
                        src={tokenIcon}
                        alt="token"
                      />
                    </div>
                    <div className="fs-main-info">
                      <div className="fs-name">
                        <span>Digital Energy Currency</span>
                        <strong>(DEC)</strong>
                      </div>
                      <div className="fs-sub">
                        <span>Balance:</span>
                        <strong>0 DEC | $0</strong>
                      </div>
                    </div>
                  </div>
                  <div className="fancy-selector-options">
                    {/* {listedTokensHtml} */}
                  </div>
                </div>
              }
            </div>
          )}

          {/* <div className={`top-menu-controls ${!isKycPage ? "mb-40" : ""}`}>
            {isKycPage && isKycPage === true && (
              <div className="custom-username">Welcome, {userDisplayName}</div>
            )}
            {isKycPage && isKycPage === true && (
              <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
                <Link to="/logout">
                  <i
                    className="os-icon os-icon-log-out menu-vertical-align-set"
                    style={{ color: "white" }}
                  ></i>
                </Link>
              </div>
            )}
          </div> */}

          {isKycPage && (
            <div className={`top-menu-controls ${!isKycPage ? "mb-40" : ""}`}>
              {isKycPage && isKycPage === true && (
                <div className="custom-username">
                  Welcome, {userDisplayName}
                </div>
              )}

              {isKycPage && isKycPage === true && (
                <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-left">
                  <i className="os-icon os-icon-user-male-circle menu-vertical-align-set"></i>
                  <div className="os-dropdown">
                    <ul>
                      <li>
                        <Link to="/my_account">
                          <i className="os-icon os-icon-ui-49"></i>
                          <span>Profile Settings</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/logout">
                          <i className="fa fa-power-off"></i>
                          <span>Logout</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CustomHeader;
