import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { fetchTokenDetails } from "../../utils/Util";
import SubscriptionAgreement from "./SubscriptionAgreement";

let fileRef = {};
let fileIconRef = {};
let listingDocuments = [];
class SiteSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      agreementButtonLoading: false,
      geminiApiKey: "",
      geminiSecretKey: "",
      isFileUploaded: [],
      listingDocumentType: "",
      tokenDetailsFetch: false,
      tokenDetailsButtonLoading: false,
    };
  }

  componentDidMount() {
    document.title =
      messageConstants.SITE_SETTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getSettingDetails();
    this.getDocumentListing();
    this.getTokenDetails();
  }

  getSettingDetails = async () => {
    try {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/dealer/site/settings");
      if (response.code === 200) {
        const geminiApiKey = response.data.settings.geminiApiKey;
        const geminiSecretKey = response.data.settings.geminiSecretKey;
        this.setState(
          {
            geminiApiKey,
            geminiSecretKey,
          },
          async () => {
            if (typeof this.props.pageProgress === "function") {
              this.props.pageProgress("remove");
            }
          }
        );
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (err) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  getDocumentListing = () => {
    const data = [
      {
        id: 1,
        label: "Agreement",
        name: "agreement",
        required: true,
      },
    ];
    this.setState({
      listingDocumentType: data,
    });
  }

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  saveSiteSettings = async (event) => {
    event.preventDefault();
    const authenticationToken = this.props.authToken;
    const { geminiApiKey, geminiSecretKey } = this.state;
    if (!_.isUndefined(geminiApiKey) && !_.isUndefined(geminiSecretKey)) {
      this.setState({ buttonLoading: true });
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/dealer/site/settings", {
          geminiApiKey,
          geminiSecretKey,
        });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
    }
  }

  handleUploadFile = (event, refName, documentTypeId) => {
    if (refName) {
      let { isFileUploaded } = this.state;
      isFileUploaded[documentTypeId] = true;
      this.setState(isFileUploaded);
    }
    if (event.target.files[0]) {
      let temp = {
        key: refName,
        documentTypeId: documentTypeId,
        file: event.target.files[0],
      };
      listingDocuments.push(temp);
    }
    console.log(JSON.stringify(listingDocuments));
  }

  saveSubscriptionAgreement = async () => {
    let _this = this;
    const data = new FormData();
    _this.setState({
      agreementButtonLoading: true,
    });

    if (!_.isEmpty(listingDocuments)) {
      _.forEach(listingDocuments, function (value, key) {
        data.append(value.key, value.file);
        data.append(value.key + "_listingDocumentId", value.documentTypeId);
      });
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: this.props.authToken,
      },
    };
    axios
      .post(API_ROOT + "/supplier/subscription/agreement", data, config)
      .then(function (response) {
        if (!_.isUndefined(response)) {
          _this.setState({
            agreementButtonLoading: false,
          });
          if (response.data.code === 200) {
            toast.success(response.data.message);
            listingDocuments = [];
          } else {
            toast.error(response.data.message);
          }
        }
      })
      .catch(function (error) {
        _this.setState({
          buttonLoading: false,
        });
        toast.error(
          "Subscription agreement not saved successfully. Please try again later."
        );
      });
  }

  getTokenDetails = async () => {
    try {
      const api = new Api();
      const authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/issuer/token/details");
      if (response.code === 200 && response.data) {
        const listedTokenIssuedFromContract = await fetchTokenDetails(
          response.data.listedTokenContractAddress,
          true
        );

        let stateObj = {
          listedTokenCompanyName: response.data.listedTokenCompanyName,
          listedTokenCompanyEmail: response.data.listedTokenCompanyEmail,
          listedTokenCompanyLicenseId:
            response.data.listedTokenCompanyLicenseId,
          listedTokenCompanySecurityId:
            response.data.listedTokenCompanySecurityId,
          listedTokenCompanyUrl: response.data.listedTokenCompanyUrl,
          listedTokenWhitepaerLink: response.data.listedTokenWhitepaerLink,
          companyOfficialAddress: response.data.companyOfficialAddress,
          listedTokenName: response.data.listedTokenName,
          listedTokenSymbol: response.data.listedTokenSymbol,
          listedTokenDecimalPlace: response.data.listedTokenDecimalPlace,
          listedTokenInitialSupply: response.data.listedTokenInitialSupply,
          listedTokenMaxOwnership: response.data.listedTokenMaxOwnership,
          listedTokenOfficialAddress: response.data.listedTokenOfficialAddress,
          listedTokenOutstanding: response.data.listedTokenOutstanding,
          listedTokenOwnersERC20Address:
            response.data.listedTokenOwnersERC20Address,
          listedTokenTotalUnlocked: response.data.listedTokenTotalUnlocked,
          listedTokenUnIssued: response.data.listedTokenUnIssued,
          listedTokenAuthorized: response.data.listedTokenAuthorized,
          listedUnissuedToken: response.data.listedUnissuedToken,
          listedFloatingToken: response.data.listedFloatingToken,
          outstandingToken: response.data.outstandingToken,
          listedTokenRestricted:
            response.data.listedTokenRestricted > 0
              ? response.data.listedTokenRestricted
              : 0,
          listedTokenTreasury:
            response.data.listedTokenTreasury > 0
              ? response.data.listedTokenTreasury
              : 0,
          listedTokenIssued: listedTokenIssuedFromContract
            ? listedTokenIssuedFromContract
            : response.data.listedTokenIssued,
          listedTokenPrice: response.data.listedTokenPrice,
          subscriptionAgreementData: response.data.subscriptionAgreementData,
          listedTokenNetPresentValue: response.data.listedTokenNetPresentValue,
          listedTokenProvince: response.data.listedTokenProvince,
          listedTokenCountry: response.data.listedTokenCountry,
          listedTokenTotalOfferings: response.data.listedTokenTotalOfferings,
          listedTokenContractAddress: response.data.listedTokenContractAddress,
          listedTokenOutstandingTokens:
            response.data.listedTokenOutstandingTokens,
          listedTokenTotalAmountRaised:
            response.data.listedTokenTotalAmountRaised,
          filePath: response.data.agreementFile
            ? response.data.agreementFile
            : "",
        };

        this.setState(stateObj, async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (err) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  saveTokenSettings = async (event) => {
    event.preventDefault();
    const authenticationToken = this.props.authToken;
    const issuerId = this.props.match.params.id;
    try {
      const {
        listedTokenCompanyName,
        listedTokenCompanyLicenseId,
        listedTokenCompanySecurityId,
        listedTokenCompanyUrl,
        listedTokenWhitepaerLink,
        companyOfficialAddress,
        listedTokenName,
        listedTokenSymbol,
        listedTokenDecimalPlace,
        listedTokenInitialSupply,
        listedTokenMaxOwnership,
        listedTokenOwnersERC20Address,
        listedTokenIssued,
        listedTokenRestricted,
        listedTokenTreasury,
        listedTokenTotalUnlocked,
        listedTokenAuthorized,
        listedTokenOfficialAddress,
        listedTokenPrice,
        listedTokenCompanyEmail,
        listedTokenNetPresentValue,
        listedTokenProvince,
        listedTokenCountry,
        listedTokenOutstandingTokens,
        listedTokenTotalOfferings,
        listedTokenContractAddress,
        listedTokenTotalAmountRaised,
      } = this.state;
      if (
        !_.isUndefined(listedTokenCompanyName) &&
        !_.isUndefined(listedTokenName) &&
        !_.isUndefined(listedTokenPrice) &&
        !_.isUndefined(listedTokenSymbol) &&
        !_.isUndefined(listedTokenDecimalPlace)
      ) {
        this.setState({
          tokenDetailsButtonLoading: true,
        });
        const api = new Api();
        const response = await api
          .setToken(authenticationToken)
          .create("user/issuer/token/details", {
            listedTokenCompanyName,
            listedTokenCompanyLicenseId,
            listedTokenCompanySecurityId,
            listedTokenCompanyUrl,
            listedTokenWhitepaerLink,
            companyOfficialAddress,
            listedTokenName,
            listedTokenSymbol,
            listedTokenDecimalPlace,
            listedTokenInitialSupply,
            listedTokenMaxOwnership,
            listedTokenOwnersERC20Address,
            listedTokenIssued,
            listedTokenRestricted,
            listedTokenTreasury,
            listedTokenTotalUnlocked,
            listedTokenOfficialAddress,
            listedTokenAuthorized,
            listedTokenPrice,
            issuerId,
            listedTokenCompanyEmail,
            listedTokenNetPresentValue,
            listedTokenProvince,
            listedTokenCountry,
            listedTokenOutstandingTokens,
            listedTokenTotalOfferings,
            listedTokenContractAddress,
            listedTokenTotalAmountRaised,
          });
        if (response) {
          this.setState({ tokenDetailsButtonLoading: false });
        }
        if (response.code === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  fetchSmartContractDetails = async () => {
    const { listedTokenContractAddress } = this.state;
    toast.success("We are fetching details from smart contract. Please wait!!");
    this.setState({
      tokenDetailsFetch: true,
    });
    const responseAr = await fetchTokenDetails(listedTokenContractAddress);
    if (responseAr) {
      this.setState({
        listedTokenName: responseAr.name,
        listedTokenCompanyName: responseAr.issuerName,
        listedTokenCompanyLicenseId: responseAr.businessNumber,
        listedTokenSymbol: responseAr.symbol,
        listedTokenDecimalPlace: responseAr.decimals,
        listedTokenOwnersERC20Address: responseAr.owner,
        listedTokenCompanyUrl: responseAr.website,
        listedTokenProvince: responseAr.province,
        listedTokenCountry: responseAr.country,
        listedTokenIssued: responseAr.totalSupply,
        tokenDetailsFetch: false,
      });
    }
  }

  render() {
    const {
      geminiApiKey,
      geminiSecretKey,
      buttonLoading,
      isFileUploaded,
      listingDocumentType,
      agreementButtonLoading,
      listedTokenName,
      listedTokenCompanyName,
      listedTokenCompanyLicenseId,
      listedTokenCompanyUrl,
      listedTokenSymbol,
      listedTokenDecimalPlace,
      listedTokenMaxOwnership,
      listedTokenOwnersERC20Address,
      listedTokenAuthorized,
      listedTokenTreasury,
      listedTokenIssued,
      listedTokenPrice,
      tokenDetailsFetch,
      listedTokenProvince,
      listedTokenCountry,
      listedTokenOutstandingTokens,
      listedTokenTotalOfferings,
      listedTokenTotalAmountRaised,
      listedTokenContractAddress,
      tokenDetailsButtonLoading,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let tokenButtonDisabled = tokenDetailsFetch === true ? true : false;
    let tokenSettingsButtonDisabled =
      tokenDetailsButtonLoading === true ? true : false;

    let listingDocumentsHtml = "";
    let _this = this;
    if (
      !_.isUndefined(listingDocumentType) &&
      !_.isEmpty(listingDocumentType)
    ) {
      listingDocumentsHtml = _.map(listingDocumentType, (documentType) => {
        return (
          <div className="col-lg-9 col-md-12 col-sm-12" key={Math.random()}>
            <div className="mt-2">
              <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6 fix-width">
                  {isFileUploaded && isFileUploaded[documentType.id] && (
                    <i
                      className="fa fa-check filesuccess"
                      ref={(ref) => (fileIconRef[documentType.name] = ref)}
                    ></i>
                  )}
                  {(!isFileUploaded ||
                    (isFileUploaded && !isFileUploaded[documentType.id])) && (
                      <i
                        className="fa fa-times fileerror"
                        ref={(ref) => (fileIconRef[documentType.name] = ref)}
                      ></i>
                    )}
                  <label className="pl-1 font-default font-small">
                    {documentType.label}{" "}
                    {documentType.required && documentType.required === true ? (
                      <span className="required-sign">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6 d-flex">
                  <button
                    className="upload-button cusor-pointer font-small"
                    onClick={(e) => fileRef[documentType.name].click()}
                  >
                    <span className="button-container" htmlFor="oil_gas_report">
                      Upload <i className="fa fa-paperclip ml-1 mt-1"></i>
                    </span>
                  </button>
                  <br />
                  <input
                    key={documentType.name}
                    className="listing_file"
                    type="file"
                    ref={(ref) => (fileRef[documentType.name] = ref)}
                    name={documentType.name}
                    onChange={(event) =>
                      _this.handleUploadFile(
                        event,
                        documentType.name,
                        documentType.id
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="dealer-site-settings-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header text-xl">Gemini Settings</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Gemini API Key
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Gemini API Key`}
                            type="text"
                            name="geminiApiKey"
                            onChange={this.onchange}
                            value={geminiApiKey}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Gemini Secret Key
                            <span className="required-sign">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Gemini's Secret Key`}
                            type="text"
                            name="geminiSecretKey"
                            onChange={this.onchange}
                            value={geminiSecretKey}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="custom-submit-button2 button-center">
                      <button
                        className="btn default-btn"
                        type="submit"
                        onClick={this.saveSiteSettings}
                        disabled={buttonDisabled}
                      >
                        Submit
                        {buttonLoading && (
                          <i className="fa-spin fa fa-spinner text-white m-1" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="mt-4"></div>

                  <div className="element-box">
                    <h6 className="element-header">
                      <span className="text-xl">Token Settings</span>
                    </h6>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Smart Contract Address
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please enter smart contract address`}
                            type="text"
                            name="listedTokenContractAddress"
                            onChange={this.onchange}
                            value={listedTokenContractAddress}
                          />
                        </div>
                      </div>
                      <div className="col-md-2" style={{ marginTop: "2.3rem" }}>
                        <button
                          className="btn default-btn"
                          onClick={() => this.fetchSmartContractDetails()}
                          disabled={tokenButtonDisabled}
                        >
                          Fetch
                          {tokenDetailsFetch && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Company Name*
                          </label>
                          <input
                            className="form-control"
                            readonly=""
                            placeholder={`Please Enter Company Name`}
                            type="text"
                            name="listedTokenCompanyName"
                            onChange={this.onchange}
                            value={listedTokenCompanyName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Business Number
                            <i
                              className="fa fa-info-circle ml-1"
                              id="totalAsicsTooltip"
                              title="Total ASICs"
                            ></i>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="bottom"
                              target="totalAsicsTooltip"
                            >
                              <PopoverBody>
                                Insert the Issuer's Business Number (BN) as
                                provided by the Canada Revenue Agency (CRA).
                              </PopoverBody>
                            </UncontrolledPopover>
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company License Id`}
                            type="text"
                            readonly=""
                            name="listedTokenCompanyLicenseId"
                            onChange={this.onchange}
                            value={listedTokenCompanyLicenseId}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Company URL
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company URL`}
                            type="text"
                            readonly=""
                            name="listedTokenCompanyUrl"
                            onChange={this.onchange}
                            id={listedTokenCompanyUrl}
                            value={listedTokenCompanyUrl}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Country
                          </label>
                          <input
                            className="form-control"
                            readonly=""
                            placeholder={`Please Enter Country`}
                            type="text"
                            name="listedTokenCountry"
                            onChange={this.onchange}
                            value={listedTokenCountry}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Province
                          </label>
                          <input
                            className="form-control"
                            readonly=""
                            placeholder={`Please Enter Province`}
                            type="text"
                            name="listedTokenProvince"
                            onChange={this.onchange}
                            value={listedTokenProvince}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label class="text-default text-default-color">
                            Token Name*
                          </label>
                          <input
                            className="form-control"
                            readonly=""
                            placeholder={`Please Enter Token Name`}
                            type="text"
                            name="listedTokenName"
                            onChange={this.onchange}
                            value={listedTokenName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Ticker*
                          </label>
                          <input
                            className="form-control"
                            readonly=""
                            placeholder={`Please Enter Token Ticker`}
                            type="text"
                            name="listedTokenSymbol"
                            onChange={this.onchange}
                            value={listedTokenSymbol}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Decimal Place*
                          </label>
                          <input
                            readonly=""
                            className="form-control"
                            placeholder={`Please Enter Decimal Place`}
                            type="text"
                            name="listedTokenDecimalPlace"
                            onChange={this.onchange}
                            value={listedTokenDecimalPlace}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Issued Tokens
                          </label>
                          <input
                            readonly=""
                            className="form-control"
                            placeholder={`Enter Issued Tokens`}
                            type="text"
                            name="listedTokenIssued"
                            onChange={this.onchange}
                            value={listedTokenIssued}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Owner ERC20 Address*
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Owner's ERC20 Address`}
                            type="text"
                            readonly=""
                            name="listedTokenOwnersERC20Address"
                            onChange={this.onchange}
                            value={listedTokenOwnersERC20Address}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Offering Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Total Offering Tokens`}
                            type="text"
                            name="listedTokenTotalOfferings"
                            onChange={this.onchange}
                            value={listedTokenTotalOfferings}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Outstanding Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Initial Supply`}
                            type="text"
                            name="listedTokenOutstandingTokens"
                            onChange={this.onchange}
                            value={listedTokenOutstandingTokens}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Treasury Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Token In Treasury`}
                            type="text"
                            name="listedTokenTreasury"
                            onChange={this.onchange}
                            value={listedTokenTreasury}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Authorized Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Authorized Tokens`}
                            type="text"
                            name="listedTokenAuthorized"
                            onChange={this.onchange}
                            value={listedTokenAuthorized}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Price*
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Token Price`}
                            type="text"
                            name="listedTokenPrice"
                            onChange={this.onchange}
                            value={listedTokenPrice}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Total Amount Raised
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Total Amount Raised`}
                            type="text"
                            name="listedTokenTotalAmountRaised"
                            onChange={this.onchange}
                            value={listedTokenTotalAmountRaised}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="form-buttons-w custom-submit-button2 mt-0 border-top-none m-btn-center
                    d-flex justify-content-end"
                    >
                      <button
                        className="btn default-btn"
                        type="submit"
                        onClick={(e) => this.saveTokenSettings(e)}
                        disabled={tokenSettingsButtonDisabled}
                      >
                        Submit
                        {tokenDetailsButtonLoading && (
                          <i className="fa-spin fa fa-spinner text-white ml-1" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="mt-4"></div>
                  <div className="element-box">
                    <h6 className="element-header text-xl">
                      Subscription Agreement
                    </h6>
                    <SubscriptionAgreement authToken={this.props.authToken}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SiteSettings;
