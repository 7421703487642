import React, { Component } from "react";
import {ExportExcel} from '../ExportExcel/ExportExcel'
import Modal from "react-responsive-modal";
class TransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeHorizon: "",
      startDate: "",
      fileName:"Transactions",
      endDate: "",
    };
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.clearTime = this.clearTime.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
  }

  onChangeStartDate(e) {
    this.setState({ startDate: e.target.value });
  }
  onChangeTimeHorizon(value) {
    this.setState({ timeHorizon: value });
  }
  onChangeEndDate(e) {
    this.setState({ endDate: e.target.value });
  }
  clearTime() {
    this.setState({ endDate: "", timeHorizon: "" });
  }
  render() {
    const { modalOpen, onCloseModal,csvData } = this.props;
    const { timeHorizon,fileName } = this.state;

    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container interest-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content ">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    // style={{ minWidth: "550px" }}
                  >
                    <h4 className="onboarding-title">Export Data</h4>
                    <div className="form-group">
                      <label htmlFor="title">
                        Start Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100"
                        id="startDate"
                        type="date"
                        value={this.state.startDate}
                        onChange={this.onChangeStartDate}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="title">
                        End Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="endDate"
                        type="date"
                        value={this.state.endDate}
                        onChange={this.onChangeEndDate}
                      />
                    </div>
                    <div className="text-center" style={{ marginTop: "32px" }}>
                      <hr />
                      <span
                        style={{
                          position: "relative",
                          top: "-26px",
                          fontWeight: "bold",
                          background: "white",
                          padding: "0px 10px",
                        }}
                      >
                        OR
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Export All</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="btn-group mr-1 mb-1"
                          style={{ width: "151px" }}
                        >
                          <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            className="btn default-border-btn dropdown-toggle"
                            data-toggle="dropdown"
                            id="dropdownMenuButton6"
                            type="button"
                          >
                            {timeHorizon || <span>Select All</span>}
                          </button>
                          <div aria-labelledby="" className="dropdown-menu">


                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.onChangeTimeHorizon("All");
                              }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <ExportExcel
                        csvData={csvData}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        timeHorizon={this.state.timeHorizon}
                        onCloseModal={onCloseModal}
                        fileName={fileName}
                        clearTime={this.clearTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default TransactionModal;
