import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import supplierLogoImg from "../../assets/img/supplier-w.png?v1";
import dashboardIcon from "../../assets/img/sidebar-icons/dashboard.svg?v1";
import dealerIcon from "../../assets/img/sidebar-icons/dealer.svg?v1";
import logoutIcon from "../../assets/img/sidebar-icons/logout.svg?v1"
import resourcesIcon from "../../assets/img/sidebar-icons/resources.svg?v1"
import campaignsIcon from "../../assets/img/sidebar-icons/campaigns.svg?v1"
import forecastIcon from "../../assets/img/sidebar-icons/forecast.svg?v1"
import shareholdersIcon from "../../assets/img/sidebar-icons/shareholders.svg?v1"
import transactionsIcon from "../../assets/img/sidebar-icons/transactions.svg?v1"
import myAccountsIcon from "../../assets/img/sidebar-icons/user.svg?v1";
import dueDeligenceLightIcon from "../../assets/img/sidebar-icons/due_deligence_light.svg?v1";
import settingsIcon from "../../assets/img/sidebar-icons/settings.svg?v1";
import dueDeligenceIcon from "../../assets/img/sidebar-icons/due_deligence.svg?v1";
class UserDashboardMenu extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {};
    this.menuMenu = React.createRef();
  }

  activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1 ? "menu_active" : "cursor-pointer";
  }

  closeMenu = () => {
    this.menuMenu.current.style.display = 'none';
  }

  render() {
    let { authUserInfo } = this.props;
    let userDisplayName =
      authUserInfo &&
        authUserInfo.userDisplayName
        ? authUserInfo.userDisplayName
        : "";
    return (
      <div className="menu-container">
        <div className="menu-mobile menu-activated-on-click color-scheme-dark">
          <div className="mm-logo-buttons-w">
            <Link to="/my_account">
              <div className="mm-logo">
                <img
                  className="customLogo"
                  src={supplierLogoImg}
                  height={70}
                  alt="Supplier"
                />
              </div>
            </Link>

            <div className="mm-buttons">
              <div className="mobile-menu-trigger">
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <div className="menu-and-user" ref={this.menuMenu}>
            <div className="logged-user-w">
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  Welcome {userDisplayName}
                </div>
              </div>
            </div>

            <ul className="main-menu">
              <li className={this.activeRoute('dashboard')} onClick={this.closeMenu}>
                <Link to="/dashboard">
                  <div className="icon-w">
                    <img src={dashboardIcon} alt="dashboard" height="20" width="20" />
                  </div>
                  <span>Token Dashboard</span>
                </Link>
              </li>

              <li className={this.activeRoute('campaigns')} onClick={this.closeMenu}>
                <Link to="/campaigns">
                  <div className="icon-w">
                    <img src={campaignsIcon} alt="campaigns" height="20" width="20" />
                  </div>
                  <span>Manage Campaigns</span>
                </Link>
              </li>

              <li className={this.activeRoute('forecast')} onClick={this.closeMenu}>
                <Link to="/forecast">
                  <div className="icon-w">
                    <img src={forecastIcon} alt="forecast" height="20" width="20" />
                  </div>
                  <span>Forecast Details</span>
                </Link>
              </li>

              <li className={this.activeRoute('transactions')} onClick={this.closeMenu}>
                <Link to="/transactions">
                  <div className="icon-w">
                    <img src={transactionsIcon} alt="transaction" height="20" width="20" />
                  </div>
                  <span>Transactions</span>
                </Link>
              </li>

              <li className={this.activeRoute('shareholders')} onClick={this.closeMenu}>
                <Link to="/shareholders">
                  <div className="icon-w">
                    <img src={shareholdersIcon} alt="shareholders" height="20" width="20" />
                  </div>
                  <span>Shareholders</span>
                </Link>
              </li>

              <li className={this.activeRoute('resources')} onClick={this.closeMenu}>
                <Link to="/resources">
                  <div className="icon-w">
                    <img src={resourcesIcon} alt="resources" height="20" width="20" />
                  </div>
                  <span>Resources</span>
                </Link>
              </li>

              <li className={this.activeRoute('my_account')} onClick={this.closeMenu}>
                <Link to="/my_account">
                  <div className="icon-w">
                    <img src={myAccountsIcon} alt="myaccount" height="20" width="20" />
                  </div>
                  <span>My Account</span>
                </Link>
              </li>

              <li className={this.activeRoute('due_diligence')} onClick={this.closeMenu}>
                <Link to="/due_diligence">
                  <div className="icon-w">
                    <img src={dueDeligenceIcon} alt="due_diligence" height="20" width="20" />
                  </div>
                  <span>Due Diligence</span>
                </Link>
              </li>

              <li className={this.activeRoute('settings')} onClick={this.closeMenu}>
                <Link to="/settings">
                  <div className="icon-w">
                    <img src={settingsIcon} alt="settings" height="20" width="20" />
                  </div>
                  <span>Settings</span>
                </Link>
              </li>

              <li className={this.activeRoute('logout')}>
                <Link to="/logout">
                  <div className="icon-w">
                    <img src={logoutIcon} alt="logout" height="20" width="20" />
                  </div>
                  <span>Logout</span>
                </Link>
              </li>

            </ul>
          </div>
        </div>

        <div className="menu-w color-scheme-light color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link user-dashboard-menu">
          <div className="logged-user-w avatar-inline">
            <div className="logged-user-i">
              <div className="logged-user-info-w emdcontainer">
                <div className="logged-user-name font-size-12 color-black">
                  Welcome {userDisplayName}
                </div>
              </div>
              <div className="logged-user-toggler-arrow">
                <div className="os-icon os-icon-chevron-down"></div>
              </div>
              <div className="logged-user-menu color-style-bright">
                <div className="logged-user-avatar-info display-block">
                  <div className="logged-user-info-w menu-padding-set">
                    <div className="logged-user-name">{userDisplayName}</div>
                    <div className="logged-user-role">Supplier</div>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="os-icon os-icon-wallet-loaded"></i>
                </div>
                <ul>
                  <li className={this.activeRoute('my_account')}>
                    <Link to="/my_account">
                      <i className="os-icon os-icon-user-male-circle2"></i>
                      <label className="color-white supplier-size">My Account</label>
                    </Link>
                  </li>
                  <li className={this.activeRoute('due_diligence')}>
                    <Link to="/due_diligence">
                      <img src={dueDeligenceLightIcon} alt="due_diligence" height="20" width="20" />
                      <label className="color-white supplier-size" style={{ marginLeft: '12px' }}>Due Diligence</label>
                    </Link>
                  </li>
                  <li className={this.activeRoute('settings')}>
                    <Link to="/settings">
                      <i className="os-icon os-icon-settings"></i>
                      <label className="color-white supplier-size">Settings</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout">
                      <i className="os-icon os-icon-signs-11"></i>
                      <label className="color-white supplier-size">Logout</label>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul className="main-menu">
            <li className={this.activeRoute('dashboard')}>
              <Link to="/dashboard">
                <div className="icon-w">
                  <img src={dashboardIcon} alt="dashboard" height="20" width="20" />
                </div>
                <span>Token Dashboard</span>
              </Link>
            </li>

            <li className={this.activeRoute('campaigns')}>
              <Link to="/campaigns">
                <div className="icon-w">
                  <img src={campaignsIcon} alt="campaigns" height="20" width="20" />
                </div>
                <span>Campaigns</span>
              </Link>
            </li>

            <li className={this.activeRoute('forecast')}>
              <Link to="/forecast">
                <div className="icon-w">
                  <img src={forecastIcon} alt="forecast" height="20" width="20" />
                </div>
                <span>Forecast Details</span>
              </Link>
            </li>

            <li className={this.activeRoute('transactions')}>
              <Link to="/transactions">
                <div className="icon-w">
                  <img src={transactionsIcon} alt="transaction" height="20" width="20" />
                </div>
                <span>Transactions</span>
              </Link>
            </li>

            <li className={this.activeRoute('shareholders')}>
              <Link to="/shareholders">
                <div className="icon-w">
                  <img src={shareholdersIcon} alt="shareholders" height="20" width="20" />
                </div>
                <span>Shareholders</span>
              </Link>
            </li>
            <li className={this.activeRoute('resources')}>
              <Link to="/resources">
                <div className="icon-w">
                  <img src={resourcesIcon} alt="resources" height="20" width="20" />
                </div>
                <span>Resources</span>
              </Link>
            </li>
            <li className={this.activeRoute('emd')}>
              <Link to="/emd">
                <div className="icon-w">
                  <img src={dealerIcon} alt="Manage Dealer" height="20" width="20" />
                </div>
                <span>Manage Dealer</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default UserDashboardMenu;