import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import * as messageConstants from "../../utils/Messages";

class TokenSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      subscriptionLoading: false,
      subscriptionAgreementData: "",
      xAxisTimeUnit: "Year",
      xAxisTitle: "",
      subscriptionRejected: false,
      subscriptionRejectedReason: "",
      filePath: "",
    };
    this.onchange = this.onchange.bind(this);
    this.saveTokenSettings = this.saveTokenSettings.bind(this);
    this.saveSubscriptionAgreement = this.saveSubscriptionAgreement.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.TOKEN_SETTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
    this.getTokenDetails();
  }

  onEditorChange(evt) {
    this.setState({
      subscriptionAgreementData: evt.editor.getData(),
    });
  }

  async getTokenDetails() {
    try {
      const api = new Api();
      let issuerId = this.props.match.params.id;
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/details", { issuerId: issuerId });
      if (response.code === 200) {
        /* const listedUnissuedToken =
          parseFloat(response.data.listedTokenInitialSupply) -
          parseFloat(response.data.listedTokenIssued);
        const listedFloatingToken =
          parseFloat(response.data.listedTokenIssued) -
          parseFloat(response.data.listedTokenRestricted);
        const outstandingToken =
          parseFloat(response.data.listedTokenIssued) -
          parseFloat(response.data.listedTokenTreasury); */

        let stateObj = {
          listedTokenCompanyName: response.data.listedTokenCompanyName,
          listedTokenCompanyEmail: response.data.listedTokenCompanyEmail,
          listedTokenCompanyLicenseId:
            response.data.listedTokenCompanyLicenseId,
          listedTokenCompanySecurityId:
            response.data.listedTokenCompanySecurityId,
          listedTokenCompanyUrl: response.data.listedTokenCompanyUrl,
          listedTokenWhitepaerLink: response.data.listedTokenWhitepaerLink,
          companyOfficialAddress: response.data.companyOfficialAddress,
          listedTokenName: response.data.listedTokenName,
          listedTokenSymbol: response.data.listedTokenSymbol,
          listedTokenDecimalPlace: response.data.listedTokenDecimalPlace,
          listedTokenInitialSupply: response.data.listedTokenInitialSupply,
          listedTokenMaxOwnership: response.data.listedTokenMaxOwnership,
          listedTokenOfficialAddress: response.data.listedTokenOfficialAddress,
          listedTokenOutstanding: response.data.listedTokenOutstanding,
          listedTokenOwnersERC20Address:
            response.data.listedTokenOwnersERC20Address,
          listedTokenTotalUnlocked: response.data.listedTokenTotalUnlocked,
          listedTokenUnIssued: response.data.listedTokenUnIssued,
          listedTokenAuthorized: response.data.listedTokenAuthorized,
          listedUnissuedToken: response.data.listedUnissuedToken,
          listedFloatingToken: response.data.listedFloatingToken,
          outstandingToken: response.data.outstandingToken,
          listedTokenRestricted:
            response.data.listedTokenRestricted > 0
              ? response.data.listedTokenRestricted
              : 0,
          listedTokenTreasury:
            response.data.listedTokenTreasury > 0
              ? response.data.listedTokenTreasury
              : 0,
          listedTokenIssued: response.data.listedTokenIssued,
          listedTokenPrice: response.data.listedTokenPrice,
          subscriptionAgreementData: response.data.subscriptionAgreementData,
          listedTokenNetPresentValue: response.data.listedTokenNetPresentValue,
          filePath: response.data.agreementFile
            ? response.data.agreementFile
            : "",
        };

        this.setState(stateObj, async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (err) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onRejectAgreement() {
    this.setState({ subscriptionRejected: true });
  }

  async saveTokenSettings(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      const {
        listedTokenCompanyName,
        listedTokenCompanyLicenseId,
        listedTokenCompanySecurityId,
        listedTokenCompanyUrl,
        listedTokenWhitepaerLink,
        companyOfficialAddress,
        listedTokenName,
        listedTokenSymbol,
        listedTokenDecimalPlace,
        listedTokenInitialSupply,
        listedTokenMaxOwnership,
        listedTokenOwnersERC20Address,
        listedTokenIssued,
        listedTokenRestricted,
        listedTokenTreasury,
        listedTokenTotalUnlocked,
        listedTokenAuthorized,
        listedTokenOfficialAddress,
        listedTokenPrice,
        listedTokenCompanyEmail,
        listedTokenNetPresentValue,
      } = this.state;
      if (
        !_.isUndefined(listedTokenIssued) &&
        !_.isUndefined(listedTokenRestricted) &&
        !_.isUndefined(listedTokenTreasury)
      ) {
        this.setState({
          buttonLoading: true,
        });
        const api = new Api();
        const response = await api
          .setToken(authenticationToken)
          .create("user/dealer/token/details", {
            listedTokenCompanyName,
            listedTokenCompanyLicenseId,
            listedTokenCompanySecurityId,
            listedTokenCompanyUrl,
            listedTokenWhitepaerLink,
            companyOfficialAddress,
            listedTokenName,
            listedTokenSymbol,
            listedTokenDecimalPlace,
            listedTokenInitialSupply,
            listedTokenMaxOwnership,
            listedTokenOwnersERC20Address,
            listedTokenIssued,
            listedTokenRestricted,
            listedTokenTreasury,
            listedTokenTotalUnlocked,
            listedTokenOfficialAddress,
            listedTokenAuthorized,
            listedTokenPrice,
            issuerId,
            listedTokenCompanyEmail,
            listedTokenNetPresentValue,
          });
        if (response) {
          this.setState({ buttonLoading: false });
        }
        if (response.code === 200) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(messageConstants.REQUIRED_FIELDS_VALID_MSG);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async saveSubscriptionAgreement(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      this.setState({ subscriptionLoading: true });
      const { subscriptionAgreementData } = this.state;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/dealer/subscription/agreement/details", {
          issuerId,
          subscriptionAgreementData,
        });
      if (response) {
        this.setState({ subscriptionLoading: false });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (event) {
      console.log("event", event);
    }
  }

  render() {
    const {
      listedTokenName,
      listedTokenCompanyName,
      listedTokenCompanyLicenseId,
      listedTokenCompanySecurityId,
      listedTokenCompanyUrl,
      listedTokenWhitepaerLink,
      listedTokenOfficialAddress,
      listedTokenSymbol,
      listedTokenDecimalPlace,
      listedTokenInitialSupply,
      listedTokenMaxOwnership,
      listedTokenOwnersERC20Address,
      listedTokenAuthorized,
      listedTokenTreasury,
      listedTokenIssued,
      listedTokenRestricted,
      listedTokenTotalUnlocked,
      listedTokenPrice,
      subscriptionRejectedReason,
      subscriptionLoading,
      listedTokenCompanyEmail,
      listedTokenNetPresentValue,
      subscriptionRejected,
      filePath,
    } = this.state;
    return (
      <div className="token-settings-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header">
                      <span className="pull-left text-xl">Token Settings</span>
                      <Link
                        to="/users"
                        className="pull-right"
                        style={{
                          marginTop: "4px",
                          fontSize: "14px",
                          color: "#395cbe",
                        }}
                      >
                        Back to users list
                      </Link>
                    </h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Company Name
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company Name`}
                            type="text"
                            name="listedTokenCompanyName"
                            onChange={this.onchange}
                            value={listedTokenCompanyName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-defsubscriptionRejectedReasonault-color">
                            Company Email
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company Email`}
                            type="text"
                            name="listedTokenCompanyEmail"
                            onChange={this.onchange}
                            value={listedTokenCompanyEmail}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Business Number
                            <i
                              className="fa fa-info-circle ml-1"
                              id="totalAsicsTooltip"
                              title="Total ASICs"
                            ></i>
                            <UncontrolledPopover
                              trigger="hover"
                              placement="bottom"
                              target="totalAsicsTooltip"
                            >
                              <PopoverBody>
                                Insert the Issuer's Business Number (BN) as
                                provided by the Canada Revenue Agency (CRA).
                              </PopoverBody>
                            </UncontrolledPopover>
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company License Id`}
                            type="text"
                            name="listedTokenCompanyLicenseId"
                            onChange={this.onchange}
                            value={listedTokenCompanyLicenseId}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Incorporation Number
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company Security Id`}
                            type="text"
                            name="listedTokenCompanySecurityId"
                            onChange={this.onchange}
                            value={listedTokenCompanySecurityId}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Company URL
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Company URL`}
                            type="text"
                            name="listedTokenCompanyUrl"
                            onChange={this.onchange}
                            id={listedTokenCompanyUrl}
                            value={listedTokenCompanyUrl}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Virtual Data Room Url
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Whitepaper Link`}
                            type="text"
                            name="listedTokenWhitepaerLink"
                            onChange={this.onchange}
                            value={listedTokenWhitepaerLink}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Enterprise Value (EV) in USD
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Net Present Value`}
                            type="text"
                            name="listedTokenNetPresentValue"
                            onChange={this.onchange}
                            value={listedTokenNetPresentValue}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Company Official Address
                          </label>
                          <textarea
                            className="form-control"
                            placeholder={`Please Enter Company Official Address`}
                            name="listedTokenOfficialAddress"
                            onChange={this.onchange}
                            rows={4}
                            value={listedTokenOfficialAddress}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label class="text-default text-default-color">
                            Token Name
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Token Name`}
                            type="text"
                            name="listedTokenName"
                            onChange={this.onchange}
                            value={listedTokenName}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Ticker
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Token Ticker`}
                            type="text"
                            name="listedTokenSymbol"
                            onChange={this.onchange}
                            value={listedTokenSymbol}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Decimal Place
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Decimal Place`}
                            type="text"
                            name="listedTokenDecimalPlace"
                            onChange={this.onchange}
                            value={listedTokenDecimalPlace}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Initial Supply
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Initial Supply`}
                            type="text"
                            name="listedTokenInitialSupply"
                            onChange={this.onchange}
                            value={listedTokenInitialSupply}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Max Ownership
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Token Max Ownership`}
                            type="text"
                            name="listedTokenMaxOwnership"
                            onChange={this.onchange}
                            value={listedTokenMaxOwnership}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Owner ERC20 Address
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Please Enter Owner's ERC20 Address`}
                            type="text"
                            name="listedTokenOwnersERC20Address"
                            onChange={this.onchange}
                            value={listedTokenOwnersERC20Address}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Issued Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Issued Tokens`}
                            type="text"
                            name="listedTokenIssued"
                            onChange={this.onchange}
                            value={listedTokenIssued}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Restricted Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Restricted Token`}
                            type="text"
                            name="listedTokenRestricted"
                            onChange={this.onchange}
                            value={listedTokenRestricted}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Treasury Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Token In Treasury`}
                            type="text"
                            name="listedTokenTreasury"
                            onChange={this.onchange}
                            value={listedTokenTreasury}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Total Unlocked Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Total Unlocked Tokens`}
                            type="text"
                            name="listedTokenTotalUnlocked"
                            onChange={this.onchange}
                            value={listedTokenTotalUnlocked}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Authorized Tokens
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Authorized Tokens`}
                            type="text"
                            name="listedTokenAuthorized"
                            onChange={this.onchange}
                            value={listedTokenAuthorized}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Token Price
                          </label>
                          <input
                            className="form-control"
                            placeholder={`Enter Token Price`}
                            type="text"
                            name="listedTokenPrice"
                            onChange={this.onchange}
                            value={listedTokenPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="form-buttons-w custom-submit-button2 mt-0 border-top-none m-btn-center
                    d-flex justify-content-end"
                    >
                      <button
                        className="btn default-border-btn"
                        type="submit"
                        onClick={(e) => this.saveTokenSettings(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header text-xl">
                      Subscription Agreement
                    </h6>
                    {/* <CKEditor
                      onChange={this.onEditorChange}
                      data={subscriptionAgreementData}
                    /> */}
                    {/* {filePath && (
                      <FileViewer
                        fileType={type}
                        filePath={filePath}
                        onError={this.onError}
                      />
                    )} */}

                    {/* {filePath && (
                      <PDFViewer
                        document={{
                          url: filePath,
                        }}
                      />
                    )} */}

                    {filePath && (
                      <a
                        href={filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Agreement
                      </a>
                    )}

                    {subscriptionRejected && (
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="text-default text-default-color">
                            Reason:
                          </label>
                          <textarea
                            className="form-control"
                            placeholder={`Please Enter Your Reason`}
                            name="subscriptionRejectedReason"
                            onChange={this.onchange}
                            rows={4}
                            value={subscriptionRejectedReason}
                          ></textarea>
                        </div>
                      </div>
                    )}

                    <div
                      className="form-buttons-w custom-submit-button2 mt-0 border-top-none m-btn-center
                    d-flex justify-content-end"
                    >
                      <button
                        className="btn default-border-btn"
                        type="submit"
                        onClick={(e) => this.onRejectAgreement(e)}
                      >
                        Reject
                      </button>
                      <button
                        className="btn default-btn"
                        type="submit"
                        onClick={(e) => this.saveSubscriptionAgreement(e)}
                      >
                        Accept{" "}
                        {subscriptionLoading && (
                          <i className="fa-spin fa fa-spinner text-white m-1" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TokenSettings;
