import React, { Component } from 'react';
import './Vendor.js';
import Layout from "./components/Layout/Layout";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: true
		};
	}

	componentDidMount() {
		this.setState({ show: false });
	}

	render() {
		return (
			<div className="App">
				<CacheBuster
					currentVersion={version}
					isEnabled={true} //If false, the library is disabled.
					isVerboseMode={false} //If true, the library writes verbose logs to console.
					loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
					>
					<Layout />
				</CacheBuster>
			</div>
		);
	}
}
export default App;