import React from "react";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import Api from "../../services/api";
import logoImg from "../../assets/img/supplier-w.png?v3";
import evolutionarymarketplace from "./assets/images/evolutionary-marketplace.svg";
import increasednetback from "./assets/images/increased-netback.svg";
import unlockuntapped from "./assets/images/unlock-untapped.svg";
import capitalize from "./assets/images/capitalize_1.svg";
import brokerage from "./assets/images/brokerage.svg";
import conserve from "./assets/images/conserve_2.svg";
import consult from "./assets/images/consult_3.svg";
import convert from "./assets/images/convert_4.svg";
import supplierIcon from "./assets/images/supplier-tiny-icon.svg?v3";

import "./assets/css/icomoon.css";
import "./assets/css/stylecustom.css";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      buttonLoading: false,
      modalOpen: false,
      recaptchaResponse: "",
      addInterestLoading: false,
      otherInterestShow: false,
      otherinterest: "",
      termsAccepted: false,
    };
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("remove");
    }
    this.onChange = this.onChange.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(
      this
    );
    this.changeInterestOptions = this.changeInterestOptions.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  acceptTerms(event) {
    this.setState({
      termsAccepted: event.target.checked,
    });
  }

  async subscribeNow(event) {
    event.preventDefault();
    const { email } = this.state;
    if (email && email !== "") {
      this.setState({
        buttonLoading: true,
      });
      const api = new Api();
      const response = await api.create("user/subscribe", {
        email,
      });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.setState({
            email: "",
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Please enter valid email.");
    }
  }

  changeInterestOptions(e) {
    var options = e.target.options;
    var value = [];
    var otherinterestFlag = false;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
        if (options[i].value === "other") {
          otherinterestFlag = true;
        }
      }
    }
    let stateObj = {
      interestedoption: value,
    };
    if (otherinterestFlag && otherinterestFlag === true) {
      stateObj.otherInterestShow = true;
    }
    this.setState(stateObj);
  }

  render() {
    const { email, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="home-page-container home-page-main-container supplier-home-page-container">
        <MetaTags>
          <title>
            Digital Securities issuance platform for exploration & production
            companies
          </title>
        </MetaTags>
        <div id="page" className="homepage">
          <nav className="colorlib-nav" role="navigation">
            <div className="top-menu">
              <div className="container">
                <div className="row">
                  <div className="col-md-2">
                    <div id="colorlib-logo">
                      <img src={logoImg} height={70} alt="Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <section
            id="home"
            className="video-hero home-background-image"
            data-section="home"
          >
            <div className="overlay"></div>
            <div className="display-t">
              <div className="display-tc">
                <div className="container">
                  <div className="col-md-12 col-md-offset-0">
                    <div className="animate-box mt-3">
                      <h2>
                        PermianChain Supplier: enabling exploration and
                        production (E&P) companies to catalogue, manage and
                        create value from their proven oil and gas reserves.
                      </h2>
                      <h3 className="home-heading-text">
                        Making it simple for exploration & production (E&P)
                        companies and registered market dealers to work together
                        to achieve sustainable resource financing
                      </h3>
                      <p className="slider-text">
                        With an integrated digital marketplace making it simple
                        for natural gas operators and data centre operators to
                        digitally trade field generated electricity to power
                        onsite server farms.
                      </p>
                      <p>
                        <Link
                          to="/signin"
                          className="btn btn-primary btn-lg btn-custom home-link-button mr-4"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sign In
                        </Link>
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          className="btn btn-primary btn-lg btn-custom home-link-button"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="colorlib-services colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img
                        src={evolutionarymarketplace}
                        width={84}
                        alt="Evolutionary Marketplace"
                      />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Evolutionary marketplace for natural gas resources
                      </h3>
                      <p className="body-text">
                        PermianChain Supplier platform enables E&P companies to
                        sell field-generated electricity to data centre
                        operators globally. PermianChain opens a new market for
                        E&Ps willing to sell excess/wasted natural gas resources
                        to take advantage of the Spark Spread.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img
                        src={increasednetback}
                        width={84}
                        alt="Increased Netback"
                      />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Increased netback and net benefits to E&P companies
                      </h3>
                      <p className="body-text">
                        PermianChain Supplier allows E&P companies to benefit
                        from digitally streamlined and automated arbitrage
                        between natural gas (mcf/d) and electricity (kWh) using
                        Smart Off-take Agreements (SOTAs).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center animate-box">
                  <div className="services mt-5">
                    <span className="icon">
                      <img
                        src={unlockuntapped}
                        width={84}
                        alt="Unlock untapped"
                      />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Unlock untapped digital earnings for optimized cash
                        flows
                      </h3>
                      <p className="body-text">
                        Option to register to digital currency mining pools.
                        Convert digital earnings from onsite data mining into
                        cash-flows. By deploying company-owned data centres,
                        E&Ps can optimize cash-flows from each data mining
                        operation with the potential for funding working capital
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center animate-box">
                  <div className="services mt-5">
                    <span className="icon">
                      <img src={brokerage} width={84} alt="Brokerage" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        Trust protocol bringing together exempt market dealers
                        (EMDs) and E&P companies
                      </h3>
                      <p className="body-text">
                        Enabling E&P issuers to work with EMDs to offer debt and
                        equity investment opportunities as compliant digital
                        securities in the form of smart contracts (i.e. ERC-20
                        tokens) on the blockchain (i.e. Ethereum) to accredited
                        investors under available prospectus exemptions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-featured">
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="cubewrap">
                    <div className="cube">
                      <div className="front">
                        <img
                          src={supplierIcon}
                          height={45}
                          className="lockerimage"
                          alt="Supplier"
                        />
                      </div>
                      <div className="back"></div>
                      <div className="top"></div>
                      <div className="bottom"></div>
                      <div className="left"></div>
                      <div className="right"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <p className="mt-5 cube-details text-center colorlib-heading animate-box mb-3">
                    PermianChain Supplier is dedicated to natural resources
                    exploration & production companies, enabling them to work
                    with registered exempt market dealers (EMDs) to access
                    potential capital sources to meet the funding shortfalls and
                    natural gas market challenge. The panel allows for:
                  </p>

                  <div>
                    <ol type="a" className="text-left">
                      <li>
                        project submission for issuing of digital securities
                        which will be offered to accredited investors on the
                        PermianChain Investor dashboard by registered EMDs;
                      </li>
                      <li>
                        protocols, features and functions that allow for direct
                        communication and transaction mechanisms with buyers for
                        the sale of field-generated electricity in a digital
                        marketplace using blockchain infrastructure;
                      </li>
                      <li>
                        utility token smart contracts for trading converted
                        natural gas using Smart Off-Take Agreements (SOTA).
                      </li>
                    </ol>
                  </div>

                  <p className="cube-details text-center cube-register-text">
                    Register today for a live demo with a PermianChain
                    representative.
                  </p>
                  <p className="text-center">
                    <a
                      href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                      className="btn btn-primary btn-lg btn-custom live-demo-button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Request Live Demo
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-work-featured colorlib-bg-white detailed-description">
            <div className="container homecontainer">
              <div className="row mobile-wrap">
                <div className="col-md-12 animate-box pull-left">
                  <div className="desc">
                    <h3>PermianChain’s 4-C’s for Oil & Gas 4.0</h3>
                    <div className="features">
                      <span className="icon">
                        <img src={consult} width={50} alt="Consult" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Consult</strong>
                          <br />
                          EMD’s using the PermianChain technology can provide
                          exploration &amp; production companies ongoing
                          consulting on efficient digital frameworks to solve
                          asset owner communication, strengthen investor
                          relations and optimize cash-flows by adopting the
                          PermianChain pillars.
                          <br />
                          <button
                            className="btn btn-primary btn-custom learn-more-link"
                            onClick={() => this.openInterestModal()}
                            disabled={buttonDisabled}
                          >
                            Learn More
                          </button>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={conserve} width={50} alt="Conserve" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Conserve</strong>
                          <br />
                          Through the adoption of PermianChain’s Oil &amp; Gas
                          4.0 framework, we deploy power generation
                          infrastructure allowing E&amp;Ps to create an onsite
                          market for excess, stranded and/or wasted natural gas,
                          generating a higher netback per mcf.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={convert} width={50} alt="Convert" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Convert</strong>
                          <br />
                          When adopting PermianChain’s Oil &amp; Gas 4.0
                          framework we guide upstream companies through the
                          development stages of converting gas-to-bitcoin, which
                          translates into increased cash-flow and an auxiliary
                          source of revenue to fund drilling campaigns.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img
                          src={capitalize}
                          width={50}
                          alt="Capitalize Icon"
                        />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Capitalize</strong>
                          <br />
                          As part of the Oil &amp; Gas 4.0 integration, our team
                          facilitates the potential to access new sources of
                          capital by adopting our technology with registered
                          EMDs, digital asset brokers and authorized placement
                          agents to structure and distribute digital securities
                          for improved liquidity and cash-flow.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="colorlib-subscribe"
            className="colorlib-subscribe"
            data-stellar-background-ratio="0.5"
          >
            <div className="overlay"></div>
            <div className="homecontainer marginleftrightauto">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 text-center colorlib-heading animate-box">
                  <h2>Subscribe Newsletter</h2>
                  <p className="font-size-16">
                    If you’d like to hear more about how PermianChain is
                    boosting oil and gas trading and investment, join our
                    mailing list.
                  </p>
                </div>
              </div>
              <div className="row animate-box">
                <div className="col-md-3 col-sm-3"></div>
                <div className="col-md-6 col-sm-6">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="form-inline qbstp-header-subscribe">
                        <div className="col-three-forth">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Enter your email"
                              onChange={this.onChange}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-one-third">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.subscribeNow(e)}
                              disabled={buttonDisabled}
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id="colorlib-footer">
            <div className="copy">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      Copyright © 2020 PermianChain Technologies, Inc. All right
                      reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
export default Home;
