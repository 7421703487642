import React, { Component } from 'react';
import Modal from "react-responsive-modal";

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { changePasswordModalOpen, onCloseModal, onInputValueChange, changePasswordButtonLoading, updatePassword } = this.props;
    let buttonDisabled = (changePasswordButtonLoading === true) ? true : false;
    return (
      <Modal open={changePasswordModalOpen} onClose={onCloseModal} showCloseIcon={false} center classNames={{ closeIcon: 'pull-right cursor-pointer', 'modal': 'instructon-modal-container' }}>
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-large">Change Password</h4>
                    <form>

                      <div className="form-group">
                        <label className="text-default-color">Current Password<span className="required-field">*</span></label>
                        <input className="form-control" placeholder="Enter current password" type="password" name="oldPassword" id="oldPassword" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label className="text-default-color">New Password<span className="required-field">*</span></label>
                        <input className="form-control" placeholder="Enter current password" type="password" name="newPassword" id="newPassword" onChange={onInputValueChange} />
                      </div>

                      <div className="form-group">
                        <label className="text-default-color">Confirm New Password<span className="required-field">*</span></label>
                        <input className="form-control" placeholder="Enter current password" type="password" name="confirmNewPassword" id="confirmNewPassword" onChange={onInputValueChange} />
                      </div>
                      <div className="d-flex justify-content-end mt-4">
                        <button className="btn modal-button cancel-button" type="button"
                          onClick={onCloseModal}>Cancel</button>
                        <button className="btn modal-button update-button" type="button" disabled={buttonDisabled} onClick={updatePassword}>Update {changePasswordButtonLoading && (
                          <i className="fa-spin fa fa-spinner text-white ml-1" />
                        )}</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ChangePasswordModal;