import React, { Component } from 'react';

class DueDiligenceText extends Component {

    render() {
        const { fullName, companyName } = this.props;
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="element-wrapper">
                        <div className="element-box custom-element-box duediligence-text">
                            <h5 className="text-default-color text-xl">Dear {fullName}</h5>
                            <h6 className="text-default-color text-default mt-3" style={{ fontWeight: '600' }}>Re: Due Diligence Review of {companyName} (the "Corporation"), related to a digital securities issuance or joint venture partnerships.</h6>
                            <p className="mt-3">Outlined below is a preliminary list of documents or materials that we ask you provide us for our due diligence review and for the review of your registered Exempt Market Dealer (EMD). We would also ask that you please advise us of any new information or amendments to the existing information as they become available and provide us with any available additional documentation relating thereto. Where we reference the Corporation in our questions below, our inquiries also apply to the Corporation’s subsidiaries.</p>
                            <p className="mt-2">Please submit this checklist indicating the status of each of the enumerated items. Please indicate for each item below whether: (i) the material is available; (ii) the item is not applicable to the Corporation; or (iii) the material is not available. If the material is available please make sure to upload the material by clicking the upload button alongside each enumerated item. While we appreciate that many of the items contained in the attached "standard list" may not be applicable to the Corporation we include the full list in order to ensure the completeness of our review. We therefore understand that certain of these items may be indicated as "not applicable". Please be advised that our request is limited to information and materials which have arisen or been created in the last 12 to 36 months unless otherwise noted.</p>
                            <p className="mt-2">Click next to submit your due digilence information.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DueDiligenceText;