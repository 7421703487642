import React, { Component } from "react";
import { toast } from "react-toastify";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";

class Forecast extends Component {
  constructor() {
    super();
    this.state = {
      forecastLoading: false,
      forecastTitle: "Forecast",
      primaryUnitTitle: "NET ENERGY GROWTH",
      primaryUnitTooltipTitle: "PermianChain net energy reserves per year",
      secondaryUnitTitle: "NET OIL RESERVE GROWTH",
      secondaryUnitTooltipTitle: "PermianChain net oil reserves per year",
    };
    this.onchange = this.onchange.bind(this);
    this.saveTokenForecastSettings = this.saveTokenForecastSettings.bind(this);
  }

  async componentDidMount() {
    document.title =
      messageConstants.TOKEN_SETTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
    this.getTokenDetails();
  }

  async getTokenDetails() {
    try {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/forecast/details");
      if (response.code === 200) {
        let stateObj = {};
        if (response.data.listedTokenForecastDetails) {
          stateObj.forecastTitle = response.data.listedTokenForecastDetails
            .forecastTitle
            ? response.data.listedTokenForecastDetails.forecastTitle
            : "";
          stateObj.xAxisTimeUnit = response.data.listedTokenForecastDetails
            .xAxisTimeUnit
            ? response.data.listedTokenForecastDetails.xAxisTimeUnit
            : "";
          stateObj.primaryUnitTitle = response.data.listedTokenForecastDetails
            .primaryUnitTitle
            ? response.data.listedTokenForecastDetails.primaryUnitTitle
            : "";
          stateObj.primaryUnitTooltipTitle = response.data
            .listedTokenForecastDetails.primaryUnitTooltipTitle
            ? response.data.listedTokenForecastDetails.primaryUnitTooltipTitle
            : "";
          stateObj.secondaryUnitTitle = response.data.listedTokenForecastDetails
            .secondaryUnitTitle
            ? response.data.listedTokenForecastDetails.secondaryUnitTitle
            : "";
          stateObj.priceUnitTitle = response.data.listedTokenForecastDetails
            .priceUnitTitle
            ? response.data.listedTokenForecastDetails.priceUnitTitle
            : "";

          stateObj.secondaryUnitTooltipTitle = response.data
            .listedTokenForecastDetails.secondaryUnitTooltipTitle
            ? response.data.listedTokenForecastDetails.secondaryUnitTooltipTitle
            : "";
          stateObj.listedTokenForecastTimeUnit0Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit0Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit0Price
            : "";
          stateObj.listedTokenForecastPrimary0Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary0Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary0Value
            : "";
          stateObj.listedTokenForecastSecondary0Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary0Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary0Value
            : "";
          stateObj.listedTokenForecastTimeUnit1Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit1Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit1Price
            : "";
          stateObj.listedTokenForecastPrimary1Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary1Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary1Value
            : "";
          stateObj.listedTokenForecastSecondary1Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary1Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary1Value
            : "";
          stateObj.listedTokenForecastTimeUnit2Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit2Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit2Price
            : "";
          stateObj.listedTokenForecastPrimary2Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary2Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary2Value
            : "";
          stateObj.listedTokenForecastSecondary2Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary2Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary2Value
            : "";
          stateObj.listedTokenForecastTimeUnit3Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit3Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit3Price
            : "";
          stateObj.listedTokenForecastPrimary3Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary3Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary3Value
            : "";
          stateObj.listedTokenForecastSecondary3Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary3Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary3Value
            : "";
          stateObj.listedTokenForecastTimeUnit4Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit4Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit4Price
            : "";
          stateObj.listedTokenForecastPrimary4Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary4Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary4Value
            : "";
          stateObj.listedTokenForecastSecondary4Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary4Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary4Value
            : "";
          stateObj.listedTokenForecastTimeUnit5Price = response.data
            .listedTokenForecastDetails.listedTokenForecastTimeUnit5Price
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastTimeUnit5Price
            : "";
          stateObj.listedTokenForecastPrimary5Value = response.data
            .listedTokenForecastDetails.listedTokenForecastPrimary5Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastPrimary5Value
            : "";
          stateObj.listedTokenForecastSecondary5Value = response.data
            .listedTokenForecastDetails.listedTokenForecastSecondary5Value
            ? response.data.listedTokenForecastDetails
                .listedTokenForecastSecondary5Value
            : "";
          stateObj.disclaimer = response.data.listedTokenForecastDetails
            .disclaimer
            ? response.data.listedTokenForecastDetails.disclaimer
            : "";
          stateObj.yAxisMainTitle = response.data.listedTokenForecastDetails
            .yAxisMainTitle
            ? response.data.listedTokenForecastDetails.yAxisMainTitle
            : "";
          stateObj.tokenPriceYaxisTitle = response.data
            .listedTokenForecastDetails.tokenPriceYaxisTitle
            ? response.data.listedTokenForecastDetails.tokenPriceYaxisTitle
            : "";
        }
        this.setState(stateObj, async () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
    } catch (err) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async saveTokenForecastSettings(event) {
    event.preventDefault();
    let authenticationToken = this.props.authToken;
    let issuerId = this.props.match.params.id;
    try {
      this.setState({ forecastLoading: true });
      const {
        forecastTitle,
        xAxisTimeUnit,
        secondaryUnitTitle,
        priceUnitTitle,
        secondaryUnitTooltipTitle,
        primaryUnitTitle,
        primaryUnitTooltipTitle,
        listedTokenForecastTimeUnit0Price,
        listedTokenForecastPrimary0Value,
        listedTokenForecastSecondary0Value,
        listedTokenForecastTimeUnit1Price,
        listedTokenForecastPrimary1Value,
        listedTokenForecastSecondary1Value,
        listedTokenForecastTimeUnit2Price,
        listedTokenForecastPrimary2Value,
        listedTokenForecastSecondary2Value,
        listedTokenForecastTimeUnit3Price,
        listedTokenForecastPrimary3Value,
        listedTokenForecastSecondary3Value,
        listedTokenForecastTimeUnit4Price,
        listedTokenForecastPrimary4Value,
        listedTokenForecastSecondary4Value,
        listedTokenForecastTimeUnit5Price,
        listedTokenForecastPrimary5Value,
        listedTokenForecastSecondary5Value,
        disclaimer,
        yAxisMainTitle,
        tokenPriceYaxisTitle,
      } = this.state;
      const api = new Api();
      const response = await api
        .setToken(authenticationToken)
        .create("user/issuer/forecast/details", {
          issuerId,
          forecastTitle,
          xAxisTimeUnit,
          primaryUnitTitle,
          primaryUnitTooltipTitle,
          secondaryUnitTitle,
          priceUnitTitle,
          secondaryUnitTooltipTitle,
          listedTokenForecastTimeUnit0Price,
          listedTokenForecastPrimary0Value,
          listedTokenForecastSecondary0Value,
          listedTokenForecastTimeUnit1Price,
          listedTokenForecastPrimary1Value,
          listedTokenForecastSecondary1Value,
          listedTokenForecastTimeUnit2Price,
          listedTokenForecastPrimary2Value,
          listedTokenForecastSecondary2Value,
          listedTokenForecastTimeUnit3Price,
          listedTokenForecastPrimary3Value,
          listedTokenForecastSecondary3Value,
          listedTokenForecastTimeUnit4Price,
          listedTokenForecastPrimary4Value,
          listedTokenForecastSecondary4Value,
          listedTokenForecastTimeUnit5Price,
          listedTokenForecastPrimary5Value,
          listedTokenForecastSecondary5Value,
          disclaimer,
          yAxisMainTitle,
          tokenPriceYaxisTitle,
        });
      if (response) {
        this.setState({ forecastLoading: false });
      }
      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (event) {
      console.log("event", event);
    }
  }

  render() {
    const {
      listedTokenSymbol,
      xAxisTimeUnit,
      forecastTitle,
      secondaryUnitTitle,
      secondaryUnitTooltipTitle,
      primaryUnitTitle,
      primaryUnitTooltipTitle,
      forecastLoading,
      listedTokenForecastTimeUnit0Price,
      listedTokenForecastPrimary0Value,
      listedTokenForecastSecondary0Value,
      listedTokenForecastTimeUnit1Price,
      listedTokenForecastPrimary1Value,
      listedTokenForecastSecondary1Value,
      listedTokenForecastTimeUnit2Price,
      listedTokenForecastPrimary2Value,
      listedTokenForecastSecondary2Value,
      listedTokenForecastTimeUnit3Price,
      listedTokenForecastPrimary3Value,
      listedTokenForecastSecondary3Value,
      listedTokenForecastTimeUnit4Price,
      listedTokenForecastPrimary4Value,
      listedTokenForecastSecondary4Value,
      listedTokenForecastTimeUnit5Price,
      listedTokenForecastPrimary5Value,
      listedTokenForecastSecondary5Value,
      disclaimer,
      yAxisMainTitle,
      tokenPriceYaxisTitle,
      priceUnitTitle,
    } = this.state;

    return (
      <div className="token-settings-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="element-wrapper">
                  <div className="element-box">
                    <h6 className="element-header text-xl">Forecast Details</h6>
                    <div className="form-buttons-w custom-submit-button2 mt-0 border-top-none pt-0">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Forecast section title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter title`}
                              type="text"
                              name="forecastTitle"
                              onChange={this.onchange}
                              value={forecastTitle}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Time unit</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter time unit (Month/Year)`}
                              type="text"
                              name="xAxisTimeUnit"
                              onChange={this.onchange}
                              value={xAxisTimeUnit}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Y axis main title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter Y axis main title`}
                              type="text"
                              name="yAxisMainTitle"
                              onChange={this.onchange}
                              value={yAxisMainTitle}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Token price Y axis title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter primary unit tooltip title`}
                              type="text"
                              name="tokenPriceYaxisTitle"
                              onChange={this.onchange}
                              value={tokenPriceYaxisTitle}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Primary unit title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter primary unit title`}
                              type="text"
                              name="primaryUnitTitle"
                              onChange={this.onchange}
                              value={primaryUnitTitle}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Primary unit tooltip title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter primary unit tooltip title`}
                              type="text"
                              name="primaryUnitTooltipTitle"
                              onChange={this.onchange}
                              value={primaryUnitTooltipTitle}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Secondary unit title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter secondary unit title`}
                              type="text"
                              name="secondaryUnitTitle"
                              onChange={this.onchange}
                              value={secondaryUnitTitle}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Secondary unit tooltip title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter secondary unit title`}
                              type="text"
                              name="secondaryUnitTooltipTitle"
                              onChange={this.onchange}
                              value={secondaryUnitTooltipTitle}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Price unit title</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price unit title`}
                              type="text"
                              name="priceUnitTitle"
                              onChange={this.onchange}
                              value={priceUnitTitle}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Disclaimer</label>
                            <textarea
                              className="form-control"
                              placeholder={`Please enter disclaimer`}
                              name="disclaimer"
                              onChange={this.onchange}
                              rows={3}
                              value={disclaimer}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 1 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit0Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit0Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary0Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary0Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary0Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary0Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 2 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit1Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit1Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary1Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary1Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary1Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary1Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 3 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit2Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit2Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary2Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary2Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary2Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary2Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 4 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit3Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit3Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary3Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary3Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary3Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary3Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 5 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit4Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit4Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary4Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary4Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary4Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary4Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h6 className="mb-1 mt-1 text-default">
                            {listedTokenSymbol} {xAxisTimeUnit} 6 Values
                          </h6>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Price</label>
                            <input
                              className="form-control"
                              placeholder={`Please enter price`}
                              type="text"
                              name="listedTokenForecastTimeUnit5Price"
                              onChange={this.onchange}
                              value={listedTokenForecastTimeUnit5Price}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{primaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${primaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastPrimary5Value"
                              onChange={this.onchange}
                              value={listedTokenForecastPrimary5Value}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>{secondaryUnitTitle} Value</label>
                            <input
                              className="form-control"
                              placeholder={`Please Enter ${secondaryUnitTitle}`}
                              type="text"
                              name="listedTokenForecastSecondary5Value"
                              onChange={this.onchange}
                              value={listedTokenForecastSecondary5Value}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-buttons-w forcast-submit d-flex justify-content-end custom-submit-button2 mt-0 border-top-none">
                        <button
                          className="btn default-btn"
                          type="submit"
                          onClick={(e) => this.saveTokenForecastSettings(e)}
                          disabled={forecastLoading}
                        >
                          Submit
                          {forecastLoading && (
                            <i className="fa-spin fa fa-spinner text-white ml-1" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Forecast;
