import React, { Component } from "react";
/* import { Checkbox } from "react-icheck"; */
import _ from "lodash";
import moment from "moment";
import Modal from "react-responsive-modal";
import axios from "axios";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Api from "../../services/api";
import { API_ROOT } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
import Map from "./Map";
import auth from "../../utils/auth";
import { pageProgress } from "../../utils/Util";

let fileRef = {};
let fileIconRef = {};
let listingDocuments = [];
let thisref;

const DEFAULT_CONVERSION_RATE = 3.3;
const DEFAULT_CONVERSION_SCALE = "dailyProductionMcf";

class NewListing extends Component {
  constructor() {
    super();
    this.state = {
      conversionRate: DEFAULT_CONVERSION_RATE,
      conversionScale: DEFAULT_CONVERSION_SCALE,
      dailyProductionMcf: "",
      estimatedPoweCapacity: "",
      powerPrice: "",
      oilTotalProvedReserves: "0",
      oilTotalProvedReservesUnit: "BBL",
      oilTotalProbableReserves: "0",
      oilTotalProbableReservesUnit: "BBL",
      oilTotalPossibleReserves: "0",
      oilTotalPossibleReservesUnit: "BBL",
      gasTotalProvedReserves: "0",
      gasTotalProvedReservesUnit: "MCF",
      gasTotalProbableReserves: "0",
      gasTotalProbableReservesUnit: "MCF",
      gasTotalPossibleReserves: "0",
      gasTotalPossibleReservesUnit: "MCF",
      listingDocumentType: "",
      paymentMethodsList: "",
      mapModalOpen: false,
      acceptedPaymentMethods: [],
      buttonLoading: false,
      totalRequiredDocuments: 0,
      listingDetails: [],
      allowWireTransfer: false,
      isFileUploaded: [],
      googlePlaceAutoSuggestResponse: "",
      markerPosition: {
        lat: 18.5204,
        lng: 73.8567,
      },
      mapPosition: {
        lat: 18.5204,
        lng: 73.8567,
      },
      address: "",
      averageDailykwh: "",
      dateOnline: "",
      dateOffline: "",
    };
    this.openMapModal = this.openMapModal.bind(this);
    this.mapModalCloseModal = this.mapModalCloseModal.bind(this);
    this.createNewListing = this.createNewListing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeNumberPositive = this.onChangeNumberPositive.bind(this);
    this.onChangeConversion = this.onChangeConversion.bind(this);
    this.changePaymentTypes = this.changePaymentTypes.bind(this);
    this.checkPaymentMehodSelected = this.checkPaymentMehodSelected.bind(this);
    this.getCity = this.getCity.bind(this);
    this.getArea = this.getArea.bind(this);
    this.getState = this.getState.bind(this);
    this.setMapStateValues = this.setMapStateValues.bind(this);
    this.setMapLatituteLongitude = this.setMapLatituteLongitude.bind(this);
    thisref = this;
    this.userUploadedFileCounter = 0;
  }

  async getPriceDEC() {
    let decPrice = 0.05;
    const api = new Api();
    api.setToken(auth.getToken());
    const priceResponse = await api
      .get("supplier/stripePrice", {
        type: "DEC",
      });
    if (
      priceResponse.code === 200 &&
      priceResponse.data
    ) {
      if (priceResponse.data.stripePrice) {
        decPrice = Number(priceResponse.data.stripePrice.unitPrice);
      } else {
        decPrice = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get DEC price, using default $0.05");
    }
    return decPrice;
  }

  async componentDidMount() {
    document.title =
      messageConstants.CAMPAIGN_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    let kycVerifiedResponse = await this.props.checkKycVerified();
    let listingDbId = this.props.match.params.id;
    let userResponse = kycVerifiedResponse.userDetailsResponse;
    let wireTransferDetails =
      userResponse && userResponse.data && userResponse.data.wireTransferDetails
        ? userResponse.data.wireTransferDetails
        : "";

    if (wireTransferDetails) {
      this.setState({
        allowWireTransfer: true,
      });
    }

    try {
      this.state.decPrice = await this.getPriceDEC();
      const api = new Api();
      api.setToken(auth.getToken());
      const documentListResponse = await api
        .get("supplier/listing/documents/types");
      if (documentListResponse.code === 200) {
        if (documentListResponse.data.listingDocumentsList) {
          this.setState({
            listingDocumentType: documentListResponse.data.listingDocumentsList,
            requiredDocumentCounter:
              documentListResponse.data.requiredDocumentCounter,
          });
        }
      }
      const paymentListResponse = await api
        .get("supplier/listing/payment/methods");
      if (paymentListResponse.code === 200) {
        if (paymentListResponse.data.paymentMethodsList) {
          this.setState({
            paymentMethodsList: paymentListResponse.data.paymentMethodsList,
          });
        }
      }

      /* Get listing details for edit */
      if (listingDbId && listingDbId !== "") {
        const listingDetailsResponse = await api
          .get("supplier/listing/details", { listingId: listingDbId });
        if (
          listingDetailsResponse.code === 200 &&
          listingDetailsResponse.data.listingDetail
        ) {
          const listingDetailsData = listingDetailsResponse.data.listingDetail;
          let isFileUploadedState = {};
          if (
            listingDetailsResponse.data.listingFiles &&
            !_.isEmpty(listingDetailsResponse.data.listingFiles)
          ) {
            const tempDocumentTypes =
              listingDetailsResponse.data.documentTypeListTypes;
            const tempObejctIdRef = listingDetailsResponse.data.objectIdRef;
            this.userUploadedFileCounter = _.size(
              listingDetailsResponse.data.listingFiles
            );
            _.forEach(tempDocumentTypes, function (value, key) {
              const typeId = value._id;
              if (tempObejctIdRef[typeId]) {
                isFileUploadedState[typeId] = true;
              }
            });
          }

          let stateObj = {
            listingDetails: listingDetailsResponse.data.listingDetail,
            documentTypeListTypes:
              listingDetailsResponse.data.documentTypeListTypes,
            listingFiles: listingDetailsResponse.data.listingFiles,
            objectIdRef: listingDetailsResponse.data.objectIdRef,
            dailyProductionMcf:
              listingDetailsData && listingDetailsData.dailyProductionMcf
                ? listingDetailsData.dailyProductionMcf
                : "",
            estimatedPoweCapacity:
              listingDetailsData && listingDetailsData.estimatedPoweCapacity
                ? listingDetailsData.estimatedPoweCapacity
                : "",
            powerPrice:
              listingDetailsData && listingDetailsData.powerPrice
                ? listingDetailsData.powerPrice
                : "",
            averageDailyProductionInBBL:
              listingDetailsData &&
                listingDetailsData.averageDailyProductionInBBL
                ? listingDetailsData.averageDailyProductionInBBL
                : "",
            averageDailyProductionInBOE:
              listingDetailsData &&
                listingDetailsData.averageDailyProductionInBOE
                ? listingDetailsData.averageDailyProductionInBOE
                : "",
            oilTotalProvedReserves:
              listingDetailsData && listingDetailsData.oilTotalProvedReserves
                ? listingDetailsData.oilTotalProvedReserves
                : "0",
            oilTotalProvedReservesUnit:
              listingDetailsData &&
                listingDetailsData.oilTotalProvedReservesUnit
                ? listingDetailsData.oilTotalProvedReservesUnit
                : "BBL",
            oilTotalProbableReserves:
              listingDetailsData && listingDetailsData.oilTotalProbableReserves
                ? listingDetailsData.oilTotalProbableReserves
                : "0",
            oilTotalProbableReservesUnit:
              listingDetailsData &&
                listingDetailsData.oilTotalProbableReservesUnit
                ? listingDetailsData.oilTotalProbableReservesUnit
                : "BBL",
            oilTotalPossibleReserves:
              listingDetailsData && listingDetailsData.oilTotalPossibleReserves
                ? listingDetailsData.oilTotalPossibleReserves
                : "0",
            oilTotalPossibleReservesUnit:
              listingDetailsData &&
                listingDetailsData.oilTotalPossibleReservesUnit
                ? listingDetailsData.oilTotalPossibleReservesUnit
                : "BBL",
            gasTotalProvedReserves:
              listingDetailsData && listingDetailsData.gasTotalProvedReserves
                ? listingDetailsData.gasTotalProvedReserves
                : "0",
            gasTotalProvedReservesUnit:
              listingDetailsData &&
                listingDetailsData.gasTotalProvedReservesUnit
                ? listingDetailsData.gasTotalProvedReservesUnit
                : "MCF",
            gasTotalProbableReserves:
              listingDetailsData && listingDetailsData.gasTotalProbableReserves
                ? listingDetailsData.gasTotalProbableReserves
                : "0",
            gasTotalProbableReservesUnit:
              listingDetailsData &&
                listingDetailsData.gasTotalProbableReservesUnit
                ? listingDetailsData.gasTotalProbableReservesUnit
                : "MCF",
            gasTotalPossibleReserves:
              listingDetailsData && listingDetailsData.gasTotalPossibleReserves
                ? listingDetailsData.gasTotalPossibleReserves
                : "0",
            gasTotalPossibleReservesUnit:
              listingDetailsData &&
                listingDetailsData.gasTotalPossibleReservesUnit
                ? listingDetailsData.gasTotalPossibleReservesUnit
                : "MCF",
            nglTotalProvedReserves:
              listingDetailsData && listingDetailsData.nglTotalProvedReserves
                ? listingDetailsData.nglTotalProvedReserves
                : "",
            nglGasTotalProvedReservesUnit:
              listingDetailsData &&
                listingDetailsData.nglGasTotalProvedReservesUnit
                ? listingDetailsData.nglGasTotalProvedReservesUnit
                : "MCF",
            nglGasTotalProbableReserves:
              listingDetailsData &&
                listingDetailsData.nglGasTotalProbableReserves
                ? listingDetailsData.nglGasTotalProbableReserves
                : "",
            nglGasTotalProbableReservesUnit:
              listingDetailsData &&
                listingDetailsData.nglGasTotalProbableReservesUnit
                ? listingDetailsData.nglGasTotalProbableReservesUnit
                : "MCF",
            nglGasTotalPossibleReserves:
              listingDetailsData &&
                listingDetailsData.nglGasTotalPossibleReserves
                ? listingDetailsData.nglGasTotalPossibleReserves
                : "",
            nglTotalPossibleReservesUnit:
              listingDetailsData &&
                listingDetailsData.nglTotalPossibleReservesUnit
                ? listingDetailsData.nglTotalPossibleReservesUnit
                : "MCF",
            acceptedPaymentMethods:
              listingDetailsData && listingDetailsData.acceptedPaymentMethods
                ? listingDetailsData.acceptedPaymentMethods
                : [],
            isFileUploaded: isFileUploadedState,
          };
          const { dailyProductionMcf, estimatedPoweCapacity } = stateObj;
          if (dailyProductionMcf && estimatedPoweCapacity) {
            stateObj.conversionRate = estimatedPoweCapacity / dailyProductionMcf;
          }
          if (listingDetailsData && listingDetailsData.location) {
            stateObj.address =
              listingDetailsData && listingDetailsData.location
                ? listingDetailsData.location
                : "";
          }
          if (
            listingDetailsData &&
            listingDetailsData.latitude &&
            listingDetailsData.longitude
          ) {
            const temp = {
              lat: parseFloat(listingDetailsData.latitude),
              lng: parseFloat(listingDetailsData.longitude),
            };
            stateObj.markerPosition = temp;
            stateObj.mapPosition = temp;
          }
          if (listingDetailsData) {
            const { dateOnline, dateOffline } = listingDetailsData;
            const pattern = "YYYY-MM-DD";
            if (dateOnline) {
              stateObj.dateOnline = moment(dateOnline).format(pattern);
            }
            if (dateOffline) {
              stateObj.dateOffline = moment(dateOffline).format(pattern);
            }
          }
          if (stateObj && !_.isEmpty(stateObj)) {
            this.setState(stateObj);
          }
        }
      }
      /* Get listing details for edit ends */
    } catch (err) {
      console.log("err");
      console.log(err);
    }

    if (kycVerifiedResponse.status === true) {
      pageProgress("remove")
    } else {
      // this.props.history.push(kycVerifiedResponse.redirectUrl);
    }
    pageProgress("force_remove");
  }

  handleUploadFile(event, refName, documentTypeId) {
    if (refName) {
      let { isFileUploaded } = this.state;
      isFileUploaded[documentTypeId] = true;
      this.setState(isFileUploaded);
    }
    if (event.target.files[0]) {
      let temp = {
        key: refName,
        documentTypeId: documentTypeId,
        file: event.target.files[0],
      };
      listingDocuments.push(temp);
      this.userUploadedFileCounter = parseInt(this.userUploadedFileCounter) + 1;
    }
  }

  mapModalCloseModal() {
    this.setState({ mapModalOpen: false });
  }

  openMapModal() {
    this.setState({ mapModalOpen: true });
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onChangeNumberPositive(event) {
    if (event.target.value >= 0.0) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }

  onChangeConversion(event) {
    let { name, value } = event.target;
    let { conversionRate, conversionScale } = this.state;
    if (name === "conversionRate") {
      conversionRate = value;
      value = this.state[conversionScale];
    }
    if (name === "dailyProductionMcf") {
      conversionScale = "dailyProductionMcf";
    }
    if (name === "estimatedPoweCapacity") {
      conversionScale = "estimatedPoweCapacity";
    }
    const conversionResult = this.doConvert(value, conversionRate, conversionScale);
    this.setState({
      conversionRate,
      conversionScale,
      ...conversionResult
    });
  }

  doConvert(value, rate, scale) {
    return {
      dailyProductionMcf: scale === "dailyProductionMcf"? value: this.toDailyProductionMcf(value, rate),
      estimatedPoweCapacity: scale === "estimatedPoweCapacity"? value: this.toPowerCapacity(value, rate)
    };
  }

  toDailyProductionMcf(value, rate) {
    return value / this.getValidConversionRate(rate);
  }

  toPowerCapacity(value, rate) {
    return _.floor(value * this.getValidConversionRate(rate));
  }

  getValidConversionRate(value) {
    if (!value || value === "0" || isNaN(value)) {
      return DEFAULT_CONVERSION_RATE;
    }
    return value;
  }

  setMapStateValues(stateValues) {
    if (stateValues && !_.isUndefined(stateValues)) {
      let stateObj = {};
      if (stateValues.address) {
        stateObj.address = stateValues.address;
      }
      if (stateValues.city) {
        stateObj.city = stateValues.city;
      }
      if (stateValues.state) {
        stateObj.state = stateValues.state;
      }
      if (stateValues.area) {
        stateObj.area = stateValues.area;
      }
      this.setState(stateObj);
    }
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  async handleSelect(address) {
    if (address !== "") {
      let stateObj = {
        address,
      };
      const geocode = await geocodeByAddress(address);
      stateObj.googlePlaceAutoSuggestResponse = geocode;
      const place_id = geocode[0].place_id;
      const addressArray = geocode[0].address_components;
      const city = thisref.getCity(addressArray),
        area = thisref.getArea(addressArray),
        state = thisref.getState(addressArray);
      const latLng = await getLatLng(geocode[0]);
      if (place_id && place_id !== "") {
        stateObj.place_id = place_id;
      }
      if (area && area !== "") {
        stateObj.area = area;
      }
      if (city && city !== "") {
        stateObj.city = city;
      }
      if (state && state !== "") {
        stateObj.state = state;
      }
      if (latLng && latLng.lat && latLng.lng) {
        let temp = {
          lat: latLng.lat,
          lng: latLng.lng,
        };
        stateObj.markerPosition = temp;
        stateObj.mapPosition = temp;
      }
      thisref.setState(stateObj);
    }
  }

  setMapLatituteLongitude(lat, lng) {
    let stateObj = {};
    if (lat !== "" && lng !== "") {
      let temp = {
        lat,
        lng,
      };
      stateObj.markerPosition = temp;
      stateObj.mapPosition = temp;
      this.setState(stateObj);
    }
  }

  checkPaymentMehodSelected(fieldName) {
    let { acceptedPaymentMethods } = this.state;
    let checked =
      !_.isUndefined(acceptedPaymentMethods) &&
        _.includes(acceptedPaymentMethods, fieldName)
        ? true
        : false;
    return checked;
  }

  createNewListing() {
    const {
      dailyProductionMcf,
      estimatedPoweCapacity,
      powerPrice,
      oilTotalProvedReserves,
      oilTotalProvedReservesUnit,
      oilTotalProbableReserves,
      oilTotalProbableReservesUnit,
      oilTotalPossibleReserves,
      oilTotalPossibleReservesUnit,
      gasTotalProvedReserves,
      gasTotalProvedReservesUnit,
      gasTotalProbableReserves,
      gasTotalProbableReservesUnit,
      gasTotalPossibleReserves,
      gasTotalPossibleReservesUnit,
      markerPosition,
      address,
      acceptedPaymentMethods,
      requiredDocumentCounter,
      place_id,
      googlePlaceAutoSuggestResponse,
      city,
      area,
      state,
      powerPlantCapacity,
      powerPlantCapacityUnit,
      dateOnline,
      dateOffline,
    } = this.state;
    let listingDbId = this.props.match.params.id;
    const uploadedDocumentCounter = _.size(listingDocuments);

    if (dateOnline && dateOffline) {
      const startDate = moment(dateOnline);
      const endDate = moment(dateOffline);
      if (startDate.isAfter(endDate)) {
        return toast.error("Date offline must after date online");
      }
    }

    if (
      dailyProductionMcf !== "" &&
      oilTotalProvedReserves !== "" &&
      powerPrice !== "" &&
      /* oilTotalProbableReserves !== "" && */
      /* oilTotalPossibleReserves !== "" && */
      gasTotalProvedReserves !== "" &&
      /* gasTotalProbableReserves !== "" &&
      gasTotalPossibleReserves !== "" && */
      (this.userUploadedFileCounter >= requiredDocumentCounter ||
        requiredDocumentCounter <= uploadedDocumentCounter)
    ) {
      this.setState({ buttonLoading: true });
      let _this = this;
      const data = new FormData();

      if (listingDbId && listingDbId !== "" && listingDbId !== null) {
        data.append("listingId", listingDbId);
      }

      if (oilTotalProvedReserves) {
        data.append("oilTotalProvedReserves", oilTotalProvedReserves);
      }

      if (oilTotalProvedReservesUnit) {
        data.append("oilTotalProvedReservesUnit", oilTotalProvedReservesUnit);
      }

      if (oilTotalProbableReserves) {
        data.append("oilTotalProbableReserves", oilTotalProbableReserves);
      }

      if (oilTotalProbableReservesUnit) {
        data.append(
          "oilTotalProbableReservesUnit",
          oilTotalProbableReservesUnit
        );
      }

      if (oilTotalPossibleReserves) {
        data.append("oilTotalPossibleReserves", oilTotalPossibleReserves);
      }

      if (oilTotalPossibleReservesUnit) {
        data.append(
          "oilTotalPossibleReservesUnit",
          oilTotalPossibleReservesUnit
        );
      }

      if (gasTotalProvedReserves) {
        data.append("gasTotalProvedReserves", gasTotalProvedReserves);
      }

      if (gasTotalProvedReservesUnit) {
        data.append("gasTotalProvedReservesUnit", gasTotalProvedReservesUnit);
      }

      if (gasTotalProbableReserves) {
        data.append("gasTotalProbableReserves", gasTotalProbableReserves);
      }

      if (gasTotalProbableReservesUnit) {
        data.append(
          "gasTotalProbableReservesUnit",
          gasTotalProbableReservesUnit
        );
      }

      if (gasTotalPossibleReserves) {
        data.append("gasTotalPossibleReserves", gasTotalPossibleReserves);
      } else {
        data.append("gasTotalPossibleReserves", 0);
      }

      if (gasTotalPossibleReservesUnit) {
        data.append(
          "gasTotalPossibleReservesUnit",
          gasTotalPossibleReservesUnit
        );
      }

      if (dailyProductionMcf) {
        data.append("dailyProductionMcf", dailyProductionMcf);
        data.append("energySource", "Gas");
      }

      if (estimatedPoweCapacity) {
        data.append("estimatedPoweCapacity", estimatedPoweCapacity);
      }

      if (powerPrice) {
        data.append("powerPrice", powerPrice);
        data.append("powerPriceUnits", "dec");  // all new listings are priced in DEC
      }

      if (!_.isEmpty(listingDocuments)) {
        _.forEach(listingDocuments, function (value, key) {
          data.append(value.key, value.file);
          data.append(value.key + "_listingDocumentId", value.documentTypeId);
        });
      }

      if (markerPosition && markerPosition.lat) {
        data.append("latitude", markerPosition.lat);
      }

      if (markerPosition && markerPosition.lng) {
        data.append("longitude", markerPosition.lng);
      }

      if (address) {
        data.append("location", address);
      }

      if (place_id) {
        data.append("place_id", place_id);
      }

      if (googlePlaceAutoSuggestResponse) {
        data.append(
          "googlePlaceAutoSuggestResponse",
          googlePlaceAutoSuggestResponse
        );
      }

      if (city) {
        data.append("city", city);
      }

      if (state) {
        data.append("state", state);
      }

      if (area) {
        data.append("area", area);
      }

      if (acceptedPaymentMethods) {
        data.append("acceptedPaymentMethods", acceptedPaymentMethods);
      }

      if (powerPlantCapacity) {
        data.append("powerPlantCapacity", powerPlantCapacity);
      }

      if (powerPlantCapacityUnit) {
        data.append("powerPlantCapacityUnit", powerPlantCapacityUnit);
      }

      if (dateOnline) {
        data.append("dateOnline", dateOnline);
      }

      if (dateOffline) {
        data.append("dateOffline", dateOffline);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: auth.getToken(),
        },
      };
      axios
        .post(API_ROOT + "/supplier/listing/save", data, config)
        .then(function (response) {
          if (!_.isUndefined(response)) {
            _this.setState({
              buttonLoading: false,
            });
            if (response.data.code === 200) {
              toast.success(response.data.message);
              listingDocuments = [];
              var redirectUrl =
                response.data.data.updateFlag === true
                  ? "/campaigns"
                  : "/campaign/success";
              _this.props.history.push(redirectUrl);
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch(function (error) {
          _this.setState({
            buttonLoading: false,
          });
          console.log(error);
          toast.error(
            "Campaign details not saved successfully. Please try again later."
          );
        });
    } else {
      let errorMsg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      if (!dailyProductionMcf) {
        errorMsg =
          messageConstants.ENTER_VALID_AVERAGE_DAILY_PRODUCTION_BOE_ERROR_MSG;
      } else if (requiredDocumentCounter > uploadedDocumentCounter) {
        errorMsg = messageConstants.UPLOAD_ALL_FILES_ERROR_MSG;
      }
      toast.error(errorMsg);
    }
  }

  changePaymentTypes(e, checked, value) {
    let { acceptedPaymentMethods } = this.state;
    if (checked) {
      acceptedPaymentMethods.push(value);
      this.setState({
        acceptedPaymentMethods,
      });
    } else {
      var removePaymentMethodsInvested = {};
      removePaymentMethodsInvested = _.remove(
        acceptedPaymentMethods,
        function (n) {
          return n !== value;
        }
      );
      this.setState({
        acceptedPaymentMethods: removePaymentMethodsInvested,
      });
    }
  }

  render() {
    var _this = this;
    const {
      conversionRate,
      estimatedPoweCapacity,
      dailyProductionMcf,
      powerPrice,
      oilTotalProvedReserves,
      oilTotalProvedReservesUnit,
      oilTotalProbableReserves,
      oilTotalProbableReservesUnit,
      oilTotalPossibleReserves,
      oilTotalPossibleReservesUnit,
      gasTotalProvedReserves,
      gasTotalProvedReservesUnit,
      gasTotalProbableReserves,
      gasTotalProbableReservesUnit,
      gasTotalPossibleReserves,
      gasTotalPossibleReservesUnit,
      listingDocumentType,
      mapModalOpen,
      buttonLoading,
      listingFiles,
      objectIdRef,
      mapPosition,
      isFileUploaded,
      dateOnline,
      dateOffline,
    } = this.state;

    let listingDocumentsHtml = "";
    if (
      !_.isUndefined(listingDocumentType) &&
      !_.isEmpty(listingDocumentType)
    ) {
      listingDocumentsHtml = _.map(
        listingDocumentType,
        function (documentType) {
          return (
            <div
              className="col-lg-6 col-md-12 col-sm-12 file-upload-border"
              key={Math.random()}
            >
              <div className="mt-2">
                <div className="row">
                  <div className="col-md-9 col-lg-9 col-sm-9 col-xs-12 xs-margin fix-width">
                    {/* {objectIdRef &&
                      objectIdRef[documentType._id] &&
                      objectIdRef[documentType._id] !== "" && (
                      <i
                        className="fa fa-check filesuccess"
                        ref={(ref) => (fileIconRef[documentType.name] = ref)}
                      ></i>
                    )}
                    {(!isFileUploaded || (isFileUploaded && !isFileUploaded[documentType._id])) && (
                        <i
                          className="fa fa-times fileerror"
                          ref={(ref) => (fileIconRef[documentType.name] = ref)}
                        ></i>
                      )} */}

                    <i
                      className={`fa ${(objectIdRef &&
                        objectIdRef[documentType._id] &&
                        objectIdRef[documentType._id] !== "") ||
                        (isFileUploaded && isFileUploaded[documentType._id])
                        ? "fa-check filesuccess"
                        : "fa-times fileerror"
                        }`}
                      ref={(ref) => (fileIconRef[documentType.name] = ref)}
                    ></i>

                    <label className="pl-1 font-default font-small">
                      {documentType.label}{" "}
                      {documentType.required &&
                        documentType.required === true ? (
                        <span className="required-sign">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                  <div className="col-md-3 col-lg-3 col-sm-3 col-xs-12 d-flex xs-margin">
                    <button
                      className="upload-button cusor-pointer font-small"
                      onClick={(e) => fileRef[documentType.name].click()}
                    >
                      <span
                        className="button-container"
                        htmlFor="oil_gas_report"
                      >
                        Upload <i className="fa fa-paperclip ml-1 mt-1"></i>
                      </span>
                    </button>
                    {objectIdRef &&
                      objectIdRef[documentType._id] &&
                      objectIdRef[documentType._id] !== "" && (
                        <span className="pl-2 cusor-pointer">
                          <a
                            href={listingFiles[objectIdRef[documentType._id]]}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View Uploaded File"
                          >
                            <i className="fa fa-eye margintop-10"></i>
                          </a>
                        </span>
                      )}
                    <br />
                    <input
                      key={documentType.name}
                      className="listing_file"
                      type="file"
                      ref={(ref) => (fileRef[documentType.name] = ref)}
                      name={documentType.name}
                      onChange={(event) =>
                        _this.handleUploadFile(
                          event,
                          documentType.name,
                          documentType._id
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
      );
    }

    // if (!_.isUndefined(paymentMethodsList) && !_.isEmpty(paymentMethodsList)) {
    //     paymentMethodsHtml = _.map(paymentMethodsList, function (paymentMethod) {
    //         return (
    //             <Checkbox
    //                 checkboxClass="icheckbox_square-blue"
    //                 increaseArea="20%"
    //                 label={paymentMethod.title}
    //                 checked={_this.checkPaymentMehodSelected(paymentMethod.name)}
    //                 onChange={(e, checked) => _this.changePaymentTypes(e, checked, paymentMethod.name)}
    //                 key={Math.random()}
    //             />
    //         )
    //     });

    //     if (allowWireTransfer) {
    //         wireTransferHtml = <Checkbox
    //             checkboxClass="icheckbox_square-blue"
    //             increaseArea="20%"
    //             label="Wire Transfer"
    //             checked={_this.checkPaymentMehodSelected('WIRE_TRANSFER')}
    //             onChange={(e, checked) => _this.changePaymentTypes(e, checked, 'WIRE_TRANSFER')}
    //         />;
    //     }
    // }

    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="submit-listing-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="card-inner-padding dark-blue-theme-color buy-xpr-block">
                  <h5 className="mb-3 listing-header-title">Production Data</h5>
                  <div className="row">
                    {/* <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Average Daily Production in BBL
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1">
                          <input
                            className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                            type="text"
                            name="averageDailyProductionInBBL"
                            onChange={this.onChange}
                            value={
                              averageDailyProductionInBBL &&
                                averageDailyProductionInBBL !== ""
                                ? averageDailyProductionInBBL
                                : ""
                            }
                          />
                        </div>
                      </div> */}
                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Daily Production (MCF)
                          <span className="required-sign">*</span>
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="text"
                          name="dailyProductionMcf"
                          onChange={this.onChangeConversion}
                          value={dailyProductionMcf || ""}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Estimated Power Capacity (kWh)
                          <span className="required-sign">*</span>
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="text"
                          name="estimatedPoweCapacity"
                          onChange={this.onChangeConversion}
                          value={estimatedPoweCapacity || ""}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Conversion Rate
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="number"
                          name="conversionRate"
                          onChange={this.onChangeConversion}
                          value={conversionRate || ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Campaign Pricing (DEC/kWh)
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="number"
                          name="powerPrice"
                          onChange={this.onChangeNumberPositive}
                          value={powerPrice || ""}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <div>
                          <span className="listing-label">
                            Current USD/kWh Price (1 DEC == ${this.state.decPrice})
                          </span>
                        </div>
                        <div className="mt-1">
                          <span className="listing-label">
                            $ {Math.ceil(powerPrice * this.state.decPrice * 100.0) / 100.0 || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-inner-padding dark-blue-theme-color buy-xpr-block">
                    <h5 className="mb-3 text-xl">
                      <span>Oil Data</span>
                    </h5>
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Proved Reserves
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            id="fullName"
                            name="oilTotalProvedReserves"
                            onChange={this.onChange}
                            value={
                              oilTotalProvedReserves &&
                                oilTotalProvedReserves !== ""
                                ? oilTotalProvedReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="oilTotalProvedReservesUnit"
                            onChange={this.onChange}
                            value={oilTotalProvedReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Probable Reserves
                          </span>
                        </div>

                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            id="fullName"
                            name="oilTotalProbableReserves"
                            onChange={this.onChange}
                            value={
                              oilTotalProbableReserves &&
                                oilTotalProbableReserves !== ""
                                ? oilTotalProbableReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="oilTotalProbableReservesUnit"
                            onChange={this.onChange}
                            value={oilTotalProbableReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Possible Reserves
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="oilTotalPossibleReserves"
                            onChange={this.onChange}
                            value={
                              oilTotalPossibleReserves &&
                                oilTotalPossibleReserves !== ""
                                ? oilTotalPossibleReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="oilTotalPossibleReservesUnit"
                            onChange={this.onChange}
                            value={oilTotalPossibleReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <h5 className="mt-3 mb-3 listing-header-title">
                      <span>Gas Data</span>
                    </h5>

                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Proved Reserves
                            <span className="required-sign">*</span>
                          </span>
                        </div>

                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="gasTotalProvedReserves"
                            onChange={this.onChange}
                            value={
                              gasTotalProvedReserves &&
                                gasTotalProvedReserves !== ""
                                ? gasTotalProvedReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="gasTotalProvedReservesUnit"
                            onChange={this.onChange}
                            value={gasTotalProvedReservesUnit}
                          >
                            <option value="MCF">MCF</option>
                            <option value="MMCF">MMCF</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Probable Reserves
                          </span>
                        </div>

                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            id="fullName"
                            name="gasTotalProbableReserves"
                            onChange={this.onChange}
                            value={
                              gasTotalProbableReserves &&
                                gasTotalProbableReserves !== ""
                                ? gasTotalProbableReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="gasTotalProbableReservesUnit"
                            onChange={this.onChange}
                            value={gasTotalProbableReservesUnit}
                          >
                            <option value="MCF">MCF</option>
                            <option value="MMCF">MMCF</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Possible Reserves
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="gasTotalPossibleReserves"
                            onChange={this.onChange}
                            value={
                              gasTotalPossibleReserves &&
                                gasTotalPossibleReserves !== ""
                                ? gasTotalPossibleReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="gasTotalPossibleReservesUnit"
                            onChange={this.onChange}
                            value={gasTotalPossibleReservesUnit}
                          >
                            <option value="MCF">MCF</option>
                            <option value="MMCF">MMCF</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* <h5 className="color-white mt-3 mb-3 listing-header-title">
                      <span>NGL Data</span>
                    </h5>
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Proved Reserves
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="pl-2 listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="nglTotalProvedReserves"
                            onChange={this.onChange}
                            value={
                              nglTotalProvedReserves &&
                                nglTotalProvedReserves !== ""
                                ? nglTotalProvedReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="nglGasTotalProvedReservesUnit"
                            onChange={this.onChange}
                            value={nglGasTotalProvedReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Probable Reserves
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="pl-2 listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="nglGasTotalProbableReserves"
                            onChange={this.onChange}
                            value={
                              nglGasTotalProbableReserves &&
                                nglGasTotalProbableReserves !== ""
                                ? nglGasTotalProbableReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="nglGasTotalProbableReservesUnit"
                            onChange={this.onChange}
                            value={nglGasTotalProbableReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Total Possible Reserves
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="pl-2 listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="nglGasTotalPossibleReserves"
                            onChange={this.onChange}
                            value={
                              nglGasTotalPossibleReserves &&
                                nglGasTotalPossibleReserves !== ""
                                ? nglGasTotalPossibleReserves
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="nglTotalPossibleReservesUnit"
                            onChange={this.onChange}
                            value={nglTotalPossibleReservesUnit}
                          >
                            <option value="BBL">BBL</option>
                            <option value="MBBL">MBBL</option>
                            <option value="MMBBL">MMBBL</option>
                          </select>
                        </div>
                      </div>
                    </div> */}

                    {/* <h5 className="color-white mt-3 mb-3 listing-header-title">
                      <span>Gas to power data</span>
                    </h5>
                    <div className="row">
                      <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                        <div>
                          <span className="listing-label">
                            Power plant capacity
                            <span className="required-sign">*</span>
                          </span>
                        </div>
                        <div className="mt-1 d-flex">
                          <input
                            className="pl-2 listing-reserves-input dark-blue-font-color"
                            type="text"
                            name="powerPlantCapacity"
                            onChange={this.onChange}
                            value={
                              powerPlantCapacity && powerPlantCapacity !== ""
                                ? powerPlantCapacity
                                : ""
                            }
                          />
                          <select
                            className="buy-xpr-currencey-select unit-selector"
                            name="powerPlantCapacityUnit"
                            onChange={this.onChange}
                            value={powerPlantCapacityUnit}
                          >
                            <option value="KW">KW</option>
                            <option value="MW">MW</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card-inner-padding dark-blue-theme-color listing-files-container files-container">
                  <h5>
                    Upload Documents{" "}
                    <i
                      className="fa fa-info-circle"
                      id="uploadDocumentsInfo"
                    ></i>
                  </h5>
                  <UncontrolledPopover
                    trigger="hover"
                    placement="bottom"
                    target="uploadDocumentsInfo"
                  >
                    <PopoverBody>
                      These documents are required if you wish to register your
                      campaigns and aggregate your mineral assets into the
                      PermianChain network. By doing so you can receive access
                      to funding from potential joint venture partners.
                    </PopoverBody>
                  </UncontrolledPopover>
                  <div className="row  ">{listingDocumentsHtml}</div>
                </div>
              </div>
            </div>

            <div className="row mt-4 row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-6 location-autosuggest-container">
                <div className="card-inner-padding dark-blue-theme-color listing-files-container">
                  <h5>
                    <span className="pull-left">Location</span>
                    <span className="pull-right">
                      <button
                        className="mark-on-map"
                        onClick={() => this.openMapModal()}
                      >
                        Mark On Map
                      </button>
                    </span>
                  </h5>
                  <div className="clearfix"></div>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="autosuggest-container">
                        <input
                          {...getInputProps({
                            placeholder: "Search Place ..",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                backgroundColor: "#59ADDE",
                                cursor: "pointer",
                              }
                              : {
                                backgroundColor: "#59ADDE",
                                cursor: "pointer",
                              };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span className="mt-5">
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-12">
                <div className="card-inner-padding dark-blue-theme-color buy-xpr-block">
                  <h5 className="mb-3 listing-header-title">Date Online and Offline</h5>
                  <div className="row">
                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Date Online
                          {/*<span className="required-sign">*</span>*/}
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="date"
                          name="dateOnline"
                          onChange={this.onChange}
                          value={dateOnline || ""}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xl-4 mb-2 col-xs-12">
                      <div>
                        <span className="listing-label">
                          Date Offline
                          {/*<span className="required-sign">*</span>*/}
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          className="listing-reserves-input average-listing-input dark-blue-font-color width-90"
                          type="date"
                          name="dateOffline"
                          onChange={this.onChange}
                          value={dateOffline || ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="btn-center-group">
                  <button
                    className="btn btn-primary permian-button"
                    type="submit"
                    onClick={this.createNewListing}
                    disabled={buttonDisabled}
                  >
                    Submit{" "}
                    {buttonLoading && (
                      <i className="fa-spin fa fa-spinner text-white ml-1" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={mapModalOpen}
          onClose={this.mapModalCloseModal}
          classNames={{
            closeIcon: "pull-right cursor-pointer",
            modal: "listing-modal-container mapmarker-location",
          }}
          closeIconId="listingmapclose"
        >
          <div className="onboarding-modal">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="onboarding-slider-w">
                  <div className="onboarding-slide">
                    <div className="onboarding-content">
                      {/* <h4 className="onboarding-title text-center">Mark location on map by drag the marker</h4> */}
                      <div className="row">
                        <div className="col-md-12">
                          <Map
                            {...this.state}
                            {...this.props}
                            center={{
                              lat: mapPosition.lat,
                              lng: mapPosition.lng,
                            }}
                            height="400px"
                            zoom={15}
                            getState={this.getState}
                            getCity={this.getCity}
                            getArea={this.getArea}
                            setMapStateValues={this.setMapStateValues}
                            setMapLatituteLongitude={
                              this.setMapLatituteLongitude
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
export default NewListing;
