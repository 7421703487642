import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import Select from "react-select";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { toast } from "react-toastify";
import TransactionModal from "./TransactionModal";
import * as messageConstants from "../../utils/Messages";
import {
  niceNumberDecimalDisplay,
  tooltipNumberDisplay,
  ethAddressDisplay,
  sendTokens,
} from "../../utils/Util";

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
      name: "",
      email: "",
      totalSize: "",
      counter: 0,
      modalOpen: false,
      status: "",
      statusText: "",
      statusOptions: [
        {
          value: false,
          label: "Pending",
        },
        {
          value: true,
          label: "Completed",
        },
      ],
      ordersRenderFlag: false,
      ordersButtonLoading: false,
      ordersPage: 1,
      ordersSizePerPage: 10,
      ordersFilters: "",
      csvData: [],
      data: [],
    };
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  }
  onOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleStatusChange = (e) => {
    this.setState({ status: e.value, statusText: e.label });
  }
  componentDidMount() {
    document.title =
      messageConstants.TRANSACTIONS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    this.getRecords();
  }

  filterRecords = () => {
    this.getRecords();
  }
  clearFilterRecords = () => {
    this.setState(
      {
        name: "",
        email: "",
        status: "",
        statusText: "",
      },
      () => {
        this.getRecords();
      }
    );
  }

  onchange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  sendTokens = async (listedTokenContractAddress, receivingEthAddress, tokens) => {
    await window.ethereum.enable();

    if (listedTokenContractAddress && receivingEthAddress && tokens) {
      sendTokens(listedTokenContractAddress, receivingEthAddress, tokens);
    } else {
      const erroMsg = !receivingEthAddress
        ? "User's wallet address is not whitelisted"
        : !tokens || tokens <= 0
        ? "Please enter a valid token."
        : "";
      toast.error(erroMsg);
    }
  }

  getAllData = () => {
    try {
      const { totalSize } = this.state;
      const api = new Api();
      let authenticationToken = this.props.authToken;
      api
        .setToken(authenticationToken)
        .get("supplier/token/transaction/history", {
          page: 1,
          sizePerPage: totalSize,
        })
        .then(async (response) => {
          let transactionArr = [];
          if (response.code === 200) {
            if (
              response.data &&
              response.data.transactions &&
              response.data.transactions.data
            ) {
              response.data.transactions.data.map((data) => {
                let transactionData = {};
                transactionData.txnId = data.txnId;
                transactionData.userName = data.userId && data.userId.fullName;
                transactionData.userEmail = data.userId &&  data.userId.email;
                transactionData.transactionDate = data.txDate
                  ? moment(data.txDate).format("YYYY-MM-DD HH:mm")
                  : "";
                transactionData.amountSent = `${niceNumberDecimalDisplay(
                  data.amountRecieve,
                  4
                )} ${data.transactionType}`;
                transactionData.tokens = `${
                  data.coins ? niceNumberDecimalDisplay(data.coins, 2) : ""
                } ${data.issuerId.listedTokenSymbol}`;

                let usdAmount =
                  data.transactionType !== "MANUAL" &&
                  data.transactionType !== "CARD"
                    ? parseFloat(data.amountRecieve) *
                      parseFloat(data.cryptoLiveRate)
                    : data.amountRecieve;
                transactionData.contributedAmount = `$ ${niceNumberDecimalDisplay(
                  usdAmount,
                  2
                )}`;
                transactionData.depositAddress = data.depositeAddress;
                transactionData.status =
                  data.tokenSentStatus === true ? "Completed" : "Pending";
                transactionArr.push(transactionData);
              });
            }
            this.setState({
              csvData: transactionArr,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  getRecords = async () => {
    const api = new Api();
    let response;
    const { sizePerPage, page, name, email, status } = this.state;
    const authenticationToken = this.props.authToken;
    try {
      response = await api
        .setToken(authenticationToken)
        .get("supplier/token/transaction/history", {
          name: name,
          email: email,
          status: status,
          sizePerPage: sizePerPage,
          page: page,
        });

      if (response.code === 200 && !_.isEmpty(response.data.transactions)) {
        this.setState(
          {
            renderFlag: true,
            data: response.data.transactions.data,
            counter: this.state.counter + 1,
            totalSize: response.data.transactions.total,
            buttonLoading:false
          },
          () => {
            if (this.state.counter == 1) {
              this.getAllData();
            }
          }
        );
      } else {
        this.setState({
          renderFlag: true,
          data: [],
        });
      }
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("remove");
      }
    } catch (e) {
      if (typeof this.props.pageProgress === "function") {
        this.props.pageProgress("force_remove");
      }
      this.setState({
        renderFlag: true,
        data: [],
        buttonLoading:false,
        totalSize: 0,
      });
    }
  }

  completeTransaction = async (transactionId,tokens,userId) => {
    this.setState({buttonLoading:true})
    const id = userId && userId._id;
    const authenticationToken = this.props.authToken;
    const api = new Api();
    await api
      .setToken(authenticationToken)
      .create("user/issuer/token/completed", {
        transactionId,
        amount:tokens,
        supplierUserId:id
      });
    toast.success("Transaction completed successfully.");
    this.getRecords();
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const {
      data,
      statusOptions,
      sizePerPage,
      page,
      renderFlag,
      name,
      modalOpen,
      email,
      buttonLoading,
      status,
      csvData,
    } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "txnId",
        text: "Txn Id",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "fullName",
        text: "User Name",
        sort: true,
        formatter: function (cell, row) {
          return (
            <div className="text-left">
              <div>{row.userId && row.userId.fullName}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "userId",
        text: "User Email",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell ? cell.email : ""}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "CreatedOnUTC",
        text: "Transaction Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div> {moment(cell).format("YYYY-MM-DD HH:mm")}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountRecieve",
        text: "Amount Sent",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              <span
                className="custom-tooltip"
                tooltip-title={tooltipNumberDisplay(
                  row.amountRecieve,
                  row.transactionType
                )}
              >
                {niceNumberDecimalDisplay(cell, 4)} {row.transactionType}
              </span>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "coins",
        text: `Tokens`,
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {niceNumberDecimalDisplay(cell, 2)}{" "}
              {row.issuerId.listedTokenSymbol}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "usdAmount",
        text: "Contributed Amount",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let usdAmount =
            row.transactionType !== "MANUAL" && row.transactionType !== "CARD"
              ? parseFloat(row.amountRecieve) * parseFloat(row.cryptoLiveRate)
              : row.amountRecieve;
          return <div>${niceNumberDecimalDisplay(usdAmount, 2)}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "depositeAddress",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.transactionType !== "MANUAL" && (
                <span>{ethAddressDisplay(cell)}</span>
              )}
              {row.transactionType === "MANUAL" && <span>N/A</span>}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        headerAlign: 'center',
        dataField: "detail",
        text: "Detail",
        style: {
          minWidth: 200
        },
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {row.paymentFileUrl && (
                <div>
                  <a href={row.paymentFileUrl} target="_blank">
                    View Payment Proof
                  </a>
                </div>
              )}
              {row.subscriptionAgreementFileUrl && (
                <div>
                  <a href={row.subscriptionAgreementFileUrl} target="_blank">
                    View Subscription Agreement
                  </a>
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "status",
        text: "Status",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.tokenSentStatus === true) {
            label = "Completed";
            className = "badge-success";
          }
          return (
            <div>
              <div className="text-left">
                <div className="d-inline-block">
                  <span className={"badge"}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "_id",
        text: "Operations",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div>
              {!row.tokenSentStatus && (
                <div className="d-flex">
                  <button
                    className="btn default-border-btn"
                    onClick={() =>
                      _this.sendTokens(
                        row.issuerId.listedTokenContractAddress,
                        row.userId.receivingEthAddress,
                        row.coins
                      )
                    }
                  >
                    Issue
                  </button>
                  <button
                    className="btn default-border-btn"
                    disabled={buttonLoading}
                    onClick={() => _this.completeTransaction(row._id,row.coins,row.userId)}
                  >
                    Complete
                    {buttonLoading && (
                  <i className="fa-spin fa fa-spinner ml-1" />
                )}
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box">
              <h5 className="form-header">Filter Transactions</h5>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      type="text"
                      onChange={this.onchange}
                      value={name}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <input
                      className="form-control "
                      placeholder="Email Address"
                      type="text"
                      name="email"
                      id="email"
                      onChange={this.onchange}
                      value={email}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <Select
                      className="country-select2"
                      classNamePrefix=""
                      options={statusOptions}
                      placeholder=""
                      name="address1CountryId"
                      id="address1CountryId"
                      onChange={this.handleStatusChange}
                      value={{
                        label: this.state.statusText || "Filter Status",
                        value: status,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="userList-btn-group">
                <button
                  className="btn default-btn"
                  type="button"
                  onClick={this.filterRecords}
                >
                  {" "}
                  Filter
                </button>
                <button
                  className="btn default-border-btn ml-2"
                  type="button"
                  onClick={this.clearFilterRecords}
                >
                  {" "}
                  Clear
                </button>
              </div>
            </div>

            <div className="element-wrapper mt-4">
              <div className="element-box">
                <div className="row justify-content-between mb-3">
                  <h5 className="text-xl">Transactions</h5>
                  <button
                    className="btn default-btn"
                    onClick={this.onOpenModal}
                  >
                    Export
                  </button>
                </div>
                <TransactionModal
                  modalOpen={modalOpen}
                  csvData={csvData}
                  onCloseModal={this.onCloseModal}
                />
                <div className="clearfix"></div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Transactions;
