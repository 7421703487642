// let backendHost = 'http://localhost:3000';
// let recaptchaSiteKey = '6LdTt5IUAAAAAHr8LILfR5h6e6PDmsqfxYHHed4K';
// let googleMapKeyValue = 'AIzaSyC7DsgoUfC1dO2Heqyl5X36-ARrlYk5i3U';

let backendHost = "http://localhost:3000";
// let backendHost = "https://api.permianchain.com";
let recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
let googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";

if (process.env.REACT_APP_NODE_ENV === "production") {
    backendHost = "https://masterapi.permianchain.com";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
    // backendHost = "http://localhost:3000";
    backendHost = "https://api.permianchain.com";
    recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
    googleMapKeyValue = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
}

export const API_ROOT = `${backendHost}`;
export const RECAPTCHASITEKEY = `${recaptchaSiteKey}`;
export const GOOGLE_MAP_KEY = googleMapKeyValue;
export const IMAGE_VERSION = 3;
export const tokenSymbol = "NRT";