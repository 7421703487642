/* eslint-disable no-template-curly-in-string */
import React, { Component } from "react";
import auth from "../../utils/auth";
import _ from "lodash";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Api from "../../services/api";
import { niceNumberDecimalDisplay, fetchTokenDetails } from "../../utils/Util";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";
import { API_ROOT } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myXPRBalance: "",
      myXPRUsdValue: "",
      myTransactions: "",
      totalTransaction: "",
      transactionLoading: true,
      popoverOpen: false,
      netPresentValueInformation: "Company's net present value in USD",
      tokenSymbol: this.props.activeTokenName ? this.props.activeTokenName : "",
      netPresentValue: "",
      displayBlurAnalytics: false,
      displayBlurForecastDetails: false,
      displayNetPresentValueDetails: false,
      listedTokenDummyForecastDetails: {
        disclaimer: "Graph Disclaimer",
        forecastTitle: "Token Forecast",
        listedTokenForecastPrimary0Value: 275000,
        listedTokenForecastPrimary1Value: 2220000,
        listedTokenForecastPrimary2Value: 5553570,
        listedTokenForecastPrimary3Value: 10181261,
        listedTokenForecastPrimary4Value: 25106746,
        listedTokenForecastPrimary5Value: 47139456,
        listedTokenForecastSecondary0Value: 575000,
        listedTokenForecastSecondary1Value: 4420000,
        listedTokenForecastSecondary2Value: 8553570,
        listedTokenForecastSecondary3Value: 30181261,
        listedTokenForecastSecondary4Value: 55106746,
        listedTokenForecastSecondary5Value: 87139456,
        listedTokenForecastTimeUnit0Price: 0.08,
        listedTokenForecastTimeUnit1Price: 0.1,
        listedTokenForecastTimeUnit2Price: 0.13,
        listedTokenForecastTimeUnit3Price: 0.18,
        listedTokenForecastTimeUnit4Price: 0.22,
        listedTokenForecastTimeUnit5Price: 0.4,
        primaryUnitTitle: "NET OIL RESERVE GROWTH",
        primaryUnitTooltipTitle: "Net oil reserves per year",
        secondaryUnitTitle: "NET ENERGY RESERVE GROWTH",
        secondaryUnitTooltipTitle: "Net energy reserves per year",
        tokenPriceYaxisTitle: "Token Price",
        xAxisTimeUnit: "Year",
        yAxisMainTitle: "NET OIL/ENERGY RESERVES GROWTH",
      },
    };
  }

  prepareGraphData() {
    const { tokenSymbol, listedTokenDummyForecastDetails } = this.state;
    let { listedTokenForecastDetails } = this.state;
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    if (!listedTokenForecastDetails) {
      listedTokenForecastDetails = listedTokenDummyForecastDetails;
    }
    if (listedTokenForecastDetails) {
      const chartDataArray = [];
      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit0Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary0Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 0`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit0Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary0Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary0Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit1Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary1Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 1`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit1Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary1Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary1Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit2Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary2Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 2`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit2Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary2Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary2Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit3Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary3Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 3`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit3Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary3Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary3Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit4Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary4Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 4`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit4Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary4Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary4Value,
        });
      }

      if (
        listedTokenForecastDetails.listedTokenForecastTimeUnit5Price &&
        listedTokenForecastDetails.listedTokenForecastSecondary5Value
      ) {
        chartDataArray.push({
          year: `${listedTokenForecastDetails.xAxisTimeUnit} 5`,
          tokenPrice:
            listedTokenForecastDetails.listedTokenForecastTimeUnit5Price,
          oilReserves:
            listedTokenForecastDetails.listedTokenForecastPrimary5Value,
          energyReserve:
            listedTokenForecastDetails.listedTokenForecastSecondary5Value,
        });
      }

      chart.data = chartDataArray;

      let categoryAxis1 = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis1.cursorTooltipEnabled = false;
      categoryAxis1.dataFields.category = "year";
      categoryAxis1.renderer.minGridDistance = 10;

      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = `${listedTokenForecastDetails.yAxisMainTitle}`;

      var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = listedTokenForecastDetails.tokenPriceYaxisTitle;
      valueAxis2.renderer.opposite = true;
      valueAxis2.renderer.grid.template.disabled = true;

      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "oilReserves";
      series1.dataFields.categoryX = "year";
      series1.yAxis = valueAxis1;
      series1.name = listedTokenForecastDetails.primaryUnitTitle;
      series1.tooltipText = "{name}\n[bold font-size: 14]{valueY}[/]";
      series1.tooltip.label.fontSize = "12";
      series1.fill = chart.colors.getIndex(0);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.columns.template.width = am4core.percent(12);

      var series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = "energyReserve";
      series2.dataFields.categoryX = "year";
      series2.yAxis = valueAxis1;
      series2.name = listedTokenForecastDetails.secondaryUnitTitle;
      series2.tooltipText = "{name}\n[bold font-size: 14]{valueY}";
      series2.tooltip.label.fontSize = "12";
      series2.fill = chart.colors.getIndex(0).lighten(0.5);
      series2.columns.template.width = am4core.percent(24);

      series2.strokeWidth = 0;
      series2.clustered = false;
      series2.toBack();

      var series3 = chart.series.push(new am4charts.LineSeries());
      series3.id = "g3";
      series3.dataFields.valueY = "tokenPrice";
      series3.dataFields.categoryX = "year";
      series3.name = listedTokenForecastDetails.priceUnitTitle
        ? listedTokenForecastDetails.priceUnitTitle
        : `${tokenSymbol} Price`;
      series3.strokeWidth = 2;
      series3.tensionX = 0.7;
      series3.yAxis = valueAxis2;
      series3.tooltipText = "{name}\n[bold font-size: 14]{valueY}[/]";
      series3.tooltip.label.fontSize = "12";
      var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.circle.radius = 3;
      bullet3.circle.strokeWidth = 2;
      bullet3.circle.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();

      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = categoryAxis1;

      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = am4core.color("#000");
      chart.cursor.lineX.fillOpacity = 0.1;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";

      this.chart = chart;
    } else {
      this.setState({ displayBlurForecastDetails: true });
    }
  }

  async componentDidMount() {
    this.setXprUnitPrice();
    this.getTokenDetails();
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    let authUserInfo = this.props.authUserInfo;
    let kycVerifiedResponse = await this.props.checkKycVerified();
    if (kycVerifiedResponse.status === true) {
      const api = new Api();
      if (
        !_.isUndefined(authUserInfo) &&
        authUserInfo !== null &&
        authUserInfo !== "" &&
        authUserInfo.isKycVerified === true &&
        !_.isUndefined(authUserInfo.appovedScreenViwed) &&
        authUserInfo.appovedScreenViwed === false
      ) {
        const response = await api.create("appvoedKycScreenViwed", {
          authenticationToken: authenticationToken,
        });
        if (response.status === "success") {
          let userInfo = {};
          let authUserInfo = this.props.authUserInfo;
          userInfo.fullName = authUserInfo.fullName;
          userInfo.userDisplayName = authUserInfo.userDisplayName;
          userInfo.email = authUserInfo.email;
          userInfo.isKycVerified = true;
          userInfo.appovedScreenViwed = true;
          auth.setUserInfo(userInfo);
        }
      }
    }
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("force_remove");
    }
  }

  async getTokenDetails() {
    const api = new Api();
    let authenticationToken = this.props.authToken;
    const response = await api
      .setToken(authenticationToken)
      .get("user/my/token/details");
    if (response) {
      const responseData =
        response.data && response.data["tokens"]
          ? response.data["tokens"][0]
          : "";

      if (!responseData) {
        const state2Object = {
          displayBlurAnalytics: true,
          displayBlurForecastDetails: true,
          displayNetPresentValueDetails: true,
        };
        this.setState(state2Object, () => {
          this.prepareGraphData();
        });
        return "";
      }

      let tokenSymbol = responseData.listedTokenSymbol;
      let tokenPrice = parseFloat(responseData.listedTokenPrice);

      let listedTokenFloating = responseData.listedTokenFloating;
      listedTokenFloating = niceNumberDecimalDisplay(listedTokenFloating, 0);
      let authorizedToken = responseData.listedTokenAuthorized;
      authorizedToken = parseFloat(authorizedToken);

      let authorizedTokenValue = parseFloat(authorizedToken);
      authorizedTokenValue = authorizedTokenValue * tokenPrice;
      authorizedTokenValue = niceNumberDecimalDisplay(authorizedTokenValue, 2);

      let floatingToken = responseData.listedTokenFloating;
      floatingToken = parseFloat(floatingToken);

      let floatingTokenValue = parseFloat(floatingToken);
      floatingTokenValue = floatingTokenValue * tokenPrice;
      floatingTokenValue = niceNumberDecimalDisplay(floatingTokenValue, 2);

      let unissuedToken = responseData.listedTokenUnIssued;
      unissuedToken = parseFloat(unissuedToken);

      let unissuedTokenValue = parseFloat(unissuedToken);
      unissuedTokenValue = unissuedTokenValue * tokenPrice;
      unissuedTokenValue = niceNumberDecimalDisplay(unissuedTokenValue, 2);

      let outstandingToken = responseData.listedTokenOutstanding;
      outstandingToken = parseFloat(outstandingToken);

      let outstandingTokenValue = parseFloat(outstandingToken);
      outstandingTokenValue = outstandingTokenValue * tokenPrice;
      outstandingTokenValue = niceNumberDecimalDisplay(
        outstandingTokenValue,
        2
      );
      authorizedToken = niceNumberDecimalDisplay(authorizedToken, 0);
      floatingToken = niceNumberDecimalDisplay(floatingToken, 0);
      unissuedToken = niceNumberDecimalDisplay(unissuedToken, 0);
      outstandingToken = niceNumberDecimalDisplay(outstandingToken, 0);

      let listedTokenTotalOfferings = responseData.listedTokenTotalOfferings;
      listedTokenTotalOfferings = parseFloat(listedTokenTotalOfferings);

      let listedTokenTotalOfferingsValue =
        parseFloat(listedTokenTotalOfferings) * tokenPrice;
      listedTokenTotalOfferings = niceNumberDecimalDisplay(
        listedTokenTotalOfferings,
        2
      );
      listedTokenTotalOfferingsValue = niceNumberDecimalDisplay(
        listedTokenTotalOfferingsValue,
        2
      );
      const listedTokenIssuedFromContract = await fetchTokenDetails(
        responseData.listedTokenContractAddress,
        true
      );
      let listedTokenIssued = listedTokenIssuedFromContract && listedTokenIssuedFromContract > 0
        ? listedTokenIssuedFromContract
        : responseData.listedTokenIssued;
      listedTokenIssued = parseFloat(listedTokenIssued || 0);

      let listedTokenIssuedValue = parseFloat(listedTokenIssued) * tokenPrice;

      let listedTokenOutstandingTokens =
        responseData.listedTokenOutstandingTokens;
      listedTokenOutstandingTokens = parseFloat(listedTokenOutstandingTokens);

      let listedTokenOutstandingTokensValue =
        parseFloat(listedTokenOutstandingTokens) * tokenPrice;
      listedTokenOutstandingTokensValue = niceNumberDecimalDisplay(
        listedTokenOutstandingTokensValue,
        2
      );

      listedTokenOutstandingTokens = niceNumberDecimalDisplay(
        listedTokenOutstandingTokens,
        2
      );

      let enterpriceValueDetails =
        (listedTokenIssued +
          parseFloat(responseData.listedTokenOutstandingTokens)) *
        tokenPrice;

      enterpriceValueDetails = niceNumberDecimalDisplay(
        enterpriceValueDetails,
        2
      );

      listedTokenIssuedValue = niceNumberDecimalDisplay(
        listedTokenIssuedValue,
        2
      );
console.log("enterpriceValueDetails",enterpriceValueDetails)
      const enterpriceValue = `$ ${enterpriceValueDetails}`;

      let stateObj = {
        tokenSymbol,
        tokensUnderEmd: true,
        tokenPrice,
        listedTokenFloating,
        authorizedToken,
        authorizedTokenValue,
        floatingToken,
        floatingTokenValue,
        unissuedToken,
        unissuedTokenValue,
        outstandingToken,
        outstandingTokenValue,
        listedTokenForecastDetails: responseData.listedTokenForecastDetails,
        displayBlurForecastDetails: !responseData.listedTokenForecastDetails,
        listedTokenTotalAmountRaised: responseData.listedTokenTotalAmountRaised,
        listedTokenTotalOfferings,
        listedTokenTotalOfferingsValue,
        listedTokenIssued,
        listedTokenIssuedValue,
        listedTokenOutstandingTokens,
        listedTokenOutstandingTokensValue,
        enterpriceValue,
        netPresentValue:
          responseData && responseData.listedTokenNetPresentValue
            ? niceNumberDecimalDisplay(
                responseData.listedTokenNetPresentValue,
                0
              )
            : "",
      };
      if (stateObj) {
        this.setState(stateObj, () => {
          if (typeof this.props.pageProgress === "function") {
            this.props.pageProgress("remove");
          }
          this.prepareGraphData();
          this.getTokenBalanceDetails();
        });
      }
    }
  }

  async getTokenBalanceDetails() {
    const { tokenSymbol, tokenPrice } = this.state;
    if (tokenSymbol) {
      const api = new Api();
      let authenticationToken = this.props.authToken;
      const response = await api
        .setToken(authenticationToken)
        .get("user/token/balance", { tokenSymbol });
      if (response) {
        const responseData = response.data;
        let tokenUsdValue = responseData.tokenBalance
          ? parseFloat(responseData.tokenBalance) * parseFloat(tokenPrice)
          : 0;
        if (tokenUsdValue) {
          tokenUsdValue = niceNumberDecimalDisplay(tokenUsdValue, 2);
        }
        let stateObj = {
          tokenBalance: responseData.tokenBalance
            ? niceNumberDecimalDisplay(responseData.tokenBalance, 2)
            : 0,
          myTransactions: responseData.transactions,
          tokenUsdValue,
          totalTransaction: _.size(responseData.transactions),
        };
        if (stateObj) {
          this.setState(stateObj);
        }
      }
    }
  }

  async setXprUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  render() {
    const {
      tokenSymbol,
      unissuedToken,
      unissuedTokenValue,
      displayBlurForecastDetails,
      listedTokenDummyForecastDetails,
      displayBlurAnalytics,
      listedTokenTotalOfferings,
      listedTokenTotalOfferingsValue,
      listedTokenIssued,
      listedTokenIssuedValue,
      listedTokenOutstandingTokens,
      listedTokenOutstandingTokensValue,
      listedTokenTotalAmountRaised,
      tokenPrice,
      enterpriceValue,
      btcUnitUsdPrice,
    } = this.state;

    let { listedTokenForecastDetails } = this.state;
    if (!displayBlurForecastDetails || !tokenSymbol) {
      listedTokenForecastDetails = listedTokenDummyForecastDetails;
    }

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row">
              <div className="col-sm-12">
                <div className="text-center p-3 dark-blue-theme-color buy-xpr-header-block">
                  {" "}
                  {enterpriceValue && (
                    <span>
                      Enterprise Value:{" "}
                      <span className="pl-2"> {enterpriceValue} </span>{" "}
                      <span style={{ marginLeft: "10px" }}> | </span>{" "}
                    </span>
                  )}{" "}
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    Amount Raised: {niceNumberDecimalDisplay(listedTokenTotalAmountRaised,2)}{" "}
                  </span>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div className={`row mt-4`}>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className={`dark-blue-theme-color ${
                    displayBlurAnalytics ? "filter-blur" : ""
                  }`}
                >
                  <div className="text-center dashboard-statistics-container card-padding">
                    <h5 className="dashboard-header"> Offering </h5>{" "}
                    <div className="barrel-display">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 ABC </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div className="color-black">
                          {" "}
                          {listedTokenTotalOfferings} {tokenSymbol}{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                    <div className="worth-highlighter">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 USD </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div>
                          {" "}
                          $ {listedTokenTotalOfferingsValue}
                          USD{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4 mgtp-md-4">
                <div
                  className={`dark-blue-theme-color ${
                    displayBlurAnalytics ? "filter-blur" : ""
                  }`}
                >
                  <div className="text-center dashboard-statistics-container card-padding">
                    <h5 className="dashboard-header"> Issued </h5>{" "}
                    <div className="barrel-display">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 ABC </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div className="color-black">
                          {" "}
                          {listedTokenIssued} {tokenSymbol}{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                    <div className="worth-highlighter">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 USD </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div>
                          {" "}
                          $ {listedTokenIssuedValue}
                          USD{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4">
                <div
                  className={`dark-blue-theme-color ${
                    displayBlurAnalytics ? "filter-blur" : ""
                  }`}
                >
                  <div className="text-center dashboard-statistics-container card-padding">
                    <h5 className="dashboard-header"> Outstanding </h5>{" "}
                    <div className="barrel-display">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 ABC </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div className="color-black">
                          {" "}
                          {listedTokenOutstandingTokens} {tokenSymbol}{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                    <div className="worth-highlighter">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 USD </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div>
                          {" "}
                          $ {listedTokenOutstandingTokensValue}
                          USD{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mgtp-sm-4 mgtp-md-4">
                <div
                  className={`dark-blue-theme-color ${
                    displayBlurAnalytics ? "filter-blur" : ""
                  }`}
                >
                  <div className="text-center dashboard-statistics-container card-padding">
                    <h5 className="dashboard-header"> Token Price </h5>{" "}
                    <div className="barrel-display">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 0.05 {tokenSymbol} </div>
                      )}{" "}
                      {!displayBlurAnalytics && <div> $ {niceNumberDecimalDisplay(tokenPrice,2)} </div>}{" "}
                    </div>{" "}
                    <div className="worth-highlighter">
                      {" "}
                      {displayBlurAnalytics && (
                        <div> 455, 555, 666 USD </div>
                      )}{" "}
                      {!displayBlurAnalytics && (
                        <div>
                          {" "}
                          {niceNumberDecimalDisplay(
                            tokenPrice / btcUnitUsdPrice,
                            8
                          )}{" "}
                          BTC{" "}
                        </div>
                      )}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </div>
            <div className="row mgtp-lg-4 mgtp-xl-4 mgtp-md-4 mgtp-xs-4 mgtp-sm-4">
              <div className="col-md-12">
                <div
                  className={`card-inner-padding graph-inner-padding dark-blue-theme-color ${
                    displayBlurForecastDetails ? "filter-blur" : ""
                  }`}
                >
                  <h6 className="xpr-chart-header">
                    {" "}
                    {tokenSymbol}{" "}
                    {listedTokenForecastDetails
                      ? listedTokenForecastDetails.forecastTitle
                      : "Forecast"}{" "}
                  </h6>{" "}
                  <hr className="bg-white" />
                  <div className="highchart mt-2">
                    <div
                      id="chartdiv"
                      style={{ width: "100%", height: "500px" }}
                    ></div>{" "}
                    {listedTokenForecastDetails &&
                      listedTokenForecastDetails.disclaimer && (
                        <div className="disclaimer-text">
                          {" "}
                          {listedTokenForecastDetails.disclaimer}{" "}
                        </div>
                      )}{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}
export default Dashboard;
