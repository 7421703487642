import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
import { niceNumberDecimalDisplay } from "../../utils/Util";

class ListingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingDetails: {},
      kycDocumentTypeListTypes: "",
      listingFiles: "",
      objectIdRef: "",
    };
  }

  async componentDidMount() {
    const api = new Api();
    document.title =
      messageConstants.LISTING_DETAIL_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    if (typeof this.props.pageProgress === "function") {
      this.props.pageProgress("display");
    }
    let authenticationToken = this.props.authToken;
    let listingId = this.props.match.params.id;
    if (!_.isUndefined(listingId)) {
      this.state.decPrice = await this.getPriceDEC();
      try {
        const response = await api
          .setToken(authenticationToken)
          .get("supplier/listing/details", { listingId: listingId });
        if (response.code === 200) {
          this.setState(
            {
              listingDetails: response.data.listingDetail,
              documentTypeListTypes: response.data.documentTypeListTypes,
              listingFiles: response.data.listingFiles,
              objectIdRef: response.data.objectIdRef,
            },
            async () => {
              if (typeof this.props.pageProgress === "function") {
                this.props.pageProgress("remove");
              }
            }
          );
        }
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("force_remove");
        }
      } catch (error) {
        if (typeof this.props.pageProgress === "function") {
          this.props.pageProgress("remove");
        }
      }
    }
    this.props.pageProgress("remove");
  }

  async getPriceDEC() {
    let decPrice = 0.05;
    const api = new Api();
    const priceResponse = await api
      .setToken(this.props.authToken)
      .get("supplier/stripePrice", {
        type: "DEC",
      });
    if (
      priceResponse.code === 200 &&
      priceResponse.data
    ) {
      if (priceResponse.data.stripePrice) {
        decPrice = Number(priceResponse.data.stripePrice.unitPrice);
      } else {
        decPrice = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get DEC price, using default $0.05");
    }
    return decPrice;
  }

  render() {
    const { listingDetails, documentTypeListTypes, listingFiles, objectIdRef } =
      this.state;
    let displayDocumentTypes = "";
    var date =
      listingDetails && listingDetails.createdOnUTC
        ? moment(listingDetails.createdOnUTC).format("DD MMM YYYY")
        : "";
    var usdPrice = listingDetails.powerPrice;
    var decPrice = 0.0;
    // Handle legacy USD pricing and unset entries
    if (listingDetails.powerPrice) {
      if (listingDetails.powerPriceUnits &&
          listingDetails.powerPriceUnits == "dec") {
            usdPrice = Math.ceil(listingDetails.powerPrice * this.state.decPrice);
            decPrice = listingDetails.powerPrice;
      } else {
        // Legacy, fixed DEC price
        usdPrice = listingDetails.powerPrice;
        decPrice = Math.ceil(listingDetails.powerPrice / 0.05);
      }
    } else {
      usdPrice = "unset";
      decPrice = "unset";
    }
    if (
      !_.isUndefined(documentTypeListTypes) &&
      !_.isEmpty(documentTypeListTypes)
    ) {
      displayDocumentTypes = documentTypeListTypes.map(
        (parentDocumentType, mainIndex) => (
          <div className="col-md-6" key={mainIndex}>
            {objectIdRef[parentDocumentType._id] && (
              <div className="mt-2">
                {parentDocumentType.label} :{" "}
                <a
                  href={
                    listingFiles[
                      objectIdRef[parentDocumentType.children[0]._id][0]
                    ]
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </div>
            )}
          </div>
        )
      );
    }

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            {/* <h2 className="text-center mb-4">
              Campaign{" "}
              {listingDetails && listingDetails.listingId
                ? "#" + listingDetails.listingId
                : ""}{" "}
              Details
            </h2> */}
            <div className="element-wrapper">
              <div className="element-box">
                <div>
                  <h6 className="element-header">
                    <div>
                      <span>General Details</span>
                      <Link to="/campaigns" className="pull-right">
                        Back to Campaigns
                      </Link>
                    </div>
                  </h6>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Daily Production (MCF) :{" "}
                        {listingDetails.dailyProductionMcf}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Total Estimated Power Capacity (KWH) :{" "}
                        {niceNumberDecimalDisplay(listingDetails.initialEstimatedPowerCapacity, 14)}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Oil total Proved Reserves :{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Oil total Probable Reserves :{" "}
                        {listingDetails.oilTotalProbableReserves}{" "}
                        {listingDetails.oilTotalProbableReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Oil total Proved Reserves :{" "}
                        {listingDetails.oilTotalProvedReserves}{" "}
                        {listingDetails.oilTotalProvedReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Gas total Proved Reserves :{" "}
                        {listingDetails.gasTotalProvedReserves}{" "}
                        {listingDetails.gasTotalProvedReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Gas total Probable Reserves :{" "}
                        {listingDetails.gasTotalProbableReserves}{" "}
                        {listingDetails.gasTotalProbableReservesUnit}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Gas total Possible Reserves :{" "}
                        {listingDetails.gasTotalPossibleReserves}{" "}
                        {listingDetails.gasTotalPossibleReservesUnit}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Location :{" "}
                        {listingDetails &&
                        listingDetails.location &&
                        listingDetails.location !== ""
                          ? listingDetails.location
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        Latitude / Longitude :{" "}
                        {listingDetails.latitude ? listingDetails.latitude : ""}{" "}
                        {listingDetails.longitude
                          ? `/ ${listingDetails.longitude}`
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>
                        Remaining (unsold) Power Capacity (KWH) :{" "}
                        {niceNumberDecimalDisplay(listingDetails.estimatedPoweCapacity, 14)}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>Campaign Pricing (DEC/kWh) : {decPrice} DEC ({usdPrice} USD)</div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div>Created Date : {date}</div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="element-box mt-4">
                <div>
                  <h6 className="element-header">
                    <span>Campaign Documents</span>
                  </h6>
                  <div className="row">{displayDocumentTypes}</div>
                </div>
              </div>

              {listingDetails.status !== "PENDING" &&
                listingDetails.statusNote !== "" &&
                listingDetails.statusNote !== null && (
                  <div className="element-box">
                    <h6>Note</h6>
                    {listingDetails.statusNote}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ListingDetails;
