import React, { Component } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import _ from 'lodash';

class DueDiligenceDocuments extends Component {

    render() {
        const { diligenceUploadDocumentsDetails, handleChange, diligenceTitles } = this.props;
        let availableDocumentsHtml = '';
        if (!_.isUndefined(diligenceUploadDocumentsDetails) && !_.isEmpty(diligenceUploadDocumentsDetails)) {
            const displayArray = {};
            _.map(diligenceUploadDocumentsDetails, function (questionaire) {
                if (_.isUndefined(displayArray[questionaire['digilenceId']['type']])) {
                    displayArray[questionaire['digilenceId']['type']] = [];
                }
                displayArray[questionaire['digilenceId']['type']].push(questionaire['digilenceId']['label']);
            });
            Object.keys(displayArray).forEach(function (key) {
                const values = displayArray[key];
                let options = '';
                _.map(values, function (questionaireTitle) {
                    options += `<li class="mt-1">${questionaireTitle}</li>`;
                });
                availableDocumentsHtml += `<div><h6 class="text-xl">${diligenceTitles[key]}</h6><ul>${options}</ul></div>`;
            });
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="element-wrapper">
                            <div className="element-box custom-element-box text-default text-default-color">
                                <p>Although we have attempted to make the foregoing list as complete as possible, your EMD may wish to
                                    review additional documents, and will attempt to bring these additional matters to your attention as soon
                                    as possible. Please contact your EMD to discuss the application and availability of these items</p>
                                <p className="mb-3">Below is the list of available documents that you selected. Upload your available documents to cloud folder and submit the link below.</p>
                                <div dangerouslySetInnerHTML={{ __html: availableDocumentsHtml }}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="element-wrapper">
                            <div className="element-box custom-element-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="text-xl">Data Room URL <i className="fa fa-info-circle" id="uploadDocumentsInfo"></i>
                                                <UncontrolledPopover trigger="hover" placement="top" target="uploadDocumentsInfo">
                                                    <PopoverBody>Please insert your cloud folder link which includes above documents (e.g. Google drive, One Drive, Dropbox, WeTransfer etc...)</PopoverBody>
                                                </UncontrolledPopover>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="dataroomUrl"
                                                id="dataroomUrl"
                                                placeholder="Please insert your cloud folder link."
                                                onChange={(event) => handleChange(event)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default DueDiligenceDocuments;