import React, { Component } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import _ from 'lodash';
import Select from 'react-select';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
import { API_ROOT } from '../../services/api-config';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class ListingProgressAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            categoryIdValue: null,
            categoryName: 'Select category',
            categoryId: '',
            shortDescription: '',
            buttonLoading: false,
            categoriesList: [
                { value: '', label: 'Select Category' }
            ]
        };
        this.fileUpdated = this.fileUpdated.bind(this);
        this.onchange = this.onchange.bind(this);
        this.saveProgressReport = this.saveProgressReport.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.uploadImageArray = {};
    }

    async componentWillMount() {
        const api = new Api();
        document.title = messageConstants.LISTING_ADD_PROGRESS_PAGE_TITLE + messageConstants.PAGE_TITLE_SEPERATOR + messageConstants.PERMIAN_LABEL;
        if (typeof (this.props.pageProgress) === 'function') {
            this.props.pageProgress('display');
        }
        this.props.pageProgress('remove');
        let authenticationToken = this.props.authToken;
        const response = await api.setToken(authenticationToken).get("user/progress/categories");
        let categoriesList = [];
        if (response.code === 200) {

            console.log("response.data.categories");
            console.log(response.data.categories);

            if (!_.isUndefined(response.data.categories)) {
                response.data.categories.forEach(category => {
                    let obj = { 'value': category._id, 'label': category.title };
                    categoriesList.push(obj);
                });
                if (!_.isUndefined(categoriesList)) {
                    this.setState({
                        categoriesList
                    });
                }
            }
        }
    }

    onchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async saveProgressReport(event) {
        let { title, shortDescription, categoryId } = this.state;
        let _this = this;
        let listingId = this.props.match.params.id;
        if (!_.isEmpty(this.uploadImageArray) && title !== '') {
            this.setState({
                buttonLoading: true
            });
            const data = new FormData();
            data.append('title', title);
            if (categoryId && categoryId !== '') {
                data.append('categoryId', categoryId);
            }

            data.append('shortDescription', shortDescription);
            data.append('listingId', listingId);
            data.append("document", this.uploadImageArray['file']);
            const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': this.props.authToken } };
            axios.post(API_ROOT + '/supplier/listing/progress/add', data, config)
                .then(function (response) {
                    if (!_.isUndefined(response)) {
                        _this.setState({
                            buttonLoading: false
                        });
                        if (response.data.code === 200) {
                            toast.success(response.data.message);
                            _this.props.history.push("/progress_reports/" + listingId);
                        }
                        else {
                            toast.error(response.data.message);
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(messageConstants.SOMETHING_WENT_WRONG);
                });
        }
        else {
            let msg = (title === '') ? messageConstants.ERROR_MSG_VALID_PROGRESS_REPORT_TITLE : messageConstants.ERROR_MSG_VALID_PROGRESS_FILE;
            toast.error(msg);
        }
    }

    async fileUpdated(fileItems) {
        if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== '') {
            this.uploadImageArray['file'] = fileItems[0].file;
        }
    }

    handleCategoryChange(selectedOption) {
        this.setState({
            categoryIdValue: selectedOption.value,
            categoryName: selectedOption.label,
            categoryId: selectedOption.value
        });
    }

    render() {
        let _this = this;
        const { buttonLoading, categoriesList, categoryName, categoryIdValue } = this.state;
        let buttonDisabled = (buttonLoading === true) ? true : false;
        return (
            <div className="progressreportadd">
                <div className="content-i">
                    <div className="content-box">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="element-wrapper">
                                    <div className="element-box">
                                        <h6 className="element-header text-xl">Add Progress Report</h6>
                                        <form id="formValidate">
                                            <div className="form-group">
                                                <label htmlFor="title">Title </label>
                                                <input className="form-control" id="title" placeholder="Enter title" type="text" name="title" onChange={this.onchange} />
                                            </div>

                                            {categoriesList &&
                                                <div className="form-group">
                                                    <label> Category <span className="required-sign">*</span></label>
                                                    <Select
                                                        className="progress-category-select2"
                                                        classNamePrefix="progressreport"
                                                        options={categoriesList}
                                                        placeholder="Select Category"
                                                        name="categoryId"
                                                        id="category"
                                                        onChange={this.handleCategoryChange}
                                                        value={{ 'label': categoryName, 'value': categoryIdValue }}
                                                    />
                                                </div>
                                            }

                                            <div className="form-group">
                                                <label>Short Description</label><textarea placeholder="Enter short description" className="form-control" rows="3" name="shortDescription" onChange={this.onchange}></textarea>
                                            </div>

                                            <label>Progress Report File</label>
                                            <div className="dropzoneIdContainer">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <FilePond
                                                            allowMultiple={false}
                                                            allowFileTypeValidation={true}
                                                            acceptedFileTypes={['image/*', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                                                            instantUpload={false}
                                                            onupdatefiles={fileItems => {
                                                                if (!_.isEmpty(fileItems)) {
                                                                    _this.fileUpdated(fileItems);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-buttons-w">
                                                <button className="btn btn-primary" type="button" onClick={this.saveProgressReport} disabled={buttonDisabled}> Submit {buttonLoading && (
                                                    <i className="fa-spin fa fa-spinner text-white m-1" />
                                                )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ListingProgressAdd;